import { Box, Heading, Text } from "grommet";
import { Search } from "grommet-icons";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setSelection } from "../../features/selectionSlice";
import { isMobile } from "../../lib/Constant";
import Button from "./button";
import CardTable from "./CardTable";
import { ToolBar } from "./style";
import Table from "./Table";
import TextInput from "./textInput";

const SearchBox = styled.li`
  width: ${(props) => (props.searchWidth ? `${props.searchWidth}%` : "45%")};
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ListView = ({
  isLoading,
  title,
  searchPlaceHolder,
  newButtonLink,
  newButtonText,
  newButtonDisabled,
  defaultColumns,
  tableData,
  searchFields,
  tableConfig,
  handeNewClick,
  isSubmit,
  onClickRow,
  onSelect,
  select,
  primaryKey,
  disabled,
  customRow1,
  searchWidth,
  isAutoMobile = false,
  onSearch,
  length,
  component = "",
}) => {
  const dispatch = useDispatch();
  const currentMenu = useSelector((state) => {
    return state.menu.value;
  });
  const selection = useSelector((state) => {
    return state.selection.value;
  });
  const [searchCount, setSearchCount] = useState(0);

  const listData = useMemo(() => {
    return tableData?.filterWithSearch(
      selection.searchValue?.[currentMenu?.name] || "",
      searchFields,
      (count) => {
        setSearchCount(count);
      }
    );
  }, [selection.searchValue, searchFields, tableData, currentMenu?.name]);

  const getRecords = () => {
    return (
      <>
        {(length !== 0 && tableData?.length) > 0 && (
          <>
            {selection.searchValue?.[currentMenu.name] ? (
              <Text>
                <Text style={{ fontWeight: "600" }}>{searchCount} </Text>
                <Text>{searchCount > 1 ? "results" : "result"} of</Text>

                <Text style={{ fontWeight: "600" }}>
                  {" "}
                  {length || tableData?.length}
                </Text>
                <Text>
                  {" "}
                  {component === "member"
                    ? length > 1
                      ? "items"
                      : "item"
                    : length || tableData?.length > 1
                    ? "items"
                    : "item"}
                </Text>
              </Text>
            ) : (
              <Text>
                <Text style={{ fontWeight: "600" }}>
                  {" "}
                  {length || tableData?.length}{" "}
                </Text>
                <Text>
                  {" "}
                  {component === "member"
                    ? length > 1
                      ? "items"
                      : "item"
                    : length || tableData?.length > 1
                    ? "items"
                    : "item"}
                </Text>
              </Text>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      {searchPlaceHolder && (
        <Heading level="3" margin="none">
          {title}
        </Heading>
      )}

      <ToolBar isTop={searchPlaceHolder ? true : false}>
        {!searchPlaceHolder && (
          <Box>
            <Heading level="3" margin="none">
              {title}
            </Heading>
            {!isLoading && getRecords()}
          </Box>
        )}

        {searchPlaceHolder && (
          <SearchBox searchWidth={searchWidth}>
            <TextInput
              icon={<Search color="black" />}
              placeholder={searchPlaceHolder}
              value={selection.searchValue?.[currentMenu?.name] || ""}
              handleChange={(e) => {
                onSearch && onSearch(e.target.value);
                dispatch(
                  setSelection({
                    searchValue: {
                      ...selection.searchValue,
                      [currentMenu?.name]: e.target.value,
                    },
                  })
                );
              }}
            />
            {!isLoading && getRecords()}
          </SearchBox>
        )}
        {customRow1}
        <li>
          {newButtonText && (
            <Button
              primary
              type={isSubmit ? "submit" : "button"}
              label={newButtonText}
              onClick={handeNewClick}
              disabled={newButtonDisabled}
            />
          )}
        </li>
      </ToolBar>

      {isAutoMobile && isMobile ? (
        <CardTable
          isLoading={isLoading}
          columns={defaultColumns}
          data={listData}
          tableConfig={tableConfig}
          onClickRow={onClickRow}
          onSelect={onSelect}
          select={select}
          primaryKey={primaryKey}
          disabled={disabled}
        />
      ) : (
        <Table
          isLoading={isLoading}
          columns={defaultColumns}
          data={listData}
          tableConfig={tableConfig}
          onClickRow={onClickRow}
          onSelect={onSelect}
          select={select}
          primaryKey={primaryKey}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default ListView;
