import { Box, Button as GButton, Layer as GLayer } from "grommet";
import { Close } from "grommet-icons";
import React from "react";
import styled from "styled-components";

const CloseButton = styled(Close)`
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: 8px;
  stroke: black;
`;

const Heading = styled.span`
  color: #000000;
  font-size: 26px;
  // line-height: 32px;
  text-align: center;
  font-weight: 500;
`;

const CancelButton = styled(GButton)`
  border-radius: 4px;
  background-color: #000000;
  color: #ffffff;
`;

const DeleteButton = styled(GButton)`
  border-radius: 4px;
  background-color: #ff4040;
  color: #ffffff;
  border: 2px solid #ff4040;
  &:hover {
    box-shadow: 0px 0px 0px 2px #ff4040;
  }
`;

const SecondaryTitle = styled.span`
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #000000;
`;

const DeleteBox = styled(Box)`
   {
    @media only screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
`;

function NormalDialog({ heading, prompt, proceed, cancel }) {
  return (
    <GLayer onClickOutside={cancel} onEsc={cancel} style={{ height: "auto" }}>
      <DeleteBox pad="medium" gap="medium">
        <CloseButton onClick={cancel} />
        <Heading>{heading}</Heading>
        <SecondaryTitle> {prompt}</SecondaryTitle>
        <Box direction="row" align="center" justify="center" gap="small">
          <CancelButton label="Cancel" onClick={cancel} />
          <DeleteButton label="Delete" onClick={proceed} />
        </Box>
      </DeleteBox>
    </GLayer>
  );
}

export default NormalDialog;
