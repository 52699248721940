import { Button as Gbutton } from 'grommet';
import styled from 'styled-components';

const ButtonWrapper = styled(Gbutton)`
height:44px;
color:white;
font-size: 1em;
padding: 0.25em 2em;
border-radius: 4px;
align-self: center;
margin-bottom:12px;
color:${(props) => props.isSecondary? props.color : props.textColor};
&:hover {
   color:${(props) =>  props.textColor || props.theme.global.colors.hoverFg };
   background-color:${(props) =>  props.color || props.theme.global.colors.brand };
  }
`;

const Button = ({label,color,bgColor,handleClick,...rest})=>{
    
return(<ButtonWrapper label={label} onClick={handleClick} isSecondary={rest?.secondary?true:false} color={bgColor} textColor={color} {...rest} />)
}

export default Button