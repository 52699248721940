import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { BackBox, DeveloperForm } from "../../components";
import useAPI from "../../lib/api/useApi";
import { Constant } from "../../lib/Constant";

const AddDeveloper = () => {
  const navigate = useNavigate();
  const { POST } = useAPI();
  const queryClient = useQueryClient();
  const { mutate, isLoading: isSubmitting } = useMutation("builderAdd", POST);

  const [formValues, setFormValues] = useState({
    developerName: "",
    companyName: "",
    constitutionSelect: "",
    constitution: "",
    isOtherConstitution: false,
    officeAddress: "",
    promoterName: "",
    promoterContactNumber: "",
    contactPersonName: "",
    contactPersonNumber: "",
    email: "",
    website: "",
    referredBy: "",
    referralContactNumber: "",
    developerLogo: "",
    developerLogoId: "",
  });

  const onFormChange = (value) => {
    setFormValues(value);
  };

  const onSubmit = (formData) => {
    mutate(
      {
        type: "form",
        path: "developer",
        body: formData,
        message: Constant.ToasterMessages.Builder.add.success,
        isApiToast:true,
      },
      {
        onSettled: (res) => {
          if (res.data.is_success) {
            queryClient.invalidateQueries("developers");
            navigate("/Developers");
          }
        },
      }
    );
  };

  return (
    <>
      <BackBox title="Add New Developer" link="/Developers" />
      <DeveloperForm
        formValues={formValues}
        handleSubmit={onSubmit}
        onFormChange={onFormChange}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default AddDeveloper;
