import {
  Box,
  Form,
  FormField,
  Heading,
  Keyboard,
  Tag,
  Text,
  TextInput,
} from "grommet";
import { Close } from "grommet-icons";
import { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import useAPI from "../../lib/api/useApi";
import PopUp from "../popUp";
import Button from "./button";
import { useSelector } from "react-redux";

const Container = styled.div`
  margin: 16px;
`;

const Content = styled(Form)`
  margin-bottom: 24px;
  width: auto;
  padding: 12px;
  overflow: auto;
  max-height: 66vh;
`;

const Title = styled(Heading)`
  margin: 0px;
`;

const ErrorMessage = styled(Text)`
  margin-top: 4px;
  font-size: 16px;
  color: red;
`;

const TagItem = styled(Tag)`
  height: 40px;
  span {
    font-size: 16px;
    font-weight: 500;
  }
`;

const FileItems = styled.div`
  align-self: flex-start;
  margin-top: 32px;
`;

const FileItem = styled(Box)`
  display: flex;
  margin-top: 12px;
  margin-bottom: 24px;
`;
const FileText = styled(Text)`
  color: black;
`;

const ModalHeader = styled(Heading)`
  margin: 12px;
`;

const CustomTextBox = styled(TextInput)`
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: lighter;
    line-height: 24px;
    color: #000000;
    background-color: inherit;
  }
  &:focus-within {
    outline: none;
  }
`;
const CustomeFormField = styled(FormField)`
  & span {
    display: none;
  }
`;

const CloseButton = styled(Close)`
  position: absolute;
  cursor: pointer;
  top: 8px;
  right: 8px;
`;

const WithTagBox = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: row;
  flex-wrap: wrap;
  border: 2px solid black;
  margin-top: 4px;
`;

export const ShareItems = ({ files, onClose }) => {
  const { POST } = useAPI();
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState("");
  const { mutate } = useMutation("shareMails", POST);
  const emaiExpression = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const invalidEmail = "Invalid email";
  const currentUser = useSelector((state) => state.user.value);
  const addEmail = (event) => {
    const val = event.target.value.toLowerCase();
    if (val && new RegExp(emaiExpression).test(val) && !emails.includes(val)) {
      const tempEmails = [...emails];
      tempEmails.push(val);
      setEmails(tempEmails);
      setError("");
      event.target.value = "";
      event.preventDefault();
    }
  };

  return (
    <PopUp
      otherStyle={{ marginTop: "80px", width: "45%" }}
      content={
        <Container>
          <ModalHeader level={3}>Share Documents</ModalHeader>
          <CloseButton onClick={onClose} color="black" />
          <Content validate="change">
            <Title level={4}>With whom would you like to share?</Title>
            <Keyboard
              onEnter={(event) => {
                addEmail(event);
              }}
            >
              <WithTagBox>
                {emails.map((item, index) => {
                  return (
                    <TagItem
                      margin="xxsmall"
                      key={`tag-${index}`}
                      onRemove={() => {
                        const tempEmails = [...emails];
                        tempEmails.splice(index, 1);
                        setEmails(tempEmails);
                      }}
                      value={item}
                    />
                  );
                })}
                <Box>
                  <CustomeFormField
                    name="email"
                    validate={(val) => {
                      if (val && !new RegExp(emaiExpression).test(val)) {
                        setError(invalidEmail);
                        return invalidEmail;
                      } else {
                        setError("");
                      }
                    }}
                  >
                    <CustomTextBox
                      id="email"
                      name="email"
                      placeholder="Enter Email"
                      plain
                      autoFocus
                      onKeyDown={(event) => {
                        if (event.code === "Comma" || event.code === "Space") {
                          addEmail(event);
                        }
                      }}
                    />
                  </CustomeFormField>
                </Box>
              </WithTagBox>
            </Keyboard>
            {error && <ErrorMessage>{error}</ErrorMessage>}

            <FileItems>
              <Title level={4}>Documents to be shared</Title>
              <FileItem>
                {files.map((item, index) => {
                  return (
                    <FileText key={`fileName-${index}`}>
                      {`${index + 1}. ${item?.displayName || item.fileName}`}
                    </FileText>
                  );
                })}
              </FileItem>
              <div>
                <Button
                  type="button"
                  onClick={() => {
                    const body = {
                      emailAddress: emails,
                      files: files,
                      siteId: currentUser?.site?._id,
                    };
                    mutate(
                      {
                        path: "share/mailDocument",
                        body: body,
                        isApiToast: true,
                      },
                      {
                        onSettled: (res) => {
                          if (res.data.is_success) {
                            onClose();
                          }
                        },
                      }
                    );
                  }}
                  disabled={emails.length === 0}
                  primary
                  label="Share"
                />
              </div>
            </FileItems>
          </Content>
        </Container>
      }
    />
  );
};
