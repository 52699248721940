import { Image, Tab, Tabs } from "grommet";
import { Document, User } from "grommet-icons";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { BackBox, FileList } from "../../components";
import MemberForm from "../../components/forms/memberForm";
import { Constant, Users } from "../../lib/Constant";
import useAPI from "../../lib/api/useApi";
import { memberDefaultFiles } from "../../lib/common";

const EditMember = () => {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const id = location.pathname.split("/")[2];
  const [formValues, setFormValues] = useState();
  const { PUT, GET } = useAPI();
  const queryClient = useQueryClient();
  const [files, setFiles] = useState(memberDefaultFiles);
  const [moneyReceipts, setMoneyReceipts] = useState([]);
  const currentUser = useSelector((state) => {
    return state.user.value;
  });

  useQuery(
    "member",
    async () =>
      await GET({
        path: `member/${id}`,
      }),
    {
      refetchOnWindowFocus: false,
      onSettled: (data) => {
        if (data?.data?.member) {
          const member = data.data.member;
          setFormValues({
            memberName: member?.memberName || member.user.userName,
            developerId: selection.developerId,
            siteId: member.siteId,
            unitId: member.unitId,
            mobileNumber: member.user.mobileNumber,
            email: member?.email || member.user.email,
            cardNumber: member?.card?.cardNumber,
            isRootUser: member?.isRootUser,
            canChangeCard: true,
          });

          const newFiles = memberDefaultFiles.map((item) => {
            const dbFile = member.documents?.[item.type];
            if (dbFile) {
              return { ...item, ...dbFile };
            } else {
              return item;
            }
          });
          setFiles(newFiles);
          setMoneyReceipts(member?.moneyReceipts || []);
        }
      },
    }
  );

  useQuery(
    ["checkMobileNumber", formValues?.mobileNumber],
    async () => {
      const isValidMobile = new RegExp("^[0-9]{10}$").test(
        formValues?.mobileNumber
      );
      if (isValidMobile && !formValues?.isRootUser) {
        return await GET({
          path: `member/check/card?mobileNumber=${formValues.mobileNumber}`,
        });
      }
    },
    {
      refetchOnWindowFocus: false,
      onSettled: (data) => {
        if (data?.is_success) {
          setFormValues({
            ...formValues,
            canChangeCard: !data.data.isMobileNumberExits,
            cardNumber: data.data?.card ? data.data?.card.cardNumber : "",
          });
        }
      },
    }
  );
  const { data: cardData } = useQuery(
    "getCards",
    async () => {
      if (currentUser.role === Users.Admin) {
        return await GET({
          path: `card`,
        });
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading: isSubmitting } = useMutation("memberUpdate", PUT);
  const selection = useSelector((state) => {
    return state.selection.value;
  });
  const onFormChange = (value) => {
    setFormValues(value);
  };

  const onSubmit = (data) => {
    mutate(
      {
        path: `member/${id}`,
        isApiToast: true,
        body: data,
      },
      {
        onSettled: (res) => {
          if (res.data.is_success) {
            queryClient.invalidateQueries("getMembersBySite");
          }
        },
      }
    );
  };

  return (
    <>
      <BackBox link="/members" />
      <Tabs
        activeIndex={activeIndex}
        onActive={(newActiveIndex) => {
          setActiveIndex(newActiveIndex);
        }}
        justify="start"
      >
        <Tab className="subTab" icon={<User />} title="Profile">
          {formValues ? (
            <MemberForm
              formValues={formValues}
              handleSubmit={onSubmit}
              onFormChange={onFormChange}
              isLoading={false}
              cardData={cardData}
              isSubmitting={isSubmitting}
            />
          ) : (
            <div>Loading ...</div>
          )}
        </Tab>
        <Tab
          disabled={!formValues}
          className="subTab"
          icon={<Document />}
          title="Documents"
        >
          <FileList
            key="Documents"
            extensions={["PDF"]}
            allowedExtensions={/(\.pdf)$/i}
            pdfViewPath={`member/file/memberDocuments?memberId=${id}&`}
            imgViewPath={`member/file/memberDocuments?memberId=${id}&`}
            thumbPath={`member/file/thumbnails?memberId=${id}&`}
            withContainer={false}
            files={files}
            uploadObject={{
              id,
              paramId: "memberId",
              path: "member/uploadDocuments",
              type: Constant.DocumentTypes.memberDocuments,
              deletePath: `member/file/delete?memberId=${id}&fileType=`,
            }}
            handleSetFiles={(passedFiles) => {
              setFiles(passedFiles);
            }}
          />
        </Tab>

        <Tab
          disabled={!formValues}
          className="subTab"
          icon={<Image width="24px" src={Constant.Icons.moneyReceipt} />}
          title="Money Receipts"
        >
          <FileList
            key="MoneyReceipts"
            allowedExtensions={/(\.jpg|\.jpeg|\.png|\.pdf)$/i}
            extensions={["JPG", "JPEG", "PNG", "PDF"]}
            pdfViewPath={`member/file/memberDocuments?memberId=${id}&`}
            thumbPath={`member/file/thumbnails?memberId=${id}&`}
            imgViewPath={`member/file/memberDocuments?memberId=${id}&`}
            withContainer={true}
            files={moneyReceipts}
            uploadObject={{
              id,
              paramId: "memberId",
              path: "member/moneyReceipts",
              type: Constant.DocumentTypes.moneyReceipts,
              deletePath: `member/file/delete?memberId=${id}&fileId=`,
            }}
            handleSetFiles={(passedFiles) => {
              setMoneyReceipts(passedFiles);
            }}
            commonDisplayName="Money Receipt"
            commonIcon={Constant.Icons.moneyReceiptWhite}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default EditMember;
