import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    prompt: "",
    isOpen: false,
    proceed: null,
    cancel: null,
  },
};

export const dialougeSlice = createSlice({
  name: "confirm",
  initialState,
  reducers: {
    setConfirm: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setConfirm } = dialougeSlice.actions;

export default dialougeSlice.reducer;
