import { memo, useEffect, useState, useTransition } from "react";
import TextInput from "./textInput";

const TableField = ({ data, property, updateCount, handleChange, ...rest }) => {
  const [value, setValue] = useState(data?.[property]);
  const [, startTransition] = useTransition();
  useEffect(() => {
    setValue(data?.[property]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCount]);

  return (
    <TextInput
      isFreeField={true}
      placeholder={" "}
      value={value || ""}
      handleChange={(e) => {
        setValue(e.target.value);
        startTransition(() => {
          handleChange && handleChange(e, data.index, property);
        });
      }}
      isWithoutForm={true}
      {...rest}
    />
  );
};
export default memo(TableField);
