import { Tab, Tabs } from "grommet";
import { User } from "grommet-icons";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { BackBox } from "../../components";
import ScannerForm from "../../components/forms/scannerForm";
import useAPI from "../../lib/api/useApi";
import { Constant } from "../../lib/Constant";

const EditScanner = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const [activeIndex, setActiveIndex] = useState(0);
  const id = location.pathname.split("/")[2];
  const { GET, PUT } = useAPI();

  const { mutate,isLoading: isSubmitting } = useMutation("scannerrUpdate", PUT);
  const [formValues, setFormValues] = useState({});

  const { data: scannerData, isLoading } = useQuery(
    "scanner",
    async () =>
      await GET({
        path: `scanner/${id}`,
      })
  );

  const onFormChange = (value) => {
    setFormValues(value);
  };

  useEffect(() => {
    if (scannerData?.data?.scanner) {
      const scanner = scannerData.data.scanner;
      setFormValues({
        userName: scanner.userName,
        mobileNumber: scanner.mobileNumber,
        email: scanner.email,
        profilePictureId: scanner.profilePictureId,
        profilePic: scanner.profilePicture
          ? [{ name: scanner.profilePicture }]
          : scanner.profilePicture,
      });
    }
  }, [scannerData]);

  const onSubmit = async (formData) => {
    formData.set(
      "profilePic",
      typeof formValues.profilePic === "object"
        ? formValues.profilePic[0]
        : formValues.profilePic
    );

    mutate(
      {
        path: `scanner/${id}`,
        type: "form",
        message: Constant.ToasterMessages.Scanner.update.success,
        body: formData,
      },
      {
        onSettled: (res) => {
          if (res.data.is_success) {
            queryClient.invalidateQueries("scanner");
            queryClient.invalidateQueries("scanners");
          }
        },
      }
    );
  };

  return (
    <>
      <BackBox link="/Scanners" />
      <Tabs
        activeIndex={activeIndex}
        onActive={(newActiveIndex) => {
          setActiveIndex(newActiveIndex);
        }}
        justify="start"
      >
        <Tab className="subTab" icon={<User />} title="Profile">
          {Object.entries(formValues)?.length > 0 && (
            <ScannerForm
              formValues={formValues}
              handleSubmit={onSubmit}
              onFormChange={onFormChange}
              isLoading={isLoading}
              id={id}
              isSubmitting={isSubmitting}
            />
          )}
        </Tab>
      </Tabs>
    </>
  );
};

export default EditScanner;
