import { Heading } from "grommet";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FileList } from "../../components";
import { memberDefaultFiles } from "../../lib/common";
import { Constant } from "../../lib/Constant";

const HeaderContent = styled(Heading)`
  max-width: 100%;
  @media only screen and (min-width: 768px) {
    margin-left: 8px;
  }
  @media only screen and (max-width: 1025px) {
    text-align: center;
  }
  @media only screen and (max-width: 426px) {
    text-align: left;
  }
`;

const PropertyDocuments = ({ showTitle = true }) => {
  const selection = useSelector((state) => state.selection.value);
  const units = useSelector((state) => state.user.value.units);
  const documentRef = useRef();
  const receiptsRef = useRef();
  const currentUser = useMemo(
    () => units.find((x) => x.unitId === selection.unitId),
    [selection.unitId, units]
  );

  const filterFiles = () => {
    return Object.entries(currentUser?.documents || []).map(
      ([fileType, file]) => {
        const defaultConfig = memberDefaultFiles.find(
          (dbItem) => dbItem.type === fileType
        );
        return { ...file, ...defaultConfig };
      }
    );
  };
  const [files] = useState(filterFiles());

  useEffect(() => {
    if (receiptsRef.current) {
      receiptsRef.current.updateChildFiles(currentUser?.moneyReceipts || []);
    }

    if (documentRef.current) {
      documentRef.current.updateChildFiles(filterFiles());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection.unitId, currentUser?.moneyReceipts]);

  return (
    <>
      {showTitle && (
        <HeaderContent level="3" margin="none">
          {`Documents for Unit - ${currentUser?.unit?.blockNumber}-${currentUser?.unit?.unitNumber}`}{" "}
        </HeaderContent>
      )}

      <FileList
        ref={documentRef}
        key="documents"
        pdfViewPath={`member/file/memberDocuments?memberId=${currentUser?._id}&`}
        thumbPath={`member/file/thumbnails?memberId=${currentUser?._id}&`}
        imgViewPath={`member/file/memberDocuments?memberId=${currentUser?._id}&`}
        withContainer={false}
        files={files}
        canDelete={false}
        emptyMsg="No Documents uploaded"
        isCenter={false}
        extensions={[]}
      />

      <HeaderContent level="3" margin="none" style={{ marginTop: "60px" }}>
        Money Receipts
      </HeaderContent>

      <FileList
        ref={receiptsRef}
        key="receipts"
        pdfViewPath={`member/file/memberDocuments?memberId=${currentUser?._id}&`}
        thumbPath={`member/file/thumbnails?memberId=${currentUser?._id}&`}
        imgViewPath={`member/file/memberDocuments?memberId=${currentUser?._id}&`}
        withContainer={false}
        files={currentUser?.moneyReceipts || []}
        canDelete={false}
        emptyMsg="No money receipts uploaded"
        isCenter={false}
        extensions={[]}
        commonDisplayName="Money Receipt"
        commonIcon={Constant.Icons.moneyReceiptWhite}
      />
    </>
  );
};

export default PropertyDocuments;
