import React from "react";
import useConfirm from "../../lib/useConfirm";
import NormalDialog from "./normalDialog";

function PromptDialog() {
  const {
    prompt = "",
    heading,
    isOpen = false,
    proceed,
    cancel,
  } = useConfirm();

  if (isOpen) {
    return (
      <NormalDialog
        heading={heading}
        prompt={prompt}
        cancel={cancel} 
        proceed={proceed}
      />
    );
  }
}

export default PromptDialog;
