import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { BackBox, ScannerForm } from "../../components";
import useAPI from "../../lib/api/useApi";
import { Constant } from "../../lib/Constant";

const AddScanner = () => {
  const navigate = useNavigate();
  const { POST } = useAPI();
  const queryClient = useQueryClient();
  const { mutate,isLoading: isSubmitting } = useMutation("scannerAdd", POST);

  const [formValues, setFormValues] = useState({
    userName: "",
    mobileNumber: "",
    email: "",
    profilePic: "",
  });

  const onFormChange = (value) => {
    setFormValues(value);
  };

  const onSubmit = (formData) => {
    mutate(
      {
        type: "form",
        path: "scanner/add-scanner",
        body: formData,
        message: Constant.ToasterMessages.Scanner.add.success,
      },
      {
        onSettled: (res) => {
          if (res.data.is_success) {
            queryClient.invalidateQueries("scanners");
            navigate("/Scanners");
          }
        },
      }
    );
  };

  return (
    <>
      <BackBox title="Add New Scanner" link="/Scanners" />
      <ScannerForm
        formValues={formValues}
        handleSubmit={onSubmit}
        onFormChange={onFormChange}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default AddScanner;
