import { Box, Form, Text } from "grommet";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Button, DropDown, TextInput } from "..";
import { Constant, Users } from "../../lib/Constant";
import useAPI from "../../lib/api/useApi";
import { scrollDown } from "../../lib/common";
import { Content } from "../shared/style";

const MemberForm = ({
  formValues,
  handleSubmit,
  onFormChange,
  isLoading,
  cardData,
  isSubmitting,
}) => {
  const [form, setForm] = useState(formValues);
  const submit = useRef(false);
  const { GET } = useAPI();
  const currentUser = useSelector((state) => {
    return state.user.value;
  });

  useEffect(() => {
    setForm(formValues);
  }, [formValues]);

  const { data: developerData } = useQuery("developers", async () => {
    if (Users.Admin === currentUser.role) {
      return await GET({
        path: "developer/all",
      });
    }
  });

  const { data: siteData } = useQuery(
    ["getDeveloperSites", form.developerId],
    async () =>
      await GET({
        path: `site/developer/${form.developerId}`,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data } = useQuery(
    ["getMembersBySiteForEdit", form.siteId],
    async () =>
      await GET({
        path: `member/site/${form.siteId}`,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: unitData } = useQuery(
    ["getUnitsByDeveloperAndSite", form.siteId],
    async () =>
      await GET({
        path: `unit?developerId=${form.developerId}&siteId=${form.siteId}`,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Form
      onSubmit={() => {
        handleSubmit(form);
      }}
      value={form}
      onChange={(changeForm) => {
        setForm(changeForm);
        onFormChange(changeForm);
      }}
      onValidate={(event) => {
        if (submit.current && Object.entries(event.errors).length > 0) {
          scrollDown(Object.entries(event.errors)[0][0]);
        }
        submit.current = false;
      }}
      method="post"
      validate="blur"
    >
      <Content>
        <TextInput
          required
          id="memberName"
          label="Member name (as per aadhar card) *"
          length={150}
        />
        {developerData && (
          <DropDown
            id="developerId"
            labelsData="Developer *"
            optionsData={developerData?.data?.developers?.map((developer) => {
              return {
                developerId: developer._id,
                developerName: developer.user.userName,
              };
            })}
            valuesData={form.developerId}
            labelKeyData="developerName"
            valueKeyData={{ key: "developerId", reduce: true }}
            handleChange={({ value: nextValue }) => {
              onFormChange({
                ...form,
                siteId: "",
                developerId: nextValue,
              });
            }}
            required
          />
        )}

        <DropDown
          id="siteId"
          labelsData="Project site *"
          optionsData={
            siteData?.data?.sites?.map((site) => {
              return {
                siteId: site._id,
                projectName: site.projectName,
              };
            }) || []
          }
          valuesData={form.siteId}
          labelKeyData="projectName"
          valueKeyData={{ key: "siteId", reduce: true }}
          handleChange={({ value: nextValue }) => {
            onFormChange({
              ...form,
              siteId: nextValue,
            });
          }}
          required
        />

        <DropDown
          id="unitId"
          labelsData="Unit *"
          optionsData={
            unitData?.data?.units
              ?.filter((x) => {
                let unitAssigned = data?.data?.members?.find(
                  (d) => d?.unitId?._id === x._id
                );

                if ((!unitAssigned && x.blockNumber) || form.unitId === x._id) {
                  return true;
                }
                return false;
              })
              .map((unit) => {
                return {
                  unitId: unit._id,
                  unitName: unit.blockNumber + "-" + unit.unitNumber,
                };
              }) || []
          }
          valuesData={form.unitId}
          labelKeyData="unitName"
          valueKeyData={{ key: "unitId", reduce: true }}
          handleChange={({ value: nextValue }) => {
            onFormChange({
              ...form,
              unitId: nextValue,
            });
          }}
          required
        />

        <TextInput
          regEx={{ pattern: "^[0-9]{10}$", message: "is invalid" }}
          icon={<Text className="textIcon">+91</Text>}
          required
          id="mobileNumber"
          type="number"
          label="Member Phone *"
          onWheel={(e) => e.target.blur()}
          disabled={formValues.isRootUser}
        />
        <TextInput
          regEx={{
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: "is invalid",
          }}
          id="email"
          label="Email"
          length={150}
        />

        {currentUser.role === Users.Admin ? (
          <DropDown
            disabled={!formValues.canChangeCard
            }
            id="cardNumber"
            optionsData={
              cardData?.data?.cards
                ?.filter(
                  (x) =>
                    x.status === Constant.CardStatus.Unassigned ||
                    x.cardNumber === form?.cardNumber
                )
                .map((item) => {
                  return {
                    cardNumber: item.cardNumber,
                    displayCard: item.cardNumber,
                  };
                }) || []
            }
            labelsData="Card number"
            labelKeyData="displayCard"
            valueKeyData={{ key: "cardNumber", reduce: true }}
            valueLabel={
              <Box
                width="20%"
                overflow="hidden"
                align="left"
                style={{
                  width: "200px",
                  fontWeight: "500",
                  padding: "12px",
                }}
              >
                {form?.cardNumber || (
                  <div style={{ fontWeight: "300" }}>Select Card</div>
                )}
              </Box>
            }
          />
        ) : (
          <>
            <Text className="label">Card number</Text>
            <Text className="label">
              {form?.cardNumber || "No card selected"}
            </Text>
          </>
        )}

        <Box style={{ margin: "auto", paddingTop: "8px" }}>
          <Button
            onClick={() => {
              submit.current = true;
            }}
            disabled={isSubmitting}
            type="submit"
            primary
            label="Submit"
          />
        </Box>
      </Content>
    </Form>
  );
};

export default MemberForm;
