import axios from "axios";
import { Box, Text } from "grommet";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button } from "..";
import { setSelection } from "../../features/selectionSlice";
import useAPI from "../../lib/api/useApi";
import {
  getCommonFilePath,
  memberDefaultFiles,
  setToaster,
  siteDefaultFiles,
} from "../../lib/common";
import { Constant, Users, isMobile } from "../../lib/Constant";
import useConfirm from "../../lib/useConfirm";
import FileItem from "./fileItem";
import FileUpload from "./fileUpload";
import { ShareItems } from "./shareItems";

const UploadItems = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const TextItem = styled(Text)`
  font-weight: 400;
  font-size: 20px;
  margin-top: 12px;
  ${({ isSmall }) =>
    isSmall &&
    `font-size:16px;
`}

  ${({ isBold }) =>
    isBold &&
    `font-size:16px;
font-weight: 500;
margin-bottom: 12px;
`}
`;

const ShieldImage = styled.img`
  vertical-align: middle;
  margin-right: 4px;
`;

const Wrapper = styled(Box)`
  position: relative;
  ${({ showBorder }) =>
    showBorder &&
    `border: 2px dashed black;
`}

  ${({ hasTopPortion }) =>
    !hasTopPortion &&
    `margin-top:12px;
`}
`;
const Container = styled(Box)`
  justify-content: center;
  width: 960px;

  ${({ isCenter }) =>
    isCenter &&
    ` margin: auto;
`}
  @media (max-width: 1024px) {
    margin: auto;
    width: 488px;
  }
  @media (max-width: 600px) {
    padding: 0px;
    margin: 0px;
    width: auto;
  }
`;

const EmptyContainer = styled.div`
  margin: auto;
  font-size: 20px;
  font-weight: 500;
  padding: 24px;
  text-align: center;
`;

const ShareRow = styled.div`
  margin-left: auto;
  margin-right: 16px;
  margin-top: 60px;
  margin-bottom: 0px;
`;

const EmailButton = styled.div`
  margin-bottom: 0px;
  padding: 0.25em 1em;
`;

const ShareVia = styled(Text)`
  font-weight: 600;
  font-size: 16px;
  margin-right: 4px;
`;

let source;
const FileList = (
  {
    files,
    handleSetFiles,
    withContainer,
    pdfViewPath,
    imgViewPath,
    uploadObject,
    thumbPath,
    allowedExtensions,
    extensions,
    canDelete = true,
    emptyMsg,
    canUpload = true,
    isCenter = true,
    commonDisplayName,
    commonIcon,
    maxFile,
    selectElement,
  },
  ref
) => {
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [listItems, setListItems] = useState(files);

  const dispatch = useDispatch();
  const inputEl = useRef(null);
  const itemsRef = useRef(null);
  const progressRef = useRef();
  const { isConfirmed } = useConfirm();
  const { POST, DELETE } = useAPI();
  const { mutate: deleteFileMutate } = useMutation("deleteFile", DELETE);
  const currentUser = useSelector((state) => {
    return state.user.value;
  });
  const canShareDocs =
    currentUser.role === Users.Member
      ? currentUser?.site?.allowMemberToDownload
      : true;

  const selectedFiles = useMemo(() => {
    return listItems
      ?.filter((x) => x?.isSelected)
      .map((item) => {
        return {
          fileId: item.fileId,
          fileName: item.originalName,
          displayName: item?.displayName,
        };
      });
  }, [listItems]);

  const fileSetting = useMemo(() => {
    let str = "";
    for (let i = 0; i < extensions.length; i++) {
      str = `${str}.${extensions[i]},`;
    }
    str = str.slice(0, -1);
    return { accept: str, msg: str.replaceAll(".", "") };
  }, [extensions]);

  useEffect(() => {
    source = axios.CancelToken.source();
    return () => {
      if (source) {
        source.cancel("Component got unmounted");
      }
    };
  }, []);

  useImperativeHandle(ref, () => {
    return {
      updateChildFiles: (items) => {
        setListItems(items);
      },
    };
  });

  const onChange = (
    event,
    index,
    passedAllowedExtensions,
    passedFileSetting
  ) => {
    let tempAllowedExtensions = allowedExtensions;
    let tempPassedFileSetting = fileSetting;

    if (!withContainer) {
      tempAllowedExtensions = passedAllowedExtensions;
      tempPassedFileSetting = passedFileSetting;
    }
    if (event.target.files.length > 0) {
      if (
        [...event.target.files].filter(
          (x) => !(tempAllowedExtensions || "").exec(x.name)
        ).length > 0
      ) {
        // "Allow only 'JPG,JPEG,PNG,PDF'"
        event.target.value = null;
        setToaster(
          "error",
          `Accepted File Type: ${tempPassedFileSetting.msg} Only`
        );
      } else {
        commonUpload(event, index);
      }
    }
  };

  const getDefaultFile = (index) => {
    return uploadObject.type === Constant.DocumentTypes.siteDocuments
      ? siteDefaultFiles[index]
      : uploadObject.type === Constant.DocumentTypes.memberDocuments
      ? memberDefaultFiles[index]
      : { type: uploadObject.type };
  };

  const commonUpload = async (event, index) => {
    const newFiles = [
      ...[...event.target.files].map((element) => {
        element.isUploading = true;
        element.progressId = Math.floor(Math.random() * (4000 - 1 + 1) + 1);
        element.icon = Constant.Icons.export_file_icon;
        element.originalName = element.name;
        return element;
      }),
    ];

    inputEl.current.style.border = "none";
    const defaultFile = getDefaultFile(index);
    setListItems((prev) => {
      const x = [...prev];
      if (withContainer) {
        return [...prev, ...newFiles];
      } else {
        x[index] = {
          ...defaultFile,
          ...newFiles[0],
        };
        return x;
      }
    });

    const onUploadProgress = (event, progressId) => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total);
      progressRef.current = {
        ...progressRef.current,
        [progressId]: { percentage: percentCompleted },
      };
      dispatch(
        setSelection({
          fileProgress: progressRef.current,
        })
      );
    };

    newFiles.forEach(async (element) => {
      const formData = new FormData();
      formData.set(uploadObject.paramId, uploadObject.id);
      formData.set(defaultFile.type, element);

      return await POST({
        path: uploadObject.path,
        type: "form",
        isApiToast: true,
        spin: false,
        onUploadProgress: (event) => {
          onUploadProgress(event, element.progressId);
        },
        body: formData,
        cancelToken: source.token,
      })
        .then((item) => {
          if (axios.isCancel(item)) {
            setListItems(files);
          } else {
            setListItems((prev) => {
              const updateFiles = [...prev];
              const updatedIndex = prev.findIndex(
                (x) => x.progressId === element.progressId
              );

              if (item?.data?.is_success) {
                updateFiles[updatedIndex] = {
                  ...defaultFile,
                  ...item.data.data,
                };
              } else {
                if (withContainer) {
                  updateFiles.splice(updatedIndex, 1);
                } else {
                  updateFiles[updatedIndex] = defaultFile;
                }
              }
              changeHeaderFiles(updateFiles);
              return updateFiles;
            });
          }
        })
        .catch((err) => {
          setListItems((prev) => {
            const updateFiles = [...prev];
            const updatedIndex = prev.findIndex(
              (x) => x.progressId === element.progressId
            );

            if (withContainer) {
              updateFiles.splice(updatedIndex, 1);
            } else {
              updateFiles[updatedIndex] = defaultFile;
            }

            changeHeaderFiles(updateFiles);
            return updateFiles;
          });
        });
    });
  };

  const changeHeaderFiles = (passedFiles) => {
    if (withContainer) {
      handleSetFiles(passedFiles.filter((x) => !x?.isUploading));
    } else {
      handleSetFiles(
        passedFiles.map((item, itemIndex) => {
          return item?.isUploading ? getDefaultFile(itemIndex) : item;
        })
      );
    }
  };

  const onDelete = (index, file) => {
    isConfirmed("Are you sure to remove this file?", "Remove").then(
      (confirm) => {
        if (confirm) {
          deleteFileMutate(
            {
              path: `${uploadObject.deletePath}${
                withContainer ? file.fileId : file.type
              }`,
              isApiToast: true,
            },
            {
              onSettled: (res) => {
                if (res.data.is_success) {
                  setListItems((prev) => {
                    const uploadFiles = [...prev];
                    if (withContainer) {
                      uploadFiles.splice(index, 1);
                    } else {
                      uploadFiles[index] = getDefaultFile(index);
                    }
                    changeHeaderFiles(uploadFiles);
                    return uploadFiles;
                  });
                }
              },
            }
          );
        }
      }
    );
  };
  return (
    <Container isCenter={isCenter}>
      {isShareOpen && (
        <ShareItems
          files={selectedFiles}
          onClose={() => {
            setIsShareOpen(false);
          }}
        />
      )}

      {((canShareDocs && listItems.length > 0) || selectElement) && (
        <ShareRow>
          {files.length > 0 && canShareDocs && (
            <>
              <ShareVia>Share via: </ShareVia>
              {isMobile && window.ReactNativeWebView ? (
                <EmailButton
                  as={Button}
                  primary
                  disabled={selectedFiles.length === 0}
                  label="WhatsApp"
                  onClick={() => {
                    let urls = selectedFiles.map((d) => {
                      let ext = d.fileName.split(".")[1];
                      return getCommonFilePath(
                        d.fileId,
                        d.fileName,
                        ext === "pdf" ? pdfViewPath : imgViewPath
                      );
                    });
                    window.ReactNativeWebView.postMessage(urls.toString());
                  }}
                />
              ) : (
                <EmailButton
                  disabled={selectedFiles.length === 0}
                  as={Button}
                  primary
                  label="Email"
                  onClick={() => {
                    setIsShareOpen(true);
                  }}
                />
              )}
            </>
          )}

          {selectElement && selectElement}
        </ShareRow>
      )}

      {listItems.length === 0 && emptyMsg ? (
        <EmptyContainer>{emptyMsg}</EmptyContainer>
      ) : (
        <>
          <Wrapper
            hasTopPortion={
              (canShareDocs && listItems.length > 0) || selectElement
            }
            showBorder={withContainer && listItems.length === 0}
            ref={inputEl}
          >
            <UploadItems
              id="itemslist"
              ref={itemsRef}
              onDropCapture={(event) => {
                event.target.style.zIndex = 0;
              }}
              onDragEnter={(event) => {
                event.target.style.zIndex = 0;
              }}
              onDragLeaveCapture={(event) => {
                event.target.style.zIndex = 0;
              }}
            >
              {listItems.map((item, index) => {
                return (
                  <FileItem
                    index={index + 1}
                    handeChild={() => {
                      if (withContainer) {
                        itemsRef.current.style.zIndex = 0;
                        inputEl.current.style.border = `${2}px dashed black`;
                      }
                    }}
                    commonDisplayName={commonDisplayName}
                    pdfViewPath={pdfViewPath}
                    imgViewPath={imgViewPath}
                    thumbPath={thumbPath}
                    withContainer={withContainer}
                    canDelete={canDelete}
                    key={`${item?.originalName}-${index}`}
                    hasFile={item?.originalName || item?.name ? true : false}
                    file={item}
                    canShareDocs={canShareDocs}
                    handleChange={(event, passedFileSetting) => {
                      onChange(
                        event,
                        index,
                        item.allowedExtensions,
                        passedFileSetting
                      );
                    }}
                    handleDelete={() => {
                      onDelete(index, item);
                    }}
                    onSelect={(e) => {
                      const tempList = [...listItems];
                      tempList[index] = {
                        ...tempList[index],
                        isSelected: e.target.checked,
                      };
                      setListItems(tempList);
                    }}
                    extensions={item?.extensions}
                    commonIcon={commonIcon}
                  ></FileItem>
                );
              })}
            </UploadItems>
            {withContainer && canUpload && (
              <FileUpload
                fileSetting={fileSetting}
                withContainer={withContainer}
                multiple={true}
                topContent={
                  withContainer && listItems.length > 0 ? (
                    <Text>{`${
                      isMobile
                        ? "Upload your files from here"
                        : "Drag and Drop here"
                    }`}</Text>
                  ) : null
                }
                handleDrag={(type) => {
                  let px = 2;
                  if (listItems?.length === 0) {
                    px = type === "leave" ? 2 : 4;
                    inputEl.current.style.border = `${px}px dashed black`;
                  } else {
                    inputEl.current.style.border =
                      type === "leave" ? "none" : `${2}px dashed black`;
                  }

                  if (type === "leave") {
                    itemsRef.current.style.zIndex = 3;
                  }
                }}
                handleChange={(event) => {
                  onChange(event);
                  event.target.type = "text";
                  event.target.type = "file";
                }}
              />
            )}
          </Wrapper>
          {withContainer && extensions?.length > 0 && (
            <Box
              flex={true}
              direction="row"
              style={{ marginLeft: "16px", marginRight: "12px" }}
              justify="between"
            >
              <TextItem
                isSmall
              >{`Accepted File Type: ${fileSetting.msg} Only`}</TextItem>
              <TextItem
                isSmall
                style={{ alignItems: "center", display: "flex" }}
              >
                <ShieldImage src={Constant.Icons.shield} />
                Secure
              </TextItem>
            </Box>
          )}
          {maxFile && (
            <TextItem
              style={{ marginLeft: "16px" }}
              isSmall
            >{`Maximum ${maxFile} number of files are allowed to upload.`}</TextItem>
          )}
        </>
      )}
    </Container>
  );
};

export default forwardRef(FileList);
