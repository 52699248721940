import axios from "axios";
import { useDispatch } from "react-redux";
import { setSpinner } from "../../features/spinnerSlice";
import { setToaster } from "../common";

const UNAUTHORIZED = 401;
// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const useAPI = () => {
  const dispatch = useDispatch();

  //#region "API CONFIG"
  const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  function setFormHeader(type) {
    API.defaults.headers.common["authorization"] =
      "Bearer " + localStorage.getItem("access_token");
    if (type === "form") {
      API.defaults.headers.common["Content-Type"] = "multipart/form-data";
    }
  }

  //#endregion

  //#region "HANDLE TOASTER,SPINNER AND ERRORS"

  API.interceptors.request.use(function (config) {
    config?.spin && dispatch(setSpinner(1));
    return config;
  });

  API.interceptors.response.use(
    (response) => {
      if (response.config?.isApiToast) {
        setToaster("success", response.data.message);
      } else {
        setToaster("success", response.config?.message);
      }
      response.config?.spin &&
        dispatch(setSpinner(response.config?.errorSpin ? -2 : -1));
      return response;
    },
    async (error) => {
      if (!axios.isCancel(error)) {
        const originalRequest = error.config;
        if (error.response.status === UNAUTHORIZED) {
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers["authorization"] = "Bearer " + token;
                return API({ ...originalRequest, ...{ errorSpin: true } });
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }
          isRefreshing = true;
          return new Promise(async (resolve, reject) => {
            await axios
              .get(`${process.env.REACT_APP_API_URL}auth/refresh`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer " + localStorage.getItem("refresh_token"),
                },
              })
              .then((response) => {
                const { access_token, refreshToken } = response.data.data;
                localStorage.setItem("access_token", access_token);
                localStorage.setItem("refresh_token", refreshToken);
                originalRequest.headers["authorization"] =
                  "Bearer " + access_token;
                processQueue(null, access_token);
                resolve(API({ ...originalRequest, ...{ errorSpin: true } }));
              })
              .catch((err) => {
                processQueue(err, null);
                if (err?.response?.status === UNAUTHORIZED) {
                  localStorage.clear();
                  setToaster("error", "Please login to continue");
                  window.location = "/login";
                }
                reject(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        } else {
          if (error.config?.spin) {
            dispatch(setSpinner(error.config?.errorSpin ? -2 : -1));
          }
          if (error.config?.isApiToast || error.config?.isErrorToast) {
            setToaster("error", error?.response?.data?.message);
          } else {
            setToaster("error", error?.config?.message);
          }
          return error
        }
      }

      return Promise.reject(error);
    }
  );

  //#endregion

  //#region "PURE API"
  const GET = async (params) => {
    setFormHeader();
    return API.get(`${params.path}`, {
      ...params?.extra,
      message: params?.message,
      isErrorToast: params?.isErrorToast,
      spin: params.hasOwnProperty("spin") ? params.spin : false,
    }).then((res) => {
      return res.data;
    });
  };

  const POST = (params) => {
    setFormHeader(params?.type);
    return API.post(`${params.path}`, params.body, {
      message: params?.message,
      isApiToast: params?.isApiToast,
      onUploadProgress: params?.onUploadProgress,
      spin: params.hasOwnProperty("spin") ? params.spin : true,
      cancelToken: params?.cancelToken,
    });
  };

  const PUT = (params) => {
    setFormHeader(params?.type);
    return API.put(`${params.path}`, params.body, {
      message: params?.message,
      isApiToast: params?.isApiToast,
      spin: params.hasOwnProperty("spin") ? params.spin : true,
    });
  };

  const DELETE = (params) => {
    setFormHeader(params?.type);
    return API.delete(`${params.path}`, {
      message: params?.message,
      isApiToast: params?.isApiToast,
      spin: params.hasOwnProperty("spin") ? params.spin : true,
    });
  };
  //#endregion

  return {
    GET,
    POST,
    PUT,
    DELETE,
  };
};

export default useAPI;
