import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
};

export const spinnerSlice = createSlice({
  name: "spinner",
  initialState,
  reducers: {
    setSpinner: (state, action) => {
      state.value =
        action.payload === 0 ? action.payload : state.value + action.payload;
    },
  },
});

export const { setSpinner } = spinnerSlice.actions;

export default spinnerSlice.reducer;
