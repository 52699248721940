import { DataTable, Heading } from "grommet";
import React from "react";
import styled from "styled-components";
import { Constant } from "../../lib/Constant";

const EmptyContainer = styled(Heading)`
   {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
  }
`;

function Table({ columns, data, tableConfig, onClickRow, isLoading,onSelect,select,primaryKey,disabled }) {
  if (isLoading) {
    return <div style={{ textAlign: "center" }}>Loading ...</div>;
  }

  return (
    <>
      {data?.length > 0 ? (
        <DataTable
          //onClickRow={onClickRow}
          columns={columns}
          data={data}
          paginate={true}
          step={tableConfig?.itemsPerPage}
          select={select}
          onSelect={onSelect}
          primaryKey={primaryKey}
          disabled={disabled}

          // these setting are usefull for server side pagination
          // paginate={{numberItems:length,onChange:onPaginate,step:itemsPerPage}}
          // step={1}
        ></DataTable>
      ) : (
        <EmptyContainer level="3">
          {!tableConfig?.emptyTableMsg
            ? Constant.Pagination.defaultEmptyTableMsg
            : tableConfig?.emptyTableMsg}
        </EmptyContainer>
      )}
    </>
  );
}

export default Table;
