import { Image, Tab, Tabs } from "grommet";
import { Document, Gallery } from "grommet-icons";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { BackBox, FileList, SiteForm } from "../../components";
import useAPI from "../../lib/api/useApi";
import { siteDefaultFiles } from "../../lib/common";
import { Constant } from "../../lib/Constant";

const EditSite = () => {
  const location = useLocation();
  const { GET, PUT } = useAPI();
  const [activeIndex, setActiveIndex] = useState(0);

  const [files, setFiles] = useState(siteDefaultFiles);
  const [photos, setPhotos] = useState([]);
  const id = location.pathname.split("/")[2];
  const queryClient = useQueryClient();

  const [formValues, setFormValues] = useState();
  const { data: siteData, isLoading } = useQuery(
    "site",
    async () =>
      await GET({
        path: `site/${id}`,
      }),
    {
      refetchOnWindowFocus: false,
      onSettled: (data) => {
        if (data?.is_success) {
          if (data?.data?.site) {
            const site = data.data.site;
            const projectLegalAddress1 = site.projectLegalAddress1;

            setFormValues({
              projectName: site.projectName,
              developerId: location.state.developerId,
              projectPostalAddress: site.projectPostalAddress,
              finalPlotNumber: projectLegalAddress1.finalPlotNumber,
              surveyNumber: projectLegalAddress1.surveyNumber,
              TP_SchemeNumber: projectLegalAddress1.TP_SchemeNumber,
              village: projectLegalAddress1.village,
              taluka: projectLegalAddress1.taluka,
              district: projectLegalAddress1.district,
              subDistrict: projectLegalAddress1.subDistrict,
              city: projectLegalAddress1.city,
              state: projectLegalAddress1.state,
              pinNumber: projectLegalAddress1.pinNumber,
              projectLegalAddress2: site.projectLegalAddress2,
              projectTypeSelect: site.isOtherProjectType
                ? Constant.ProjectTypes.Other
                : site.projectType,
              projectType: site.projectType,
              isOtherProjectType: site.isOtherProjectType,
              numberOfTowers: site.numberOfTowers,
              projectTypeUnits: site.projectTypeUnits,
              projectStatus: site.projectStatus,
              RERA_RegistrationNumber: site.RERA_RegistrationNumber,
              RERA_RegistrationDate: site.RERA_RegistrationDate,
              RERARegistrationDate: site.RERA_RegistrationDate,
              rajachitthi: site.rajachitthi,
              projectEndDate: site.projectEndDate,
              allowMemberToDownload: site.allowMemberToDownload,
            });

            filterFiles(site?.projectStatus, site);
            setPhotos(site?.sitePhotos || []);
          }
        }
      },
    }
  );

  const { mutate, isLoading: isSubmitting } = useMutation("siteUpdate", PUT);

  const onFormChange = (value) => {
    setFormValues(value);
  };

  const filterFiles = (status, tempData) => {
    const newFiles = siteDefaultFiles
      .filter((x) => (x?.status ? x.status === status : true))
      .map((item) => {
        const dbFile = (tempData || siteData?.data?.site)?.documents?.[
          item.type
        ];
        if (dbFile) {
          return { ...item, ...dbFile };
        } else {
          return item;
        }
      });
    setFiles(newFiles);
  };

  const onSubmit = (data) => {
    mutate(
      {
        path: `site/${id}`,
        type: "form",
        isApiToast: true,
        body: data,
      },
      {
        onSettled: (res) => {
          if (res.data.is_success) {
            queryClient.invalidateQueries("getDeveloperSites");
          }
        },
      }
    );
  };

  return (
    <>
      <BackBox link="/Sites" />

      <Tabs
        activeIndex={activeIndex}
        onActive={(newActiveIndex) => {
          setActiveIndex(newActiveIndex);
        }}
        justify="start"
      >
        <Tab
          className="subTab"
          icon={<Image width="24px" src={Constant.Icons.detail} />}
          title="Details"
        >
          {formValues ? (
            <SiteForm
              id={id}
              formValues={formValues}
              handleSubmit={onSubmit}
              onFormChange={onFormChange}
              isLoading={isLoading}
              filterFiles={filterFiles}
              isSubmitting={isSubmitting}
            />
          ) : (
            <div>Loading ...</div>
          )}
        </Tab>
        <Tab
          disabled={!formValues}
          className="subTab"
          icon={<Document />}
          title="Documents"
        >
          <FileList
            key="Documents"
            extensions={["PDF"]}
            allowedExtensions={/(\.pdf)$/i}
            pdfViewPath={`site/file/siteDocuments?siteId=${id}&`}
            withContainer={false}
            files={files}
            uploadObject={{
              id: id,
              paramId: "siteId",
              path: "site/uploadDocument",
              type: Constant.DocumentTypes.siteDocuments,
              deletePath: `site/file/delete?siteId=${id}&fileType=`,
            }}
            handleSetFiles={(passedFiles) => {
              setFiles(passedFiles);
            }}
          />
        </Tab>
        <Tab
          disabled={!formValues}
          className="subTab"
          icon={<Gallery />}
          title="Photos"
        >
          <FileList
            key="photos"
            allowedExtensions={/(\.jpg|\.jpeg|\.png)$/i}
            extensions={["JPG", "JPEG", "PNG"]}
            imgViewPath={`site/file/siteDocuments?siteId=${id}&`}
            thumbPath={`site/file/thumbnails?siteId=${id}&`}
            withContainer={true}
            files={photos}
            uploadObject={{
              id: id,
              paramId: "siteId",
              path: "site/sitePhotos",
              type: Constant.DocumentTypes.sitePhotos,
              deletePath: `site/delete/site/photos?siteId=${id}&fileId=`,
            }}
            handleSetFiles={(passedFiles) => {
              setPhotos(passedFiles);
            }}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default EditSite;
