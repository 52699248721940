import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    developerId: "",
    siteId: "",
  },
};

export const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    setSelection: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setSelection } = selectionSlice.actions;

export default selectionSlice.reducer;
