import { CheckBox, Meter, Text } from "grommet";
import { Trash, Upload } from "grommet-icons";
import React, { useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import useAPI from "../../lib/api/useApi";
import { getCommonFilePath, setToaster } from "../../lib/common";
import { Constant } from "../../lib/Constant";
import FileUpload from "./fileUpload";
import FileViewer from "./fileViewer";

const Item = styled.div`
  margin: 12px;
  width: 200px;
  height: 244px;
  background-color: #ffb302;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px
    ${(props) =>
      props.hasFile &&
      `padding: 8px;
`};

  ${(props) =>
    props.isLastColumn &&
    `margin-right: 0px;
`};

  @media (max-width: 600px) {
    width: 100%;
    margin: 8px 0px;
  }
`;

const ItemProgress = styled(Meter)`
  min-width: 216px;
  margin-left: -8px;
  margin-top: -8px;
`;

const UploadWrapper = styled.div`
  ${({ hasFile }) =>
    !hasFile &&
    `
    border: 2px dashed white;
    padding: 12px 0px;
`}

  ${({ hasFile }) =>
    hasFile &&
    `
border: none !important;
`}

  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DeleteImg = styled(Trash)`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
`;

const Thumb = styled.img`
  ${(props) =>
    !props.isPdf &&
    ` object-fit: scale-down;
`};

  aspect-ratio: 15/10;
  cursor: pointer;
  margin: 40px 4px 0px;
  @media only screen and (max-width: 767px) {
    aspect-ratio: none;
    margin: 40px 4px 0px;
    // height: ${(props) => (!props.isPdf ? "140px" : "124px")};
    height: 124px;
  }
`;

const ThumbText = styled.div`
  word-break: break-word;
  overflow: hidden;
  font-weight: 600;
  height: 20px;
`;

const FileWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  padding-bottom: 12px;
  box-shadow: 0 4px 6px -6px white;
`;

const View = styled.img`
  cursor: pointer;
  height: 24px;
  margin: auto;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: 8px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
`;

const UploadImg = styled(Upload)`
  margin-bottom: 8px;
`;

const TextItem = styled(Text)`
  font-size: 16px;
  text-align: center;
`;

const FileItem = ({
  index,
  pdfViewPath,
  imgViewPath,
  thumbPath,
  file,
  hasFile,
  handleChange,
  handleDelete,
  withContainer,
  canDelete,
  handeChild,
  extensions,
  commonDisplayName,
  commonIcon,
  onSelect,
  canShareDocs,
}) => {
  const { GET } = useAPI();
  const theme = useTheme();
  const inputEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const selection = useSelector((state) => {
    return state.selection.value;
  });
  const [urlObject, setUrlObject] = useState("");
  const { mutate: downloadFile } = useMutation("downloadFile", GET);

  const isPdf = new RegExp(/(\.pdf)$/i).test(file?.originalName);

  const fileSetting = useMemo(() => {
    let str = "";
    for (let i = 0; i < extensions?.length; i++) {
      str = `${str}.${extensions[i]},`;
    }
    str = str.slice(0, -1);
    return { accept: str, msg: str.replaceAll(".", "") };
  }, [extensions]);

  return (
    <>
      {isOpen && (
        <FileViewer
          title={`${file?.displayName || "Document"}`}
          togglePopup={togglePopup}
          fileId={file.fileId}
          originalName={file.originalName}
          urlObject={urlObject}
          imgViewPath={imgViewPath}
          isPdf={isPdf}
        />
      )}

      <Item
        isLastColumn={index !== 0 && index % 4 === 0 ? true : false}
        onDropCapture={() => {
          handeChild();
        }}
        onDragEnter={() => {
          handeChild();
        }}
        onClick={(e) => {
          if (hasFile && !file?.isUploading) {
            if (isPdf) {
              localStorage.setItem(
                "downloadFileName",
                `${file?.displayName || "Document"}.pdf`
              );
              downloadFile(
                {
                  path: getCommonFilePath(
                    file.fileId,
                    file.originalName,
                    pdfViewPath
                  ),
                  extra: {
                    responseType: "blob",
                  },
                  spin: true,
                },
                {
                  onSettled: (data) => {
                    if (data) {
                      const href = URL.createObjectURL(data);
                      setUrlObject(href);
                      togglePopup();
                    } else {
                      setToaster("error", "Document not found");
                    }
                  },
                }
              );
            } else {
              togglePopup();
            }
          }
        }}
      >
        <UploadWrapper ref={inputEl} hasFile={hasFile}>
          {file?.isUploading && (
            <ItemProgress
              background="none"
              thickness="4px"
              values={[
                {
                  value:
                    selection?.fileProgress?.[file?.progressId]?.percentage,
                  color: theme.global.colors.brand,
                  label: "sixty",
                },
              ]}
              aria-label="meter"
            />
          )}

          {hasFile ? (
            <>
              {!file?.isUploading && (
                <>
                  {canShareDocs && (
                    <CheckBox
                      className="selectFile"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      checked={file?.isSelected}
                      onChange={(event) => {
                        event.stopPropagation();
                        onSelect(event);
                      }}
                    />
                  )}

                  {canDelete && (
                    <DeleteImg
                      color="white"
                      onClick={(e) => {
                        handleDelete();
                        e.stopPropagation();
                      }}
                    />
                  )}
                </>
              )}
              <>
                {isPdf && !file?.showIcon ? (
                  <Thumb
                    isPdf={isPdf && !commonIcon}
                    src={
                      commonIcon || file?.icon || Constant.Icons.architecture
                    }
                  />
                ) : (
                  <Thumb
                    isPdf={false}
                    src={
                      file?.icon ||
                      commonIcon ||
                      getCommonFilePath(
                        file.fileId,
                        file.originalName,
                        thumbPath
                      )
                    }
                  />
                )}
                <FileWrapper style={{ marginTop: "4px", height: "16px" }}>
                  <ThumbText title={file?.displayName || file.originalName}>
                    {commonDisplayName ||
                      ((!withContainer || isPdf) &&
                        (file?.displayName ||
                          (file.originalName.substring(
                            0,
                            file.originalName.lastIndexOf(".")
                          )?.length > 14
                            ? `${file.originalName
                                .substring(
                                  0,
                                  file.originalName.lastIndexOf(".")
                                )
                                .slice(0, 15)} ... .${file.originalName
                                .substring(
                                  file.originalName.lastIndexOf(".") + 1,
                                  file.originalName.length
                                )
                                .toLowerCase()}`
                            : `${file.originalName.substring(
                                0,
                                file.originalName.lastIndexOf(".")
                              )}.${file.originalName
                                .substring(
                                  file.originalName.lastIndexOf(".") + 1,
                                  file.originalName.length
                                )
                                .toLowerCase()}`)))}
                  </ThumbText>
                </FileWrapper>
                {!file?.isUploading && <View src={Constant.Icons.findFile} />}
              </>
            </>
          ) : (
            <FileUpload
              fileSetting={fileSetting}
              multiple={false}
              withContainer={withContainer}
              handleDrag={(type) => {
                const px = type === "leave" ? 2 : 4;
                inputEl.current.style.border = `${px}px dashed white`;
              }}
              handleChange={(event) => {
                handleChange(event, fileSetting);
              }}
              topContent={
                <Content>
                  <UploadImg color="white" />
                  <TextItem size="medium">
                    <TextItem
                      style={{ fontWeight: "600" }}
                    >{`${file?.displayName}`}</TextItem>{" "}
                  </TextItem>
                  <TextItem>OR</TextItem>
                  <TextItem color="#7D4CDB">Browse</TextItem>
                </Content>
              }
            />
          )}
        </UploadWrapper>
      </Item>
    </>
  );
};

export default FileItem;
