import { Box, FileInput, Form, FormField, Grommet, Text } from "grommet";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { Button, DropDown, TextInput } from "../../components";
import useAPI from "../../lib/api/useApi";
import { getCommonFilePath, scrollDown } from "../../lib/common";
import { Constant, MAX_IMAGE_LIMIT, Users } from "../../lib/Constant";
import NormalDialog from "../dialogs/normalDialog";
import FileViewer from "../shared/fileViewer";
import { Content } from "../shared/style";

const Thumb = styled.img`
  object-fit: scale-down;
  width: 120px;
  height: 120px;
  @media only screen and (max-width: 769px) {
    height: 56px;
    width: 56px;
    object-fit: scale-down;
  }
`;

const DeveloperForm = ({
  formValues,
  handleSubmit,
  onFormChange,
  isLoading,
  id,
  cardData,
  isSubmitting,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = { ...useTheme() };
  const submit = useRef(false);
  const { DELETE } = useAPI();
  const queryClient = useQueryClient();
  const [form, setForm] = useState(formValues);
  theme.formField.border = {
    color: "brand",
    error: {
      color: "#FF4040",
    },
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setForm(formValues);
  }, [formValues]);

  const { mutate: deleteImage } = useMutation("developerImageDelete", DELETE);
  const imageSrc = useRef("");

  const currentUser = useSelector((state) => {
    return state.user.value;
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Form
      onSubmit={(objForm) => {
        const formData = new FormData(objForm.target);
        formData.set(
          "isOtherConstitution",
          form.constitutionSelect === Constant.Constitution.Other
        );
        formData.set(
          "constitution",
          form.constitutionSelect === Constant.Constitution.Other
            ? form.constitution
            : form.constitutionSelect
        );
        handleSubmit(formData);
      }}
      value={form}
      onChange={(changeForm) => {
        setForm(changeForm);
        onFormChange(changeForm);
      }}
      method="post"
      validate="blur"
      onValidate={(event) => {
        if (submit.current && Object.entries(event.errors).length > 0) {
          scrollDown(Object.entries(event.errors)[0][0]);
        }
        submit.current = false;
      }}
    >
      <Content>
        {isOpen && (
          <FileViewer
            togglePopup={togglePopup}
            fileId={form?.developerLogoId}
            originalName={form?.developerLogo[0]?.name}
            imgViewPath={`developer/file?developerId=${id}&`}
            isPdf={false}
            directPath={imageSrc.current}
            showEmailIcon={false}
          />
        )}
        <TextInput
          required
          id="developerName"
          label="Group / Individual developer name *"
          length={200}
        />
        <TextInput id="companyName" label="Firm / Company name" length={200} />
        <DropDown
          id="constitutionSelect"
          optionsData={Object.values(Constant.Constitution).map((item) => item)}
          handleChange={({ value: nextValue }) => {
            onFormChange({
              ...form,
              constitution: "",
              constitutionSelect: nextValue,
            });
          }}
          required
          labelsData="Constitution *"
        />
        <TextInput
          display={Constant.Constitution.Other === form.constitutionSelect}
          required
          id="constitution"
          placeholder="Enter other constitution"
          length={200}
        />

        <TextInput
          id="officeAddress"
          type="textArea"
          label="Developer office address"
          length={1000}
        />

        <TextInput
          required
          id="promoterName"
          label="Name of the promoter *"
          length={150}
        />
        <TextInput
          regEx={{ pattern: "^[0-9]{10}$", message: "invalid" }}
          icon={<Text className="textIcon">+91</Text>}
          required
          id="promoterContactNumber"
          type="number"
          label="Promoter contact number *"
          onWheel={(e) => e.target.blur()}
        />

        <TextInput
          id="contactPersonName"
          label="Contact person name"
          length={150}
        />
        <TextInput
          regEx={{ pattern: "^[0-9]{10}$", message: "invalid" }}
          icon={<Text className="textIcon">+91</Text>}
          id="contactPersonNumber"
          type="number"
          label="Mobile number of contact person"
          onWheel={(e) => e.target.blur()}
        />

        <TextInput
          id="email"
          type="text"
          regEx={{
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: "is invalid",
          }}
          length={150}
          label="Email ID"
        />

        <TextInput
          id="website"
          label="Website"
          length={150}
          regEx={{
            pattern:
              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,

            message: "is invalid",
          }}
        />
        {currentUser.role === Users.Admin && (
          <>
            <TextInput id="referredBy" label="Referred by" length={150} />
            <TextInput
              regEx={{ pattern: "^[0-9]{10}$", message: "invalid" }}
              icon={<Text className="textIcon">+91</Text>}
              id="referralContactNumber"
              type="number"
              label="Referral contact number"
              onWheel={(e) => e.target.blur()}
            />
          </>
        )}

        {currentUser.role === Users.Admin ? (
          <>
            {id && (
              <DropDown
                disabled={currentUser?.role !== Users.Admin}
                id="cardNumber"
                placeholder="Select Card number"
                optionsData={
                  cardData?.data?.cards
                    ?.filter(
                      (x) =>
                        x.status === Constant.CardStatus.Unassigned ||
                        x.cardNumber === form?.cardNumber
                    )
                    .map((item) => {
                      return {
                        cardNumber: item.cardNumber,
                        displayCard: item.cardNumber,
                      };
                    }) || []
                }
                labelsData="Card number"
                labelKeyData="displayCard"
                valueKeyData={{ key: "cardNumber", reduce: true }}
              />
            )}
          </>
        ) : (
          <>
            <TextInput id="cardNumber" className="hidden" />
            <Text className="label">Card number</Text>
            <Text className="label">
              {form?.cardNumber || "No card selected"}
            </Text>
          </>
        )}

        <Grommet theme={theme}>
          <FormField
            label={`Developer Logo`}
            name="developerLogo"
            htmlFor="developerLogo"
            validate={(e) => {
              if (e.length > 0) {
                var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                if (e[0].size > MAX_IMAGE_LIMIT) {
                  if (!allowedExtensions.exec(e[0].name)) {
                    return "Allow only 'JPG,JPEG,PNG'";
                  }

                  return Constant?.Errors?.FileLimitError;
                }
              }
            }}
          >
            <FileInput
              renderFile={(file) => {
                if (file?.size) {
                  var reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onloadend = function () {
                    imageSrc.current = reader.result;
                  };
                }
                return (
                  <Box
                    onClick={togglePopup}
                    style={{
                      padding: "4px",
                      alignItems: "center",
                      position: "relative",
                      zIndex: "2",
                    }}
                  >
                    {!imageSrc.current && form?.developerLogoId ? (
                      <Thumb
                        src={getCommonFilePath(
                          form?.developerLogoId,
                          form?.developerLogo[0].name,
                          `developer/file?developerId=${id}&`
                        )}
                        alt="logo"
                      />
                    ) : (
                      <Thumb src={imageSrc.current} alt="logo" />
                    )}
                  </Box>
                );
              }}
              accept=".JPG,.JPEG,.PNG"
              id="developerLogo"
              name="developerLogo"
              confirmRemove={({ onConfirm, onCancel }) => (
                <NormalDialog
                  proceed={() => {
                    if (form?.developerLogoId) {
                      deleteImage(
                        {
                          path: `developer/file/delete?developerId=${id}&fileId=${form?.developerLogoId}`,
                          isApiToast: true,
                        },
                        {
                          onSuccess: () => {
                            if (currentUser.role === Users.Developer) {
                              queryClient.invalidateQueries(["userProfile"]);
                            }
                            onFormChange({
                              ...form,
                              developerLogoId: "",
                              developerLogo: "",
                            });
                          },
                        }
                      );
                    }
                    onConfirm();
                  }}
                  cancel={onCancel}
                  heading="Remove"
                  prompt="Are you sure to remove developer logo?"
                />
              )}
            />
          </FormField>
        </Grommet>
        <Box style={{ margin: "auto", paddingTop: "8px" }}>
          <Button
            onClick={() => {
              submit.current = true;
            }}
            disabled={isSubmitting}
            type="submit"
            primary
            label="Submit"
          />
        </Box>
      </Content>
    </Form>
  );
};

export default DeveloperForm;
