import { FileInput, Grommet, Text } from "grommet";
import { Upload } from "grommet-icons";
import styled, { css } from "styled-components";
import { isMobile } from "../../lib/Constant";

const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  position: relative;
  height: 100%;
  ${({ topContent }) =>
    topContent &&
    `
align-items: flex-start;
height: 0px;
padding:12px 20px;
margin-bottom:16px; 
font-weight:bold;
`}

  ${({ topContent, withContainer }) =>
    topContent &&
    !withContainer &&
    ` height: 100%;
`}
`;

const TextItem = styled(Text)`
  font-weight: 400;
  font-size: 20px;
  margin-top: 12px;
  ${({ lastChild }) =>
    lastChild &&
    `
margin-bottom:16px; 
`}
`;

const FileUpload = ({
  handleChange,
  handleDrag,
  topContent,
  withContainer,
  multiple,
  fileSetting,
}) => {
  const customTheme = {
    fileInput: {
      extend: css`
        color: green;
        outline: none;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
      `,
    },
  };

  return (
    <>
      {topContent ? (
        <Grommet
          theme={customTheme}
        >
          <UploadWrapper withContainer={withContainer} topContent={topContent}>
            {topContent}
          </UploadWrapper>
          <FileComponent
            handleChange={handleChange}
            handleDrag={handleDrag}
            multiple={multiple}
            fileSetting={fileSetting}
          />
        </Grommet>
      ) : (
        <Grommet style={{ position: "relative" }} theme={customTheme}>
          <UploadWrapper withContainer={withContainer} topContent={topContent}>
            <Upload color="black" />
            <TextItem>{`${isMobile ? 'Upload your files from here' : 'Drag and Drop here' }`} </TextItem>
            <TextItem>Or</TextItem>
            <TextItem lastChild color="#7D4CDB">
              Browse files
            </TextItem>
          </UploadWrapper>
          <FileComponent
            fileSetting={fileSetting}
            handleChange={handleChange}
            handleDrag={handleDrag}
            multiple={multiple}
          />
        </Grommet>
      )}
    </>
  );
};

const FileComponent = ({ handleDrag, handleChange, multiple, fileSetting }) => {
  return (
    <FileInput
      accept={fileSetting?.accept}
      multiple={multiple}
      messages={{
        browse: " ",
        dropPrompt: "dropfilecontentthiscon",
      }}
      onDropCapture={() => {
        handleDrag("leave");
      }}
      onDragEnter={() => {
        handleDrag();
      }}
      onDragLeaveCapture={() => {
        handleDrag("leave");
      }}
      onChange={handleChange}
    />
  );
};

export default FileUpload;
