import { Grommet, Layer } from "grommet";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import { AppRoutes, PromptDialog } from "./components";
import "./css/Custom.css";
import theme from "./css/theme";
import { persistor, store } from "./store";
import 'react-calendar/dist/Calendar.css';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Grommet theme={theme} style={{ height: "100%" }}>
            <ToastContainer />
            <Layout />
            <PromptDialog />
            <AppRoutes />
          </Grommet>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}
export default App;

const Layout = () => {
  const spinner = useSelector((state) => {
    return state.spinner.value;
  });
   if (spinner > 0) {
    return <Layer responsive={false} className="spinner"></Layer>;
   }
};
