import { Tab, Tabs, Text } from "grommet";
import { Document, User } from "grommet-icons";
import { useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { BackBox, DeveloperForm, DropDown, FileList } from "../../components";
import { FlexBar } from "../../components/shared/style";
import { Constant, Users } from "../../lib/Constant";
import useAPI from "../../lib/api/useApi";
import { siteDefaultFiles } from "../../lib/common";

const RowGroup = styled(FlexBar)`
  display: inline-flex;
  margin-left: 24px;
`;

const TextField = styled(Text)`
  font-size: 20px;
  text-align: right;
  font-weight: 500;
  color: #000000;
  margin-right: 8px;
  width: 148px;
`;

const EditDeveloper = () => {
  const siteListRef = useRef();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [documentSiteId, setdocumentSiteId] = useState();
  const currentUser = useSelector((state) => {
    return state.user.value;
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const id =
    location.pathname.split("/")[2] ||
    (currentUser.role === Users.Developer && currentUser._id);

  const { GET, PUT } = useAPI();

  const { mutate, isLoading: isSubmitting } = useMutation("builderUpdate", PUT);
  const [files, setFiles] = useState([]);
  const [siteFiles, setSiteFiles] = useState([]);
  const [formValues, setFormValues] = useState();
  const { isLoading } = useQuery(
    "developer",
    async () =>
      await GET({
        path: `developer/${id}`,
      }),
    {
      onSettled: (data) => {
        if (data?.data?.developer) {
          const developer = data.data.developer;
          setFormValues({
            developerName: developer.userId.userName,
            companyName: developer.companyName,
            constitutionSelect: developer.isOtherConstitution
              ? Constant.Constitution.Other
              : developer.constitution,
            constitution: developer.constitution,
            isOtherConstitution: developer.isOtherConstitution,
            officeAddress: developer.officeAddress,
            promoterName: developer.promoterName,
            promoterContactNumber: developer.userId.mobileNumber,
            contactPersonName: developer.contactPersonName,
            contactPersonNumber: developer.contactPersonNumber,
            email: developer.userId.email,
            website: developer.website,
            referredBy: developer.referredBy,
            referralContactNumber: developer.referralContactNumber,
            cardNumber: developer?.cardId?.cardNumber,
            developerLogoId: developer.developerLogoId,
            developerLogo: developer.developerLogo
              ? [{ name: developer.developerLogo }]
              : developer.developerLogo,
          });

          setFiles(developer?.documents || []);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: fileMutate } = useMutation("getSiteFiles", GET);

  const { data: cardData } = useQuery(
    "getCards",
    async () => {
      if (currentUser.role === Users.Admin) {
        return await GET({
          path: `card`,
        });
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: siteData } = useQuery(["getPersonalSites", id], async () => {
    if (id) {
      return await GET({
        path: `site/developer/${id}`,
      });
    }
  });

  const onFormChange = (value) => {
    setFormValues(value);
  };

  const filterFiles = (status, tempData) => {
    const newFiles = siteDefaultFiles
      .filter((x) => (x?.status ? x.status === status : true))
      .map((item) => {
        const dbFile = (tempData || siteData?.data?.site)?.documents?.[
          item.type
        ];
        if (dbFile) {
          return { ...item, ...dbFile };
        } else {
          return item;
        }
      });
    setSiteFiles(() => {
      return newFiles;
    });
    siteListRef.current.updateChildFiles(newFiles);
  };

  const onSubmit = async (formData) => {
    mutate(
      {
        path: `developer/${id}`,
        type: "form",
        body: formData,
        isApiToast: true,
      },
      {
        onSettled: (res) => {
          if (res.data.is_success) {
            if (currentUser.role === Users.Developer) {
              queryClient.invalidateQueries(["userProfile"]);
            }
            queryClient.invalidateQueries("developer");
          }
        },
      }
    );
  };

  return (
    <>
      {Constant.rights[currentUser.role].developer.view && (
        <BackBox link="/Developers" />
      )}
      <Tabs
        activeIndex={activeIndex}
        onActive={(newActiveIndex) => {
          setActiveIndex(newActiveIndex);
        }}
        justify="start"
      >
        <Tab className="subTab" icon={<User />} title="Profile">
          {formValues ? (
            <DeveloperForm
              formValues={formValues}
              handleSubmit={onSubmit}
              onFormChange={onFormChange}
              isLoading={isLoading}
              id={id}
              cardData={cardData}
              isSubmitting={isSubmitting}
            />
          ) : (
            <div>Loading ...</div>
          )}
        </Tab>
        <Tab
          className="subTab"
          icon={<Document />}
          disabled={!formValues}
          title={`${
            currentUser.role === Users.Developer
              ? "Personal Documents"
              : "Documents"
          }`}
        >
          <FileList
            allowedExtensions={/(\.jpg|\.jpeg|\.png|\.pdf)$/i}
            extensions={["JPG", "JPEG", "PNG", "PDF"]}
            pdfViewPath={`developer/file?developerId=${id}&`}
            imgViewPath={`developer/file?developerId=${id}&`}
            thumbPath={`developer/file/thumbnails?developerId=${id}&`}
            uploadObject={{
              id,
              paramId: "developerId",
              path: "developer/uploadDocuments",
              type: Constant.DocumentTypes.developerDocuments,
              deletePath: `developer/file/delete?developerId=${id}&fileId=`,
            }}
            withContainer={true}
            files={files}
            handleSetFiles={(passedFiles) => {
              setFiles(passedFiles);
            }}
            maxFile={Constant.Documents.developer.document.limit}
          />
        </Tab>
        <Tab
          disabled={!formValues}
          className="subTab"
          icon={<Document />}
          title="Site Documents"
        >
          <>
            <FileList
              ref={siteListRef}
              key={`doc -${documentSiteId}`}
              extensions={["PDF"]}
              allowedExtensions={/(\.pdf)$/i}
              pdfViewPath={`site/file/siteDocuments?siteId=${documentSiteId}&`}
              withContainer={false}
              files={siteFiles}
              emptyMsg="No site selected"
              uploadObject={{
                id: documentSiteId,
                paramId: "siteId",
                path: "site/uploadDocument",
                type: Constant.DocumentTypes.siteDocuments,
                deletePath: `site/file/delete?siteId=${documentSiteId}&fileType=`,
              }}
              handleSetFiles={(passedFiles) => {
                setSiteFiles(passedFiles);
              }}
              isFilterDrop={true}
              selectElement={
                <RowGroup>
                  <TextField>Project site</TextField>
                  <DropDown
                    placeholder="Select site"
                    optionsData={
                      siteData?.data?.sites?.map((site) => {
                        return {
                          siteId: site._id,
                          projectName: site.projectName,
                        };
                      }) || []
                    }
                    labelKeyData="projectName"
                    valueKeyData={{ key: "siteId", reduce: true }}
                    valuesData={documentSiteId}
                    handleChange={({ value: nextValue }) => {
                      console.log(siteListRef);
                      setdocumentSiteId(nextValue);
                      fileMutate(
                        {
                          path: `site/${nextValue}`,
                          spin: true,
                        },
                        {
                          onSettled: (data) => {
                            if (data?.is_success && data?.data?.site) {
                              const site = data.data.site;
                              filterFiles(site?.projectStatus, site);
                            }
                          },
                        }
                      );
                    }}
                  />
                </RowGroup>
              }
            />
          </>
        </Tab>
      </Tabs>
    </>
  );
};

export default EditDeveloper;
