import { Box, FormField, Select } from "grommet";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SelectBox = styled(Select)`
   {
    ::placeholder,
    ::-webkit-input-placeholder {
      font-size: 16px;
      font-weight: lighter;
      line-height: 24px;
      color: #000000;
      background-color: inherit;
    }
  }
`;

const Container = styled(Box)`
  height: 44px;
  background-color: white;
  border: 2px solid black;
  &:focus-within {
    outline: black solid 2px;
  }
  border-radius: 4px;
  ${({ isValid }) =>
    !isValid &&
    `
&:focus-within  {
outline: red solid 2px;
}
border: 2px solid red;
`}
`;

const DropDown = ({
  id,
  optionsData,
  valuesData,
  handleChange,
  labelsData,
  labelKeyData,
  valueKeyData,
  required,
  placeholder,
  parentForm,
  ...rest
}) => {
  useEffect(() => {
    let defualtId = 0;
    if (optionsData.length === 1 && !valuesData) {
      defualtId = Object.values(optionsData[0])[0];
      handleChange && handleChange({ value: defualtId });
    }
  }, [optionsData, valuesData, handleChange]);

  useEffect(() => {
    if (parentForm?.current) {
      const element = parentForm.current;
      const handleClick = (event) => {
        setIsValid(true);
      };
      element.addEventListener("reset", handleClick);
      return () => {
        element.removeEventListener("reset", handleClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isValid, setIsValid] = useState(true);
  const textLabel = labelsData?.toString().replace("*", "");
  return (
    <>
      <FormField
        name={id}
        style={{ marginBottom: labelsData ? "12px" : "0px", width: "100%" }}
        label={labelsData}
        validate={(val) => {
          if (!val && required) {
            setIsValid(false);
            return `${textLabel} is required`;
          } else {
            setIsValid(true);
          }
        }}
      >
        <Container isValid={isValid}>
          <SelectBox
            plain
            name={id}
            id={id}
            options={optionsData}
            value={valuesData}
            labelKey={labelKeyData}
            valueKey={valueKeyData}
            onChange={handleChange}
            placeholder={`${placeholder || "Select " + (textLabel || "")}`}
            {...rest}
          />
        </Container>
      </FormField>
    </>
  );
};

export default DropDown;
