import { css } from "styled-components";
import { NextPage, PrevPage, RemoveTextIcon } from "../components/shared/style";

const Theme = {
  global: {
    font: {
      family: "Roboto",
      size: "16px",
      height: "20px",
    },
    colors: {
      brand: "#000000",
      hoverFg: "white",
    },
    input: {
      weight: 500,
    },
    focus: {
      outline: {
        color: "brand",
      },
    },
  },
  heading: {
    color: "brand",
  },
  accordion: {
    hover: {
      heading: {
        color: "black",
      },
    },
    panel: {
      border: {
        color: "black",
        size: "xsmall",
      },
    },
  },
  fileInput: {
    background: {
      color: "#ffffff",
    },
    button: {
      color: "#7D4CDB",
    },
    icons: {
      remove: RemoveTextIcon,
    },
    extend: () => css`
      span {
        @media only screen and (max-width: 769px) {
          font-size: 16px;
          margin-right: -8px;
        }
      }
      button {
        @media only screen and (max-width: 769px) {
          font-size: 16px;
          padding: 4px;
          padding-right: 16px;
        }
      }
    `,
  },
  text: {
    color: "red",
  },

  formField: {
    error: {
      size: "small",
      style: {
        marginBottom: "-4px",
        marginLeft: "0px",
      },
    },
    border: {
      color: "none",
      error: {
        color: "none",
      },
    },
    label: {
      style: {
        fontSize: "16px",
        marginLeft: "0px",
        fontWeight: "500",
        color: "#000000",
      },
    },
  },
  dataTable: {
    header: {
      color: {
        light: "#000000",
      },
      font: {
        size: "14px",
        weight: "600",
      },
      extend: ({ Theme }) => css`
        border-bottom-color: #999999;
        span {
          line-height: 18px;
        }
      `,
    },
  },
  table: {
    body: {
      extend: ({ Theme }) => css`
        border-bottom-color: #cccccc;
        border-bottom-style: solid;
        border-bottom-width: 1px;

        span {
          color: #000000;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }
      `,
    },
  },
  pagination: {
    button: {
      color: "black",
      background: {
        color: "white",
      },
      active: {
        background: {
          color: "black",
        },
        color: "white",
      },
      hover: {
        color: "white",
        background: {
          color: "black",
        },
      },
    },
    icons: {
      next: NextPage,
      previous: PrevPage,
    },
    container: {
      extend: ({ Theme }) => css`
        margin-top: 16px;
      `,
    },
  },
  menu: {
    icons: {
      color: "white",
    },
  },
  list: {
    item: {
      pad: { horizontal: "xsmall" },
      background: ["white"],
      border: false,
      extend: ({ Theme }) => css`
        box-shadow: 0px 4px 8px 0px #ccc;
        span {
          font-size: 16px;
        }
      `,
    },
  },
  tabs: {
    header: {
      extend: ({ Theme }) => css`
        border-bottom-color: #cccccc;
        border-bottom-style: solid;
        border-bottom-width: 2px;
      `,
    },
  },
  tab: {
    extend: ({ Theme }) => css`
      margin-bottom: -2px;
      font-weight: 700;
    `,
    color: "text-strong",
    active: {
      background: "background-contrast",
    },
    border: {
      side: "bottom",
      color: "transparent",
      size: "6px",
      active: {
        color: "black",
      },
      disabled: {
        color: "border-weak",
      },
      hover: {
        color: "border",
      },
    },
    disabled: {
      color: "text-weak",
    },
    hover: {
      background: "background-contrast",
      color: "text",
    },
    pad: "small",
    margin: {
      horizontal: "none",
    },
  },
  layer: {
    zIndex: 2001,
  },
  checkBox: {
    extend: `
          color: brand;
          font-weight:500;
      `,
  },
  // dateinput:{
  //   icon:{
  //     extend: ({ Theme }) => css`
  //     text-align: right;
  //     right: 0px;
  //     right: 0px;
  //     opacity: 0;
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     cursor: pointer;
  //   `,
  //   }
  // }
  // card: {
  //   container: {
  //     elevation: "none",
  //     extend: ({ Theme }) => css`
  //     border: 0.01px solid black;
  //     border-radius: 0px !important;
  //     ;
  //   `,
  //   },
  // },
};

export default Theme;
