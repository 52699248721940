import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConfirm } from "../features/dialougeSlice";

const useConfirm = () => {
  const dispatch = useDispatch();
  const confirm = useSelector((state) => {
    return state.confirm.value;
  });
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isConfirmed = (prompt, heading) => {
    const promise = new Promise((resolve, reject) => {
      dispatch(
        setConfirm({
          prompt,
          heading,
          isOpen: true,
          proceed: resolve,
          cancel: reject,
        })
      );
      setNeedsCleanup(true);
    });

    const reset = () => {
      dispatch(
        setConfirm({
          prompt: "",
          heading: "",
          proceed: null,
          cancel: null,
          isOpen: false,
        })
      );
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed,
  };
};

export default useConfirm;
