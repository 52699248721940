import { Box, Text } from "grommet";
import { Close, FormClose } from "grommet-icons";
import ImgsViewer from "react-images-viewer";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useAPI from "../../lib/api/useApi";
import { getCommonFilePath } from "../../lib/common";
import { Constant, Users } from "../../lib/Constant";
import PopUp from "../popUp";
import { FlexBar } from "./style";

const Title = styled(Text)`
  color: white;
  font-weight: 600;
  font-size: 20px;
  margin: auto;
  margin-left: 0px;
  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

const CloseButton = styled(FormClose)`
  cursor: pointer;
  font-size: 20px;
  stroke: white;
  font-size: 20px;
  border-left: 1px solid white;
  margin: auto;
  padding-left: 8px;
`;

const PopUpHeader = styled(Box)`
  background-color: #fd8411;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding: 0px 12px;
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    height: 50px;
  }
`;

const Controls = styled(Box)`
  width: 100%;
  flex-direction: row-reverse;
  margin-bottom: 4px;
`;

const FileViewer = ({
  title,
  togglePopup,
  fileId,
  originalName,
  urlObject,
  imgViewPath,
  isPdf,
  directPath,
  showEmailIcon = true,
}) => {
  const { GET } = useAPI();

  const currentUser = useSelector((state) => {
    return state.user.value;
  });

  const sendEmail = () => {
    GET({
      path: `user/mailDocument?fileId=${fileId}&fileName=${originalName}`,
      spin: true,
      message: "Email sent successfully on your registered email address",
    });
  };
  return (
    <>
      {isPdf ? (
        <PopUp
          content={
            <>
              <PopUpHeader>
                <Title>{title}</Title>
                <FlexBar>
                  {showEmailIcon &&
                    (currentUser.role === Users.Member
                      ? currentUser?.site?.allowMemberToDownload
                      : true) &&
                    (currentUser?.email ||
                      currentUser?.userId?.email ||
                      currentUser?.user?.email) && (
                      <img
                        onClick={sendEmail}
                        style={{
                          marginRight: "16px",
                          height: "32px",
                          cursor: "pointer",
                        }}
                        src={Constant.Icons.email}
                        alt="email"
                      />
                    )}

                  <CloseButton onClick={togglePopup}>X</CloseButton>
                </FlexBar>
              </PopUpHeader>
              <iframe
                onLoad={() => {
                  if (
                    currentUser.role === Users.Member &&
                    !currentUser?.site?.allowMemberToDownload
                  ) {
                    window[0].document.getElementById("print").style.display =
                      "none";
                    window[0].document.getElementById(
                      "download"
                    ).style.display = "none";
                  }
                }}
                title="PDF"
                src={`/pdfjs-3.0.279-dist/web/viewer.html?file=${urlObject}`}
                width={"100%"}
                height={window.innerHeight * 0.84}
              ></iframe>
            </>
          }
          handleClose={togglePopup}
        />
      ) : (
        <ImgsViewer
          spinner={() => {
            return <div className="spinner"></div>;
          }}
          showCloseBtn={false}
          customControls={[
            <Controls>
              <Close
                style={{ marginTop: "4px", cursor: "pointer" }}
                onClick={togglePopup}
                color="white"
              />

              {showEmailIcon &&
                (currentUser?.email ||
                  currentUser?.userId?.email ||
                  currentUser?.user?.email) && (
                  <img
                    onClick={sendEmail}
                    style={{
                      marginRight: "16px",
                      height: "32px",
                      cursor: "pointer",
                    }}
                    src={Constant.Icons.email}
                    alt="email"
                  />
                )}
            </Controls>,
          ]}
          imgs={[
            {
              src:
                directPath ||
                getCommonFilePath(fileId, originalName, imgViewPath),
            },
          ]}
          showImgCount={false}
          isOpen={true}
          onClose={togglePopup}
        />
      )}
    </>
  );
};

export default FileViewer;
