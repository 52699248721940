import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { BackBox, SiteForm } from "../../components";
import useAPI from "../../lib/api/useApi";

const AddSite = () => {
  const { POST } = useAPI();
  const location = useLocation();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    projectName: "",
    developerId: location.state?.developerId,
    projectPostalAddress: "",
    finalPlotNumber: "",
    surveyNumber: "",
    TP_SchemeNumber: "",
    village: "",
    taluka: "",
    district: "",
    subDistrict: "",
    city: "",
    state: "",
    pinNumber: "",
    projectLegalAddress2: "",
    projectTypeSelect: "",
    projectType: "",
    isOtherProjectType: false,
    numberOfTowers: "",
    projectTypeUnits: [],
    projectStatus: "",
    RERA_RegistrationNumber: "",
    RERA_RegistrationDate: "",
    rajachitthi: "",
    projectEndDate: "",
    allowMemberToDownload:true,
  });
  const queryClient = useQueryClient();
  const { mutate,isLoading: isSubmitting } = useMutation("siteAdd", POST);

  const onFormChange = (value) => {
    setFormValues(value);
  };

  const onSubmit = (data) => {
    mutate(
      {
        path: "site",
        isApiToast:true,
        body: data,
      },
      {
        onSettled: (res) => {
          if (res.data.is_success) {
            queryClient.invalidateQueries("getDeveloperSites");
            navigate("/Sites");
          }
        },
      }
    );
  };

  return (
    <>
      <BackBox title="Add New Project Site" link="/Sites" />
      <SiteForm
        formValues={formValues}
        handleSubmit={onSubmit}
        onFormChange={onFormChange}
        isSubmitting={isSubmitting}
        isAddSite={location.state?.isAddSite}
      />
    </>
  );
};

export default AddSite;
