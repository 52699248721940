import {
  Button as Gbutton,
  FormField,
  TextArea,
  TextInput as GtextInput
} from "grommet";
import { Hide, View } from "grommet-icons";
import { memo, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import styled from "styled-components";

const InputWrapper = styled(GtextInput)`
  height: 44px;
  border: 2px solid black;
  background-color: white;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: lighter;
    line-height: 24px;
    color: #000000;
    background-color: inherit;
  }
  &.textArea {
    height: 90px;
  }

  ${({ isDate }) =>
    isDate &&
    `
&:focus-within {
outline: black solid 2px;
}

`}

  ${({ isValid }) =>
    !isValid &&
    `
  &:focus-within {
  outline: red solid 2px;
  }
 border: 2px solid red;
`}
`;

const FieldWrapper = styled(FormField)`
  margin-bottom: ${(props) => (!props.isFreeField ? "12px" : "0px")};
  display: ${(props) => (props.isDisplay ? "flex" : "none")};

  & span {
    ${({ isFreeField }) =>
      isFreeField &&
      `
      display:none;
`}
  }
`;

const TextInput = ({
  id,
  label,
  type,
  handleChange,
  required,
  min,
  max,
  regEx,
  compare,
  length,
  prefix = null,
  suffix = null,
  placeholder,
  dateFormat,
  isFreeField,
  display = true,
  reqMsg,
  formValue,
  isOptional = false,
  optionalField,
  minDate,
  isWithoutForm = false,
  parentForm,
  ...rest
}) => {
  const textType = type?.toLowerCase();
  const [reveal, setReveal] = useState(false);
  const [validation, setValidation] = useState({ isValid: true, message: "" });
  const textLabel = label?.toString().replace("*", "");
  const onValidate = (val) => {
    const getMessage = (msg) => {
      const actualMsg = `${textLabel || "This field"} ${msg}`;
      setValidation({ isValid: false, message: actualMsg });
      return actualMsg;
    };

    const dummyVal = val || "";
    if (dummyVal.toString().trim() === "" && required && display) {
      return getMessage(`is required`);
    } else if (
      val &&
      (parseInt(val) < (min || 0) || parseInt(val) > (max || Number.MAX_VALUE))
    ) {
      return getMessage(`must be between ${min || 0} to ${max || "any"}`);
    } else if (val && regEx && !new RegExp(regEx.pattern).test(val)) {
      return getMessage(`${regEx?.message}`);
    } else if (compare && compare.value !== val) {
      return getMessage(` is not matched with ${compare.field}`);
    } else if (val && val.length > length) {
      return getMessage(`can not be greater than ${length} characters`);
    } else if (val && isOptional) {
      return `Please remove other ${optionalField}`;
    } else {
      setValidation({ isValid: true, message: "" });
    }
  };

  useEffect(() => {
    if (parentForm?.current) {
      const element = parentForm.current;
      const handleClick = (event) => {
        setValidation({ isValid: true, message: "" });
      };
      element.addEventListener("reset", handleClick);
      return () => {
        element.removeEventListener("reset", handleClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FieldWrapper
        isDisplay={display}
        isFreeField={isFreeField}
        htmlFor={id}
        name={id}
        label={label}
        validate={onValidate}
      >
        {textType === "textarea" ? (
          <InputWrapper
            as={TextArea}
            name={id}
            plain={false}
            size="medium"
            placeholder={
              placeholder ? placeholder : `Enter ${textLabel || "value"}`
            }
            className="textArea"
            isValid={reqMsg ? false : validation.isValid}
          />
        ) : (
          <>
            {textType === "date" ? (
              <InputWrapper
                className="react-calender"
                isDate={true}
                isValid={reqMsg ? false : validation.isValid}
                id={id}
                name={id}
                as={DatePicker}
                onChange={handleChange}
                value={formValue && new Date(formValue)}
                minDate={minDate ? minDate : null}
              />
            ) : (
              <InputWrapper
                title={
                  isFreeField && !validation.isValid ? validation.message : ""
                }
                required={reqMsg ? true : false}
                onChange={(e) => {
                  if (isWithoutForm) {
                    onValidate(e.target.value);
                  }
                  handleChange && handleChange(e);
                }}
                isValid={reqMsg ? false : validation.isValid}
                type={reveal ? "text" : textType}
                id={id}
                name={id}
                placeholder={
                  placeholder ? placeholder : `Enter ${textLabel || "value"}`
                }
                icon={
                  type === "password" ? (
                    <Gbutton
                      style={{ pointerEvents: "all", cursor: "pointer" }}
                      icon={
                        reveal ? <View size="medium" /> : <Hide size="medium" />
                      }
                      onClick={() => setReveal(!reveal)}
                    />
                  ) : null
                }
                {...rest}
              />
            )}
          </>
        )}
      </FieldWrapper>
    </>
  );
};

export default memo(TextInput);
