import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Pagination,
  Text,
} from "grommet";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Constant } from "../../lib/Constant";
import { FlexBar } from "./style";

const EmptyContainer = styled(Heading)`
   {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
  }
`;

const Item = styled(Card)`
  margin: 12px;
  color: black;
  border-radius: 4px;
  padding: 8px
    ${(props) =>
      props.hasFile &&
      `padding: 8px;
`};
  @media (max-width: 600px) {
    width: 100%;
    margin: 8px 0px;
  }
`;

const Group = styled(FlexBar)`
  flex-direction: column;
  align-items: flex-start;
  margin: 8px;
  word-break: break-word;
  width: calc(50% - 16px);
`;

const Content = styled(Text)`
  text-align: left;
  font-size: 16px;
  min-height: 12px;
`;

const Wrapper = styled(FlexBar)`
  width: 100%;
  margin-top: 12px;
  align-items: ${(props) =>
    props.tableConfig?.flex ? "flex-end" : "flex-start"};
`;

function CardTable({
  columns,
  data,
  tableConfig,
  onClickRow,
  isLoading,
  onSelect,
  select,
  primaryKey,
  disabled,
}) {
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    setCurrentData(data?.slice(0, tableConfig.itemsPerPage));
  }, [data, tableConfig]);

  if (isLoading) {
    return <div style={{ textAlign: "center" }}>Loading ...</div>;
  }

  const handleChange = ({ startIndex, endIndex }) => {
    const nextData = data?.slice(startIndex, endIndex);
    setCurrentData(nextData);
  };

  function getValue(item) {
    if (this) {
      return this.replace(/\[([^[\]]*)\]/g, ".$1.")
        .split(".")
        .filter((t) => t !== "")
        .reduce((prev, cur) => prev && prev[cur], item);
    }
  }

  const getFor = (dt, item) => {
    const lst = [];
    for (let index = 0; index < dt.length; index = index + 2) {
      lst.push(
        <Wrapper tableConfig={tableConfig} key={index}>
          <Group>
            {React.cloneElement(dt?.[index]?.header, {
              className: "cardLabel",
            })}
            <Content>
              {dt?.[index]?.render
                ? dt?.[index].render(item)
                : dt?.[index]?.property &&
                  getValue.call(dt?.[index].property, item)}
            </Content>
          </Group>

          {dt?.length > index + 1 && (
            <Group>
              {React.cloneElement(dt?.[index + 1]?.header, {
                className: "cardLabel",
              })}
              {dt?.[index + 1]?.render
                ? dt?.[index + 1].render(item)
                : dt?.[index + 1]?.property &&
                  getValue.call(dt?.[index + 1].property, item)}
            </Group>
          )}
        </Wrapper>
      );
    }
    return lst;
  };

  return (
    <>
      {currentData?.length > 0 ? (
        <>
          {currentData?.map((item, index) => {
            return (
              <Item key={index}>
                <CardHeader
                  style={{ padding: "0px", paddingLeft: "4px" }}
                  pad="medium"
                >
                  <Heading level="3" style={{ margin: "0px" }}>
                    {(() => {
                      const headerData = columns.find(
                        (x) => x.fieldType === "header"
                      );
                      return headerData?.render
                        ? headerData.render(item)
                        : getValue.call(headerData?.property || "", item);
                    })()}
                  </Heading>
                </CardHeader>
                <CardBody>
                  {getFor(
                    columns.filter((x) => x.fieldType === "field"),
                    item
                  )}
                </CardBody>

                {(() => {
                  const buttonFields = columns.filter(
                    (x) => x.fieldType === "button"
                  );

                  return buttonFields.length > 0 ? (
                    <CardFooter
                      pad={{ horizontal: "small" }}
                      style={{ height: "44px", marginTop: "12px" }}
                      background="light-2"
                    >
                      {columns
                        .filter((x) => x.fieldType === "button")
                        .map((cItem,cIndex) => {
                          return <FlexBar key={cIndex}>{cItem.render(item)}</FlexBar>;
                        })}
                    </CardFooter>
                  ) : null;
                })()}
              </Item>
            );
          })}
          <Pagination
            step={tableConfig?.itemsPerPage}
            numberItems={data?.length}
            onChange={handleChange}
            style={{ alignItems: "flex-end" }}
          />
        </>
      ) : (
        <EmptyContainer level="3">
          {!tableConfig?.emptyTableMsg
            ? Constant.Pagination.defaultEmptyTableMsg
            : tableConfig?.emptyTableMsg}
        </EmptyContainer>
      )}
    </>
  );
}

export default CardTable;
