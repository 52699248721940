import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionHeader, FieldHeader } from "../../components";
import ListView from "../../components/shared/listView";
import { AddIcon } from "../../components/shared/style";
import { setSelection } from "../../features/selectionSlice";
import useAPI from "../../lib/api/useApi";
import { DeleteColumn, editList } from "../../lib/common";
import { Constant } from "../../lib/Constant";

const SitesList = () => {
  const queryClient = useQueryClient();
  const { DELETE, GET } = useAPI();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selection = useSelector((state) => {
    return state.selection.value;
  });
  const currentUser = useSelector((state) => {
    return state.user.value;
  });
  const currentRight = Constant.rights[currentUser.role].site;

  const { mutate: deletePost } = useMutation("siteDelete", DELETE);

  const { data: siteData, isLoading } = useQuery(
    ["getDeveloperSites", selection.developerId],
    async () => {
      if (selection.developerId) {
        return await GET({
          path: `site/developer/${selection.developerId}`,
        });
      }
    }
  );

  const defaultColumns = [
    {
      property: "projectName",
      header: <FieldHeader> Project Name</FieldHeader>,
      fieldType: "header",
    },
    {
      property: "projectType",
      header: <FieldHeader> Project Type</FieldHeader>,
      fieldType: "field",
    },

    {
      property: "projectStatus",
      header: <FieldHeader> Project Status</FieldHeader>,
      fieldType: "field",
    },

    {
      property: "addUnit",
      header: <ActionHeader>Add Units</ActionHeader>,
      render: (data) => (
        <AddIcon
          onClick={() => {
            dispatch(setSelection({ siteId: data._id }));
            navigate("/Units");
          }}
        />
      ),
      align: "center",
      fieldType: "button",
    },
    editList({
      onClick: (data) => {
        navigate(`/sites/${data._id}`, {
          state: { developerId: selection.developerId },
        });
      },
    }),
  ];

  if (currentRight.delete) {
    defaultColumns.push(
      DeleteColumn({
        onDelete: (data) => {
          deletePost(
            {
              path: `site/${data._id}`,
              isApiToast:true,
            },
            {
              onSuccess: (res) => {
                queryClient.invalidateQueries("getDeveloperSites");
              },
            }
          );
        },
      })
    );
  }
  const custMsg = "Please select any developer to see their sites";
  const searchFields = ["projectName", "projectStatus", "projectType"];
  return (
    <ListView
      isAutoMobile={true}
      isLoading={isLoading}
      title="Project site list"
      searchPlaceHolder="Search project site"
      newButtonText={currentRight.add && "Add New Project Site"}
      searchFields={searchFields}
      tableData={siteData?.data?.sites}
      defaultColumns={defaultColumns}
      tableConfig={{
        ...Constant.Pagination.Sites,
        emptyTableMsg: selection.developerId
          ? Constant.Pagination.Sites.emptyTableMsg
          : custMsg,
      }}
      newButtonDisabled={!selection.developerId}
      handeNewClick={() => {
        navigate("/sites/add", {
          state: { developerId: selection.developerId },
        });
      }}
    />
  );
};

export default SitesList;
