import { Layer as GLayer } from "grommet";
import styled from "styled-components";

const PopupBox = styled(GLayer)`
   {
    background: #00000050;
    width: 100%;
    height: 200vh;

    @media only screen and (max-width: 767px) {
      height: 100%;
    }
  }
`;

const BoxModal = styled.div`
   {
    position: relative;
    width: 60%;
    margin: 0 auto;
    height: auto;
    max-height: 85vh;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #999;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-bottom: 40px;
    @media only screen and (max-width: 768px) {
      width: 95% !important;
    }
  }
`;

const PopUp = (props) => {
  return (
    <PopupBox full animation="fadeIn">
      <BoxModal style={props.otherStyle}>{props.content}</BoxModal>
    </PopupBox>
  );
};

export default PopUp;
