import { Box, Image, Text } from "grommet";
import React from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { Constant, isMobile, Users } from "../lib/Constant";
import { FlexBar } from "./shared/style";
import { useSelector } from "react-redux";

const Container = styled(FlexBar)`
   {
    height: 100%;
    align-items: inherit;
    background: #333333;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
`;

const LeftSide = styled(Box)`
   {
    height: 100%;
    width: 40%;
    padding: 50px 80px;
    color: white;

    @media only screen and (max-width: 1024px) {
      width: 50%;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (max-width: 430px) {
      padding: 24px;
    }
  }
`;

const RightSide = styled(Box)`
   {
    width: 60%;
    background: #ffffff;

    @media only screen and (max-width: 1024px) {
      width: 50%;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      height: 100%;
    }
    @media only screen and (max-width: 430px) {
      padding: 24px;
    }
  }
`;

export const LogoImage = styled(Image)`
   {
    width: 240px;
    height: 60px;
  }
`;

const BrandBox = styled(Box)`
   {
    margin-top: 54px;
  }
`;

const BrandText = styled(Text)`
   {
    font-size: 28px;
    font-weight: 400;

    line-height: initial;
    @media only screen and (max-width: 769px) {
      font-size: 24px;
    }
  }
`;

const CheckPoints = styled(Box)`
   {
    font-weight: 360;
    font-size: 14px;
    margin-left: 4%;

    @media only screen and (max-width: 1025px) {
      font-size: 12px;
    }
  }
`;

const CheckImage = styled(Image)`
   {
    display: flex;
    width: 20px;
    height: 20px;
  }
`;

const Check = styled(Box)`
   {
    margin: auto 0px;
    width: 100%;
    line-spacing: 4%;
  }
`;

const Wrap = styled(FlexBar)`
  padding: 36px 0px;
  //  background-color:red;
`;

const CheckBox = styled(FlexBar)`
   {
    margin-bottom: 12px;
    align-items: flex-start;
    @media only screen and (max-width: 769px) {
      margin-bottom: 2px;
    }
  }
`;
const CloudImage = styled(Image)`
   {
    height: 244px;
    width: 70%;
  }
`;

const IllustrationImage = styled(Image)`
   {
    margin-left: -100px;
    width: 250px;
    height: 98px;
  }
`;

const BottomBox = styled(FlexBar)`
  padding-top: 32px;
`;

const Auth = ({ isLogin, rightScreen }) => {
  const currentUser = useSelector((state) => {
    return state.user.value;
  });
  const SamplePoints = isLogin
    ? [
        "It’s just a card with amazing features ",
        "One Stop solution of your valued property documents ",
        "Easy to access, share and print anywhere anytime",
        "Its secured and trusted by OTP on your mobile",
        "Your documents are totally safe in encrypted mode",
        "Easy to manage on your phone or gadgets",
      ]
    : [
        "Easy to access and use",
        "Affordable and viable",
        "Files storage for long time",
        "Safe, secure and encrypted",
        "Developed by expert IT & Real Estate team",
      ];

  if (localStorage.getItem("access_token")) {
    if (currentUser.role === Users.Member) {
      return <Navigate to="/MyPropertyInformation" />;
    } else if (currentUser.role === Users.Developer) {
      return <Navigate to="/Profile" />;
    } else {
      return <Navigate to="/Developers" />;
    }
  }

  const header = isLogin
    ? "FED UP WITH SO MANY DOCUMENTS FOR A SINGLE PROPERTY?"
    : "MY PROPERTY CARD IS THE ONLY SOLUTION.";

  return (
    <>
      {!isMobile ? (
        <Container>
          <LeftSide>
            <FlexBar>
              <LogoImage src={Constant.Icons.myPropertyCardLogo} />
            </FlexBar>
            <BrandBox>
              <BrandText>{header}</BrandText>
            </BrandBox>

            <Wrap>
              <Check>
                {SamplePoints.map((points, i) => {
                  return (
                    <CheckBox key={i}>
                      <CheckImage src={Constant.Icons.checkMark} />
                      <CheckPoints>{points}</CheckPoints>
                    </CheckBox>
                  );
                })}
              </Check>
            </Wrap>

            <BottomBox>
              {isLogin ? (
                <>
                  <CloudImage src={Constant.Icons.cloud}></CloudImage>
                  <IllustrationImage src={Constant.Icons.illustration} />
                </>
              ) : (
                <CloudImage
                  style={{ width: "100%" }}
                  src={Constant.Icons.OTP}
                ></CloudImage>
              )}
            </BottomBox>
          </LeftSide>
          <RightSide>{rightScreen}</RightSide>
        </Container>
      ) : (
        <RightSide>{rightScreen}</RightSide>
      )}
    </>
  );
};

export default Auth;
