import { Tab, Tabs } from "grommet";
import { Document, Gallery } from "grommet-icons";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FileList } from "../../components";
import { siteDefaultFiles } from "../../lib/common";

const MasterDocuments = (context) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const selection = useSelector((state) => state.selection.value);
  const units = useSelector((state) => state.user.value.units);
  const documentRef = useRef();
  const photosRef = useRef();

  const currentUser = useMemo(() => {
    return units.find((x) => x.unitId === selection.unitId);
  }, [selection.unitId, units]);
  const id = currentUser?.siteId;

  const filterFiles = () => {
    const siteDocList = Object.entries(currentUser?.site?.documents || []);
    return siteDocList.map(([fileType, file]) => {
      const defaultConfig = siteDefaultFiles.find(
        (dbItem) => dbItem.type === fileType
      );
      return { ...file, ...defaultConfig };
    });
  };
  const [files, setFiles] = useState(filterFiles());

  useEffect(() => {
    if (photosRef.current) {
      photosRef.current.updateChildFiles(currentUser?.site?.sitePhotos || []);
    }
    const fFiles = filterFiles();
    setFiles(fFiles);
    if (documentRef.current) {
      documentRef.current.updateChildFiles(fFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection.unitId, currentUser?.site?.sitePhotos]);

  return (
    <Tabs
      activeIndex={activeIndex}
      onActive={(newActiveIndex) => {
        setActiveIndex(newActiveIndex);
      }}
      justify="start"
    >
      <Tab className="subTab" icon={<Document />} title="Documents">
        <FileList
          ref={documentRef}
          key="masterDocuments"
          extensions={["PDF"]}
          allowedExtensions={/(\.pdf)$/i}
          pdfViewPath={`site/file/siteDocuments?siteId=${id}&`}
          withContainer={false}
          files={files}
          canDelete={false}
          emptyMsg="No documents uploaded"
        />
      </Tab>
      <Tab className="subTab" icon={<Gallery />} title="Photos">
        <FileList
          ref={photosRef}
          key="masterPhotos"
          imgViewPath={`site/file/siteDocuments?siteId=${id}&`}
          thumbPath={`site/file/thumbnails?siteId=${id}&`}
          withContainer={true}
          files={currentUser?.site?.sitePhotos || []}
          canDelete={false}
          canUpload={false}
          emptyMsg="No photos uploaded"
          extensions={[]}
        />
      </Tab>
    </Tabs>
  );
};

export default MasterDocuments;
