import { toast } from "react-toastify";
import { ActionHeader } from "../components";
import { DeleteIcon, EditIcon } from "../components/shared/style";
import { Constant } from "./Constant";
import useConfirm from "./useConfirm";

const scrollDown = (id) => {
  const yourHeight = 200;
  document.getElementById(id)?.scrollIntoView(true);

  const scrolledY = window.scrollY;
  if (scrolledY) {
    window.scroll(0, scrolledY - yourHeight);
  }
};

const getCommonFilePath = (id, name, apiPath) => {
  return `${
    process.env.REACT_APP_API_URL
  }${apiPath}auth_token=${localStorage.getItem(
    "access_token"
  )}&fileId=${id}&fileName=${name}`;
};

const setToaster = (type, msg) => {
  if (msg) {
    toast?.[type](msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const editList = ({ header, onClick }) => {
  return {
    property: "edit",
    header: <ActionHeader> {header || "Edit"}</ActionHeader>,
    render: (data) => (
      <EditIcon
        onClick={() => {
          onClick(data);
        }}
      />
    ),
    align: "center",
    fieldType: "button",
  };
};

const objExt = {
  extensions: ["PDF"],
  allowedExtensions: /(\.pdf)$/i,
};

const memberDefaultFiles = [
  {
    type: "allotmentLetter",
    displayName: "Allotment Letter",
    allowedExtensions: /(\.pdf)$/i,
    extensions: ["PDF"],
  },
  {
    type: "agreementToSale",
    displayName: "Agreement To Sale",
    icon: Constant.Icons.sale,
    allowedExtensions: /(\.pdf)$/i,
    extensions: ["PDF"],
  },
  {
    type: "saleDeed",
    displayName: "Sale Deed / Conveyance Deed",
    icon: Constant.Icons.saleDeed,
    allowedExtensions: /(\.pdf)$/i,
    extensions: ["PDF"],
  },
  {
    type: "shareCertificate",
    displayName: "Share Certificate",
    icon: Constant.Icons.certificate,
    allowedExtensions: /(\.pdf)$/i,
    extensions: ["PDF"],
  },
  {
    type: "aadharCard",
    displayName: "Aadhar Card",
    allowedExtensions: /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
    extensions: ["JPG", "JPEG", "PNG", "PDF"],
    icon: Constant.Icons.aadhar,
  },
  {
    type: "panCard",
    displayName: "Pan Card",
    allowedExtensions: /(\.jpg|\.jpeg|\.png|\.pdf)$/i,
    extensions: ["JPG", "JPEG", "PNG", "PDF"],
    icon: Constant.Icons.pancard,
  },
];

const siteDefaultFiles = [
  {
    type: "RERA_Certificate",
    displayName: "RERA Certificate",
    icon: Constant.Icons.certificate,
    ...objExt,
  },
  {
    type: "projectLandSaleDeed",
    displayName: "Project Land - Sale Deed ",
    icon: Constant.Icons.saleDeed,
    ...objExt,
  },
  {
    type: "projectLandChainDocuments",
    displayName: "Project Land – Chain Documents",
    ...objExt,
  },
  {
    type: "formSevenAndTwelve",
    displayName: "Latest Copy of Form 7 & Form 12",
    icon: Constant.Icons.Form_7_12,
    ...objExt,
  },
  {
    type: "formSixEntries",
    displayName: "Form 6 Entries",
    ...objExt,
  },
  {
    type: "NA_Order",
    displayName: "NA Order",
    ...objExt,
  },
  {
    type: "projectBrochure",
    displayName: "Project Brochure",
    icon: Constant.Icons.projectBrochure,
    ...objExt,
  },
  {
    type: "approvedBuildingPlan",
    displayName: "Approved Building Plan",
    icon: Constant.Icons.approvedBuildingPlan,
    ...objExt,
  },
  {
    type: "titleCertificateAndReport",
    displayName: "Title Certificate & Title Report",
    icon: Constant.Icons.certificate,
    ...objExt,
  },
  {
    type: "encumbranceCertificate",
    displayName: "Encumbrance Certificate",
    icon: Constant.Icons.certificate,
    ...objExt,
  },
  {
    type: "developmentAgreement",
    displayName: "Development Agreement ",
    ...objExt,
  },
  {
    type: "bankNoc",
    displayName: "Bank NOC",
    ...objExt,
  },
  {
    type: "fireSaftyCertificate",
    displayName: "Fire Safety Certificate",
    icon: Constant.Icons.certificate,
    ...objExt,
  },
  {
    type: "airportNOC",
    displayName: "Airport NOC ",
    ...objExt,
  },
  {
    type: "environmentCertificate",
    displayName: "Environment Certificate",
    icon: Constant.Icons.certificate,
    ...objExt,
  },
  {
    type: "redevelopmentAgreement",
    displayName: "Re-Development Agreement",
    status: Constant.ProjectStatus.Redevelopment,
    ...objExt,
  },
];

const DeleteColumn = ({ show = true, header, onDelete }) => {
  const { isConfirmed } = useConfirm();
  return {
    property: "delete",
    header: <ActionHeader> {header || "Delete"}</ActionHeader>,
    render: (data) =>
      (show || data?.index !== 0) && (
        <DeleteIcon
          onClick={() => {
            isConfirmed("Are you sure to delete?", "Delete").then((confirm) => {
              if (confirm) {
                onDelete(data);
              }
            });
          }}
        />
      ),
    align: "center",
    fieldType: "button",
  };
};

export {
  scrollDown,
  getCommonFilePath,
  setToaster,
  editList,
  DeleteColumn,
  siteDefaultFiles,
  memberDefaultFiles,
};

