import { Text } from "grommet";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionHeader, FieldHeader } from "../../components";
import ListView from "../../components/shared/listView";
import { AddIcon } from "../../components/shared/style";
import { setSelection } from "../../features/selectionSlice";
import useAPI from "../../lib/api/useApi";
import { DeleteColumn, editList } from "../../lib/common";
import { Constant } from "../../lib/Constant";

const BuilderList = () => {
  const queryClient = useQueryClient();
  const { GET, DELETE } = useAPI();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //delete Logic
  const { mutate: deletePost } = useMutation("developerDelete", DELETE);
  const { data: builderData, isLoading } = useQuery(
    "developers",
    async () => {
      return await GET({
        path: "developer/all",
      });
    }
  );

  const currentUser = useSelector((state) => {
    return state.user.value;
  });
  const currentRight = Constant.rights[currentUser.role].developer;

  const defaultColumns = [
    {
      property: "user.userName",
      header: <FieldHeader> Group Name</FieldHeader>,
      fieldType: "header",
    },
    {
      property: "promoterName",
      header: <FieldHeader> Promoter Name</FieldHeader>,
      fieldType: "field",
      render: (data) => (
        <Text>
          {data.promoterName}
          {data?.card?.cardNumber && (
            <>
              <br />
              <Text
                style={{ fontSize: "10px", color: "grey" }}
              >{`(${data?.card?.cardNumber})`}</Text>
            </>
          )}
        </Text>
      ),
    },
    {
      property: "user.mobileNumber",
      header: <FieldHeader> Promoter Number</FieldHeader>,
      fieldType: "field",
    },
    {
      property: "contactPersonName",
      header: <FieldHeader> Contact Person Name</FieldHeader>,
      fieldType: "field",
    },
    {
      property: "user.email",
      header: <FieldHeader> Contact Person Email</FieldHeader>,
      fieldType: "field",
    },
    editList({
      onClick: (data) => {
        navigate(`/Developers/${data._id}`);
      },
    }),
    {
      property: "addSite",
      header: <ActionHeader> Add Site</ActionHeader>,
      render: (data) => (
        <AddIcon
          onClick={() => {
            dispatch(setSelection({ developerId: data._id, siteId: "" }));

            navigate("/sites/add", {
              state: { developerId: data._id, isAddSite: true },
            });
          }}
        />
      ),
      align: "center",
      fieldType: "button",
    },
  ];

  if (currentRight.delete) {
    defaultColumns.push(
      DeleteColumn({
        onDelete: (data) => {
          deletePost(
            {
              path: `developer/${data._id}`,
              message: Constant.ToasterMessages.Builder.delete.success,
              isApiToast:true,
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(["developers"]);
              },
            }
          );
        },
      })
    );
  }

  const searchFields = [
    "user.userName",
    "promoterName",
    "user.mobileNumber",
    "contactPersonName",
    "user.email",
    "card.cardNumber",
  ];
  return (
    <ListView
      isAutoMobile={true}
      isLoading={isLoading}
      title="Developer list"
      searchPlaceHolder="Search group name"
      newButtonText={currentRight.add === true && "Add New Developer"}
      searchFields={searchFields}
      tableData={builderData?.data?.developers}
      defaultColumns={defaultColumns}
      tableConfig={Constant.Pagination.Builder}
      handeNewClick={() => {
        navigate("/Developers/add");
      }}
    />
  );
};

export default BuilderList;
