import { Box, Heading, Text } from "grommet";
import styled from "styled-components";
import { FlexBar } from "../../components/shared/style";
import { Constant } from "../../lib/Constant";

const Container = styled(Box)`
   {
    min-height:400px;
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    @media only screen and (max-width: 766px) {
      flex-direction: column;
    }
  }
`;
const Label = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;
const Content = styled(Text)`
  margin-top: 4px;
  font-size: 16px;
`;

const SingleRow = styled(FlexBar)`
  flex-direction: column;
  margin-top: 28px;
`;
const LeftSide = styled(Box)`
   {
    width: 50%;
    @media only screen and (max-width: 426px) {
      width: 100%;
    }
  }
`;

const RightSide = styled(Box)`
   {
    position: absolute;
    right: 0px;
    top: 0px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    @media only screen and (max-width: 426px) {
      position: relative;
      width: 100%;
      padding: 8px;
      align-items: center;
    }
  }
`;

const HeaderContent = styled(Heading)`
  max-width: 100%;
`;

const HelpPage = () => {
  return (
    <>
      <HeaderContent level="3" margin="none">
        Help
      </HeaderContent>

      <Container>
        <LeftSide>
          <img src={Constant.Icons.helpPage} alt="ee" />
        </LeftSide>
        <RightSide>
          <SingleRow>
            <Label>Contact Number</Label>
            <Content>+91-8866031196</Content>
          </SingleRow>
          <SingleRow>
            <Label>Email</Label>
            <Content>support@mypropertycard.co.in</Content>
          </SingleRow>
          <SingleRow>
            <Label>Address</Label>
            <Content>
              A-37, Capital Commercial Center,
              <br />
              Nr. Sanyas Ashram, Ashram Road,
              <br />
              Ahmedabad-380009 Gujarat, India
            </Content>
          </SingleRow>
        </RightSide>
      </Container>
    </>
  );
};

export default HelpPage;
