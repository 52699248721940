import { Heading } from "grommet";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Route, Routes } from "react-router-dom";
import { setSpinner } from "../features/spinnerSlice";
import { Constant } from "../lib/Constant";
import Login from "../pages/auth/login";
import CardList from "../pages/cards";
import BuilderList from "../pages/developer";
import AddDeveloper from "../pages/developer/addDeveloper";
import EditDeveloper from "../pages/developer/editDeveloper";
import HelpPage from "../pages/help";
import MasterDocuments from "../pages/member-information/masterDocuments";
import PropertyDocuments from "../pages/member-information/propertyDocuments";
import PropertyInformation from "../pages/member-information/propertyInformation";
import AddMember from "../pages/members/addMember";
import EditMember from "../pages/members/editMember";
import MemberList from "../pages/members/index";
import ScannerList from "../pages/scanners";
import AddScanner from "../pages/scanners/addScanner";
import EditScanner from "../pages/scanners/editScanner";
import SitesList from "../pages/sites";
import AddSite from "../pages/sites/addSites";
import EditSite from "../pages/sites/editSites";
import UnitList from "../pages/units";
import { Auth, OtpVerification } from "../components";
import Layout from "./layout";
import DefaultPage from "./defaultPage";

const AppRoutes = () => {
  const currentUser = useSelector((state) => {
    return state.user.value;
  });
  const userRights = Constant.rights[currentUser?.role];
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type === 1) {
        dispatch(setSpinner(0));
      }
    }
  });
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <DefaultPage />
          </Layout>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route
        path="/verify-otp"
        element={<Auth rightScreen={<OtpVerification />} />}
      />
      <Route
        path="*"
        element={
          <Layout>
            <Routes>
              <Route path="*" element={<Heading>Page Not Found</Heading>} />
              {userRights?.developer?.view && (
                <>
                  <Route path="developers" element={<Outlet />}>
                    <Route index element={<BuilderList />} />
                    {userRights?.developer?.add && (
                      <Route path="add" element={<AddDeveloper />} />
                    )}

                    {userRights?.developer.edit && (
                      <Route path=":id" element={<EditDeveloper />} />
                    )}
                  </Route>
                </>
              )}

              {userRights?.profile.view && (
                <>
                  <Route path="profile" element={<EditDeveloper />} />
                </>
              )}

              {userRights?.site.view && (
                <Route path="sites" element={<Outlet />}>
                  <Route index element={<SitesList />} />
                  {userRights.site.add && (
                    <Route path="add" element={<AddSite />} />
                  )}

                  {userRights.site.edit && (
                    <Route path=":id" element={<EditSite />} />
                  )}
                </Route>
              )}

              {userRights?.scanner.view && (
                <Route path="/scanners" element={<Outlet />}>
                  <Route index element={<ScannerList />} />
                  {userRights.scanner.add && (
                    <Route path="add" element={<AddScanner />} />
                  )}

                  {userRights.scanner.edit && (
                    <Route path=":id" element={<EditScanner />} />
                  )}
                </Route>
              )}

              {userRights?.member.view && (
                <Route path="/Members" element={<Outlet />}>
                  <Route index element={<MemberList />} />
                  {userRights?.member.add && (
                    <Route path="add" element={<AddMember />} />
                  )}

                  {userRights?.member.edit && (
                    <Route path=":id" element={<EditMember />} />
                  )}
                </Route>
              )}

              {userRights?.unit.view && (
                <Route path="/units" element={<UnitList />}>
                  <Route index element={<UnitList />} />
                </Route>
              )}

              {userRights?.card.view && (
                <Route path="/cards" element={<CardList />}>
                  <Route index element={<CardList />} />
                </Route>
              )}

              {userRights?.help.view && (
                <Route path="/help" element={<HelpPage />}>
                  <Route index element={<HelpPage />} />
                </Route>
              )}

              <Route element={<Outlet />}>
                {userRights?.myPropertyInformation.view && (
                  <>
                    <Route
                      index
                      path="MyPropertyInformation"
                      element={<PropertyInformation />}
                    />
                  </>
                )}
                {userRights?.myPropertyDocuments.view && (
                  <Route
                    path="MyPropertyDocuments"
                    element={<PropertyDocuments />}
                  />
                )}

                {userRights?.projectMasterDocuments.view && (
                  <Route
                    path="ProjectMasterDocuments"
                    element={<MasterDocuments />}
                  />
                )}
              </Route>
            </Routes>
          </Layout>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
