import { Box, Form, Text } from "grommet";
import { Close } from "grommet-icons";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Button, DropDown, PopUp, TextInput } from "../../components";
import ListView from "../../components/shared/listView";
import useAPI from "../../lib/api/useApi";
import { Constant, isMobile } from "../../lib/Constant";
import useConfirm from "../../lib/useConfirm";

const ColumnHeader = styled(Text)`
  font-size: 14px;
  line-height: normal;
  color: #000000;
  font-weight: 600;
  text-align: start;
  min-width: 100px;
`;

const BottomBar = styled.div`
  display: flex;
  margin-top: 28px;
  position: relative;
`;

const Content = styled(Box)`
  margin: auto;
  margin-top: 40px;
  width: 40%;

  @media only screen and (max-width: 767px) {
    width: 80%;
  }
`;

const Title = styled.h3`
  font-size: 28px;
`;

const CardList = () => {
  const queryClient = useQueryClient();
  const [cards, setCards] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [reveal, setReveal] = useState(false);
  const { isConfirmed } = useConfirm();
  const [formValues, setFormValues] = useState({});
  const { GET, POST, DELETE, PUT } = useAPI();
  const { mutate } = useMutation("cardGenerate", POST);
  const [isLoading, setIsLoading] = useState(false);
  
  const currentUser = useSelector((state) => {
    return state.user.value;
  });
  const currentRight = Constant.rights[currentUser.role].card;
  const { mutate: deleteCards } = useMutation("delete", DELETE);
  const { mutate: updateCards } = useMutation("upate", PUT);
  const { mutate: downloadCards, isLoading: isSubmitting } = useMutation(
    "downloadCards",
    GET
  );

   useQuery(
    ["getCards"],
    async () => {
      setIsLoading(true);
      const getPromise = new Promise((resolve) => {
        GET({
          path: `card`,
        }).then((data) => {
          const db = data?.data?.cards
            ?.filter((x) => x.status !== Constant.CardStatus.DELETED)
            .map((item, index) => {
              return {
                ...item,
                index,
              };
            });

          resolve(db);
        });
      });
      return await getPromise;
    },
    {
      onSettled: (data) => {
        setSelect([]);
        setCards(data || []);
        setIsLoading(false);
      },
    }
  );

  const onUpdate = () => {
    const ids = select.map((item) => {
      return cards[item]._id;
    });
    updateCards(
      {
        path: `card`,
        message: Constant.ToasterMessages.Card.save.success,
        body: { cardIds: ids },
      },
      {
        onSuccess: (res) => {
          setSelect([]);
          queryClient.invalidateQueries("getCards");
        },
      }
    );
  };

  const [select, setSelect] = useState([]);

  const defaultColumns = [
    {
      property: "index",
      header: <ColumnHeader style={{ minWidth: "40px" }}> Sr No.</ColumnHeader>,
      render: (data) => data.index + 1,
    },
    {
      property: "cardNumber",
      header: (
        <ColumnHeader
          onClick={() => {
            setReveal(!reveal);
          }}
        >
          Card Number
        </ColumnHeader>
      ),
      fieldType: "header",
      render: (data) => {
        return (
          <span>
            {isMobile && (
              <span style={{ color: "grey", marginRight: "8px" }}>
                #{data.index + 1}
              </span>
            )}
            {data.cardNumber}
          </span>
        );
      },
    },
    {
      property: "status",
      header: <ColumnHeader>Status</ColumnHeader>,
      render: (data) =>
        Constant.CardStatus.Assigned === data.status
          ? "Assigned "
          : "Not Assigned",
      fieldType: "field",
    },
    {
      property: "isPrinted",
      header: <ColumnHeader>Printed</ColumnHeader>,
      render: (data) => (data.isPrinted ? "Printed " : "Not Printed"),
      fieldType: "field",
    },
  ];
  const onFormChange = (value) => {
    setFormValues(value);
  };

  const status_all = 9;
  const searchFields = ["cardNumber"];
  const [currentFilter, setCurrentFilter] = useState({
    status: status_all,
    printed: status_all,
  });

  const statusList = [
    { id: status_all, name: "All" },
    { id: 2, name: "Assigned" },
    { id: 3, name: "Not Assigned" },
  ];

  const printList = [
    { id: status_all, name: "All" },
    { id: 1, name: "Yes" },
    { id: 0, name: "No" },
  ];

  return (
    <>
      {isNew && (
        <PopUp
          otherStyle={{ height: "260px", width: "40%", margin: "80px auto" }}
          content={
            <Box
              as={Form}
              value={formValues}
              onChange={onFormChange}
              onSubmit={() => {
                if (formValues.cardDigits && formValues.cardDigits > 0) {
                  mutate(
                    {
                      path: `card`,
                      message: Constant.ToasterMessages.Card.add.success,
                      body: { cardCount: formValues.cardDigits },
                    },
                    {
                      onSuccess: (res) => {
                        setSelect([]);
                        queryClient.invalidateQueries("getCards");
                        setIsNew(false);
                      },
                    }
                  );

                  setFormValues({
                    ...formValues,
                    cardDigits: "",
                  });
                }
              }}
              style={{ padding: "0px 12px" }}
            >
              <Title>Add New Cards</Title>
              <Close
                onClick={() => {
                  setIsNew(false);
                }}
                color="black"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  top: "8px",
                  right: "8px",
                }}
              />

              <Content>
                {" "}
                <TextInput
                  required
                  autoFocus
                  id="cardDigits"
                  label="Enter Number of Cards"
                  placeholder="0"
                />
                <Box style={{ margin: "auto" }}>
                  <Button
                    type="submit"
                    disabled={
                      !formValues.cardDigits || formValues.cardDigits <= 0
                    }
                    primary
                    label="Submit"
                  />
                </Box>
              </Content>
            </Box>
          }
        />
      )}

      <ListView
      onSearch={()=>{
        setSelect([])
      }}
        isAutoMobile={true}
        customRow1={
          <>
            <li style={{ marginBottom: "12px" }}>
              <DropDown
                placeholder="Select site"
                optionsData={
                  statusList?.map((item) => {
                    return {
                      id: item.id,
                      name: item.name,
                    };
                  }) || []
                }
                labelKeyData="name"
                valueKeyData={{ key: "id", reduce: true }}
                onChange={(e) => {
                  setSelect([]);
                  setCurrentFilter({
                    ...currentFilter,
                    status: parseInt(e.target.value),
                  });
                }}
                valueLabel={
                  <Box
                    width="20%"
                    overflow="hidden"
                    align="left"
                    style={{
                      width: "180px",
                      fontWeight: "500",
                      padding: "12px",
                    }}
                  >
                    Status :{" "}
                    {
                      statusList.find((x) => x.id === currentFilter.status)
                        ?.name
                    }
                  </Box>
                }
              />
            </li>
            <li style={{ marginBottom: "12px" }}>
              <DropDown
                optionsData={
                  printList?.map((item) => {
                    return {
                      id: item.id,
                      name: item.name,
                    };
                  }) || []
                }
                labelKeyData="name"
                valueKeyData={{ key: "id", reduce: true }}
                onChange={(e) => {
                  setSelect([]);
                  setCurrentFilter({
                    ...currentFilter,
                    printed: parseInt(e.target.value),
                  });
                }}
                valueLabel={
                  <Box
                    width="20%"
                    overflow="hidden"
                    align="left"
                    style={{
                      width: "116px",
                      fontWeight: "500",
                      padding: "12px",
                    }}
                  >
                    Printed :{" "}
                    {
                      printList.find((x) => x.id === currentFilter.printed)
                        ?.name
                    }
                  </Box>
                }
              />
            </li>
            <li>
              <Button
                onClick={() => {
                  downloadCards(
                    {
                      path: `card/download`,
                      extra: {
                        responseType: "blob",
                      },
                      spin: true,
                    },
                    {
                      onSuccess: (res) => {
                        const href = URL.createObjectURL(res);
                        // create "a" HTML element with href to file & click
                        const link = document.createElement("a");
                        link.href = href;
                        link.setAttribute("download", `cards_${Date.now()}`); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        // clean up "a" element & remove ObjectURL
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                      },
                    }
                  );
                }}
                disabled={
                  isSubmitting
                    ? true
                    : !cards?.filter((x) => x.isPrinted === false)?.length > 0
                }
                primary
                label="Download Cards"
              />
            </li>
          </>
        }
        title="Card list"
        searchPlaceHolder="Search cards"
        searchWidth={20}
        isLoading={cards.length > 0 ? false : isLoading}
        newButtonText={currentRight.add && "Add Cards"}
        tableData={cards
          ?.filter(
            (x) =>
              x.status !== Constant.CardStatus.DELETED &&
              ((currentFilter.status !== status_all &&
                x.status === currentFilter.status) ||
                (currentFilter.status === status_all && true)) &&
              ((currentFilter.printed !== status_all &&
                (x.isPrinted ? 1 : 0) === currentFilter.printed) ||
                (currentFilter.printed === status_all && true))
          )
          .map((item, index) => {
            return {
              ...item,
              index,
            };
          })}
        defaultColumns={defaultColumns}
        tableConfig={Constant.Pagination.Card}
        searchFields={searchFields}
        handeNewClick={() => {
          setFormValues({
            cardDigits: "",
          });
          setIsNew(true);
        }}
        primaryKey="index"
        select={(currentRight.delete || currentRight.edit) && select}
        onSelect={(currentRight.delete || currentRight.edit) && setSelect}
      />

      {((select.length > 0 && currentRight.delete) ||
        (select.length > 0 && currentRight.edit)) && (
        <BottomBar>
          {select.length > 0 && currentRight.delete && (
            <Button
              primary
              label="Delete"
              bgColor="#ff4040"
              onClick={() => {
                isConfirmed("Are you sure to delete?", "Delete").then(
                  (confirm) => {
                    if (confirm) {
                      let values = select.reduce((prev, next) => {
                        prev = `${prev}cardIds=${cards[next]._id}&`;
                        return prev;
                      }, "");
                      values = values.slice(0, -1);
                      deleteCards(
                        {
                          path: `card?${values}`,
                          isApiToast:true
                        },
                        {
                          onSuccess: (res) => {
                            setSelect([]);
                            queryClient.invalidateQueries("getCards");
                          },
                        }
                      );
                    }
                  }
                );
              }}
            />
          )}
          {select.length > 0 && currentRight.edit && (
            <Button
              type="button"
              primary
              label="Print"
              onClick={onUpdate}
              style={{ position: "absolute", top: "0px", right: "0px" }}
            />
          )}
        </BottomBar>
      )}
    </>
  );
};

export default CardList;
