import { Text } from "grommet";
import { useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Button } from "../../components";
import ListView from "../../components/shared/listView";
import TableField from "../../components/shared/tableField";
import useAPI from "../../lib/api/useApi";
import { Constant, isMobile } from "../../lib/Constant";
import useConfirm from "../../lib/useConfirm";

const ColumnHeader = styled(Text)`
   {
    font-size: 14px;
    line-height: normal;
    color: #000000;
    font-weight: 600;
    text-align: start;
    min-width: 100px;

    @media only screen and (max-width: 767px) {
      margin-bottom: 4px;
    }
  }
`;

const ColumnTextField = styled(Text)`
  font-size: inherit !important;
  color: #000000;
  font-weight: 500 !important;
`;

const BottomBar = styled.div`
  display: flex;
  margin-top: 28px;
  position: relative;
`;

const UnitList = () => {
  const queryClient = useQueryClient();
  const { isConfirmed } = useConfirm();
  const [updateCount, setUpdateCount] = useState(0);
  const [formValues, setFormValues] = useState({});
  const { GET, POST, DELETE } = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const { mutate, isLoading: isSubmitting } = useMutation("saveUnits", POST);
  const selection = useSelector((state) => {
    return state.selection.value;
  });
  const currentUser = useSelector((state) => {
    return state.user.value;
  });
  const autoLoad = useRef(true);
  const currentRight = Constant.rights[currentUser.role].unit;
  const { mutate: deleteUnits } = useMutation("siteDelete", DELETE);
  const [fieldsValid, setFieldsValid] = useState({
    carpetArea: true,
    balcony: true,
    terrace: true,
    unitNo: true,
  });

  const changeIsLoading = (value) => {
    if (autoLoad.current) {
      setIsLoading(value);
    }
  };

  useQuery(
    ["getUnitsByDeveloperAndSite", selection.siteId],
    async () => {
      if (selection?.siteId) {
        changeIsLoading(true);
        return await GET({
          path: `unit?developerId=${selection.developerId}&siteId=${selection.siteId}`,
        });
      }
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        changeIsLoading(false);
        autoLoad.current = true;
        if (data?.is_success) {
          setSelect([]);
          const db = data?.data?.units?.map((item, index) => {
            let obj2 = {};
            if (!item?._id) {
              obj2 = {
                balcony: "",
                blockNumber: "",
                carpetArea: "",
                terraceArea: "",
                unitNumber: "",
              };
            }
            return {
              ...item,
              ...obj2,
              ...{
                developerId: selection.developerId,
                siteId: selection.siteId,
                touched:false
              },
              index,
            };
          });
          setFormValues({
            units: db || [],
          });
          setUpdateCount(updateCount + 1);
        } else {
          setFormValues({
            units: [],
          });
        }
      },
    }
  );

  const handleSubmit = (e) => {
    e.nativeEvent.preventDefault();
    if (!isSubmitting) {
      autoLoad.current = false;
      const isValid =
        formValues.units.filter((x) => x?.isValid === false).length === 0;
      if (
        isValid &&
        fieldsValid?.terrace &&
        fieldsValid?.balcony &&
        fieldsValid?.carpetArea &&
        fieldsValid?.unitNo
      ) {
        const result = formValues.units.filter((x) => {
          return checkValue.call(x) > 0 && x.touched;
        });
        if (result.length > 0) {
          mutate(
            {
              path: `unit`,
              message: Constant.ToasterMessages.Unit.save.success,
              isApiToast: true,
              body: { units: result },
            },
            {
              onSettled: (res) => {
                setSelect([]);
                if (res.data.is_success) {
                  queryClient.invalidateQueries("getUnitsByDeveloperAndSite");
                }
              },
            }
          );
        }
      }
    }
  };

  const handlCellChange = (e, index, property) => {
    setFormValues((prev) => {
      const frm = { ...prev };
      frm.units[index][property] = e.target.value;
      frm.units[index].touched = true;
      return frm;
    });

    fieldValueValidate(property, e.target.value);
  };

  const fieldValueValidate = (fieldName, value) => {
    switch (fieldName) {
      case "carpetArea":
        let carpetValid = value >= 1 && value <= 99999.99;
        setFieldsValid({ ...fieldsValid, carpetArea: carpetValid });
        break;
      case "balcony":
        let balconyValid = value >= 1 && value <= 99999.99;
        setFieldsValid({ ...fieldsValid, balcony: balconyValid });
        break;
      case "terrace":
        let terraceValid = value >= 1 && value <= 99999.99;
        setFieldsValid({ ...fieldsValid, terrace: terraceValid });
        break;
      case "unitNumber":
        let unitNoValid = value >= 1 && value <= 99999.99;
        setFieldsValid({ ...fieldsValid, unitNo: unitNoValid });
        break;
      default:
        break;
    }
  };

  const validateField = (obj, field) => {
    const res = checkValue.call(obj, field) !== 0 && !obj[field];
    obj.isValid = res ? false : true;
    return res ? "This field is required" : "";
  };

  function checkValue(field) {
    const obj2 = {
      blockNumber: this.blockNumber,
      carpetArea: this.carpetArea,
      unitNumber: this.unitNumber,
    };
    if (field) {
      delete obj2[field];
    }
    return Object.values(obj2).filter((x) => x !== "").length;
  }
  const [select, setSelect] = useState([]);

  const defaultColumns = [
    {
      property: "index",
      header: <ColumnHeader style={{ minWidth: "40px" }}> Sr No.</ColumnHeader>,
      align: "center",
      render: (data) => {
        return (
          <ColumnTextField>{`${isMobile ? "#" : ""}${
            data.index + 1
          }`}</ColumnTextField>
        );
      },
      fieldType: "header",
    },
    {
      property: "unitType",
      header: <ColumnHeader> Unit Type</ColumnHeader>,
      align: "center",
      render: (data) => <ColumnTextField>{data.unitType}</ColumnTextField>,
      fieldType: "field",
    },
    {
      property: "blockNo",
      header: <ColumnHeader>Block No.</ColumnHeader>,
      render: (data) => (
        <TableField
          key={`units[${data.index}].blockNumber`}
          data={data}
          length={4}
          property="blockNumber"
          reqMsg={validateField(data, "blockNumber")}
          handleChange={handlCellChange}
          updateCount={updateCount}
        />
      ),
      align: "center",
      fieldType: "field",
    },
    {
      property: "unitNo",
      header: <ColumnHeader> Unit No.</ColumnHeader>,
      render: (data) => (
        <TableField
          key={`units[${data.index}].unitNumber`}
          data={data}
          type="number"
          max={9999}
          min={1}
          reqMsg={validateField(data, "unitNumber")}
          property="unitNumber"
          onWheel={(e) => e.target.blur()}
          handleChange={handlCellChange}
          updateCount={updateCount}
        />
      ),
      align: "center",
      fieldType: "field",
    },
    {
      property: "carpetArea",
      header: (
        <ColumnHeader>
          {" "}
          Carpet Area <br /> <ColumnHeader>(Sq. Meter)</ColumnHeader>
        </ColumnHeader>
      ),
      render: (data) => (
        <TableField
          data={data}
          key={`units[${data.index}].carpetArea`}
          type="number"
          max={99999.99}
          min={1}
          property="carpetArea"
          onWheel={(e) => e.target.blur()}
          reqMsg={validateField(data, "carpetArea")}
          handleChange={handlCellChange}
          updateCount={updateCount}
        />
      ),
      align: "center",
      fieldType: "field",
    },
    {
      property: "balcony",
      header: (
        <ColumnHeader>
          {" "}
          Balcony & Wash Area <br /> <ColumnHeader>(Sq. Meter)</ColumnHeader>
        </ColumnHeader>
      ),
      render: (data) => (
        <TableField
          data={data}
          key={`units${data.index}balcony`}
          type="number"
          max={99999.99}
          min={1}
          onWheel={(e) => e.target.blur()}
          property="balcony"
          handleChange={handlCellChange}
          updateCount={updateCount}
        />
      ),
      align: "center",
      fieldType: "field",
    },
    {
      property: "Terrace",
      header: (
        <ColumnHeader>
          {" "}
          Terrace Area <br /> <ColumnHeader>(Sq. Meter)</ColumnHeader>
        </ColumnHeader>
      ),
      render: (data) => (
        <TableField
          data={data}
          key={`units${data.index}terraceArea`}
          type="number"
          max={99999.99}
          min={1}
          property="terraceArea"
          onWheel={(e) => e.target.blur()}
          handleChange={handlCellChange}
          updateCount={updateCount}
        />
      ),
      align: "center",
      fieldType: "field",
    },
  ];

  const fMsg = "Please select any developer and any site to see their units";
  const config = {
    ...Constant.Pagination.Unit,
    emptyTableMsg:
      !selection.developerId && !selection.siteId
        ? fMsg
        : selection.developerId && !selection.siteId
        ? "No site selected"
        : Constant.Pagination.Unit.emptyTableMsg,
  };

  return (
    <form onSubmit={handleSubmit}>
      <ListView
        isAutoMobile={true}
        isLoading={isLoading}
        title="Unit list"
        newButtonText={formValues.units?.length > 0 && !isLoading && "Save"}
        tableData={formValues?.units}
        defaultColumns={defaultColumns}
        tableConfig={config}
        newButtonDisabled={isSubmitting ? true : !selection.developerId}
        isSubmit={true}
        primaryKey="index"
        select={currentRight.delete && select}
        onSelect={currentRight.delete && setSelect}
        disabled={formValues?.units
          ?.filter((x) => !x.hasOwnProperty("_id"))
          ?.map((item) => {
            return item.index;
          })}
      />
      {((select.length > 0 && currentRight.delete) ||
        (formValues.units?.length > 0 && !isLoading)) && (
        <BottomBar
          style={{
            justifyContent:
              select.length > 0 && currentRight.delete
                ? "space-between"
                : "flex-end",
          }}
        >
          {select.length > 0 && currentRight.delete && (
            <Button
              primary
              label="Delete"
              bgColor="#ff4040"
              onClick={() => {
                isConfirmed("Are you sure to delete?", "Delete").then(
                  (confirm) => {
                    if (confirm) {
                      autoLoad.current = false;
                      let values = select.reduce((prev, next) => {
                        prev = `${prev}unitIds=${formValues.units[next]._id}&`;
                        return prev;
                      }, "");

                      values = values.slice(0, -1);
                      deleteUnits(
                        {
                          path: `unit?${values}`,
                          isApiToast: true,
                          message: Constant.ToasterMessages.Unit.delete.success,
                        },
                        {
                          onSuccess: (res) => {
                            setSelect([]);
                            queryClient.invalidateQueries(
                              "getUnitsByDeveloperAndSite"
                            );
                          },
                        }
                      );
                    }
                  }
                );
              }}
            />
          )}

          {formValues.units?.length > 0 && !isLoading && (
            <Button primary type="submit" label="Save" />
          )}
        </BottomBar>
      )}
    </form>
  );
};

export default UnitList;
