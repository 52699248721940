import { Box, Text } from "grommet";
import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FlexBar } from "../../components/shared/style";
import PropertyDocuments from "./propertyDocuments";

const Container = styled(FlexBar)`
   {
    align-items: flex-start;
    height: 100%;
    margin: 0px 0px 60px 0px;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
`;

const LeftSide = styled(Box)`
   {
    width: 50%;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
`;

const RightSide = styled(Box)`
   {
    width: 50%;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
`;
const Row = styled(FlexBar)`
  display: ${(props) => (props.keyValue ? "block" : "none")};
`;
const Label = styled(Text)`
  text-align: left;
  margin: 4px 0px;
  font-size: 16px;
`;
const Content = styled(Text)`
  text-align: left;
  margin: 4px 0px;
  margin-left: 8px;
  font-size: 16px;
`;

const HeaderContent = styled(Text)`
  font-size: 24px;
  font-weight: 600;

  text-align: center;
  @media only screen and (max-width: 426px) {
    line-height: 32px;
  }
`;

const BlackRow = styled(Box)`
  background-color: black;
  padding: 12px;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  @media only screen and (max-width: 376px) {
    padding: 4px;
  }
`;

const HeadingLabel = styled(Text)`
   {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
    @media only screen and (max-width: 767px) {
      margin-top: 40px;
    }
  }
`;

const PropertyInformation = () => {
  const selection = useSelector((state) => state.selection.value);
  const units = useSelector((state) => state.user.value.units);
  const currentUser = useMemo(() => {
    return units.find((x) => x.unitId === selection.unitId);
  }, [selection.unitId, units]);

  const ref = useRef();
  const site = currentUser?.site;
  const unit = currentUser?.unit;
  const isSmallScreen = window.innerWidth <= 426;

  const strs = useMemo(() => {
    const displayField = (...keys) => {
      const obj = site?.projectLegalAddress1;
      return keys.reduce((prev, next) => {
        if (obj[next]) {
          prev = prev + `${obj[next]}, `;
        }

        return prev;
      }, "");
    };

    let str1 = displayField(
      "finalPlotNumber",
      "surveyNumber",
      "TP_SchemeNumber",
      "village"
    );
    let str2 = displayField("taluka", "district", "subDistrict");
    let str3 = displayField("city", "state", "pinNumber");
    str1 = str2 || str3 ? str1 : str1.trim().slice(0, -1);
    str2 = str3 ? str2 : str2.trim().slice(0, -1);
    str3 = str3.trim().slice(0, -1);

    return `${str1} <br /> ${str2} <br /> ${str3}`;
  }, [site.projectLegalAddress1]);

  return (
    <>
      <BlackRow>
        <HeaderContent>
          {`Unit No. ${unit.blockNumber}-${unit.unitNumber}`}{" "}
        </HeaderContent>
      </BlackRow>

      <Container>
        <LeftSide>
          <Row keyValue="true">
            <HeadingLabel style={{ marginBottom: "0px" }}>Owner:</HeadingLabel>
            <Content>
              {currentUser?.memberName || currentUser.user.userName}
            </Content>
          </Row>
          <HeadingLabel style={{ marginTop: "24px" }}>
            Unit Information:
          </HeadingLabel>

          <Row keyValue={unit.carpetArea}>
            <Label style={{ fontWeight: "600" }}>Carpet Area:</Label>
            <Content>{`${unit.carpetArea} Sq. Meter`}</Content>
          </Row>
          <Row keyValue={unit.balcony}>
            <Label style={{ fontWeight: "600" }}>Balcony & Wash Area:</Label>
            <Content>{`${unit.balcony} Sq. Meter`}</Content>
          </Row>
          <Row keyValue={unit.terraceArea}>
            <Label>Terrace Area:</Label>
            <Content>{`${unit.terraceArea} Sq. Meter`}</Content>
          </Row>
          <HeadingLabel className="headingProjectInfo">
            Project Information:
          </HeadingLabel>
          <Row keyValue={site.RERA_RegistrationNumber}>
            <Label>RERA Registraction Number:</Label>
            <Content>{site?.RERA_RegistrationNumber}</Content>
          </Row>
          <Row keyValue="true">
            <Label>Total Number of Units:</Label>
            <Content>
              {parseInt(site?.projectTypeUnits?.[0] || 0) +
                parseInt(site?.projectTypeUnits?.[1] || 0)}
            </Content>
          </Row>
          {site?.projectTypeUnits?.length > 1 ? (
            <>
              <Row keyValue="true">
                <Label>Commercial Units:</Label>
                <Content>{site?.projectTypeUnits[0]}</Content>
              </Row>
              <Row keyValue="true">
                <Label>Residencial Units:</Label>
                <Content>{site?.projectTypeUnits[1]}</Content>
              </Row>
            </>
          ) : (
            <Row keyValue="true">
              <Label>{`${site?.projectType.split(" ")[0]} Units:`}</Label>
              <Content>{site?.projectTypeUnits[0]}</Content>
            </Row>
          )}
        </LeftSide>
        <RightSide>
          <HeadingLabel>Project Address:</HeadingLabel>
          {Object.values(site.projectLegalAddress1).filter((x) => x !== "")
            .length > 0 ? (
            <Content
              ref={ref}
              style={{ width: "50%", margin: "0px" }}
              dangerouslySetInnerHTML={{ __html: strs }}
            />
          ) : (
            <Content style={{ margin: "0px" }}>
              {site?.projectLegalAddress2}
            </Content>
          )}
        </RightSide>
      </Container>
      <BlackRow style={{ marginBottom: "48px" }}>
        <HeaderContent>
          {isSmallScreen ? (
            <>
              Documents for Unit <br /> {unit.blockNumber}-{unit.unitNumber}
            </>
          ) : (
            `Documents for Unit - ${unit.blockNumber}-${unit.unitNumber}`
          )}
        </HeaderContent>
      </BlackRow>
      <PropertyDocuments showTitle={false} />
    </>
  );
};

export default PropertyInformation;
