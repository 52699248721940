import { Box, Heading, Text } from "grommet";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BackButton } from "../../components";
import MemberForm from "../../components/forms/memberForm";
import useAPI from "../../lib/api/useApi";
import { Constant } from "../../lib/Constant";

const AddMember = () => {
  const selection = useSelector((state) => {
    return state.selection.value;
  });
  const [formValues, setFormValues] = useState({  userName: "",
  developerId: selection.developerId,
  siteId: selection.siteId,
  unitId: "",
  mobileNumber: "",});
  const navigate = useNavigate();
  const { POST } = useAPI();
  const queryClient = useQueryClient();
  const { mutate,isLoading: isSubmitting } = useMutation("memberAdd", POST);
  const onFormChange = (value) => {
    setFormValues(value);
  };

  const onSubmit = (data) => {
    mutate(
      {
        path: "member",
        body: data,
        message: Constant.ToasterMessages.Member.add.success,
      },
      {
        onSettled: (res) => {
          if (res.data.is_success) {
            queryClient.invalidateQueries("getMembersBySite");
            navigate("/members");
          }
        },
      }
    );
  };
  return (
    <>
      <Box direction="row" align="center">
        <Link style={{ display: "inline-flex" }} to="/members">
        <BackButton />
        </Link>
        <Text size="small">Back</Text>
      </Box>
      <Heading level="3" margin="none">
        Add new member
      </Heading>
      <MemberForm
        formValues={formValues}
        handleSubmit={onSubmit}
        onFormChange={onFormChange}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default AddMember;
