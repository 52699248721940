import { Box, FileInput, Form, FormField, Grommet, Text } from "grommet";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useTheme } from "styled-components";
import { Button, TextInput } from "../../components";
import useAPI from "../../lib/api/useApi";
import { scrollDown } from "../../lib/common";
import { Constant } from "../../lib/Constant";
import NormalDialog from "../dialogs/normalDialog";
import { Content } from "../shared/style";

const ScannerForm = ({
  formValues,
  handleSubmit,
  onFormChange,
  isLoading,
  id,
  isSubmitting,
}) => {
  const [form, setForm] = useState(formValues);
  const submit = useRef(false);
  const theme = { ...useTheme() };
  const { DELETE } = useAPI();
  theme.formField.border = {
    color: "brand",
    error: {
      color: "#FF4040",
    },
  };

  useEffect(() => {
    setForm(formValues);
  }, [formValues]);

  const { mutate: deleteImage } = useMutation("scannerImageDelete", DELETE);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Form
      onSubmit={(form) => {
        const formData = new FormData(form.target);
        handleSubmit(formData);
      }}
      value={form}
      onChange={(changeForm) => {
        setForm(changeForm);
        onFormChange(changeForm);
      }}
      method="post"
      validate="blur"
      onValidate={(event) => {
        if (submit.current && Object.entries(event.errors).length > 0) {
          scrollDown(Object.entries(event.errors)[0][0]);
        }
        submit.current = false;
      }}
    >
      <Content>
        <TextInput required id="userName" label="Scanner name *" length={50} />
        <TextInput
          regEx={{ pattern: "^[0-9]{10}$", message: "invalid" }}
          icon={<Text className="textIcon">+91</Text>}
          required
          id="mobileNumber"
          type="number"
          label="Mobile number *"
          onWheel={(e) => e.target.blur()}
        />

        <TextInput
          id="email"
          type="text"
          regEx={{
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: "is invalid",
          }}
          label="Email ID"
        />

        <Grommet theme={theme}>
          <FormField
            label="Profile picture"
            name="profilePic"
            htmlFor="profilePic"
            validate={(e) => {
              if (e?.length > 0) {
                var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                if (!allowedExtensions.exec(e[0].name)) {
                  return "Allow only 'JPG,JPEG,PNG'";
                }
              }
            }}
          >
            <FileInput
              id="profilePic"
              accept=".JPG,.JPEG,.PNG"
              name="profilePic"
              confirmRemove={({ onConfirm, onCancel }) => (
                <NormalDialog
                  proceed={() => {
                    if (form?.profilePictureId) {
                      deleteImage(
                        {
                          path: `scanner/file/delete?scannerId=${id}&fileId=${form?.profilePictureId}`,
                          message:
                            Constant.ToasterMessages.Scanner.removeImage
                              .success,
                        },
                        {
                          onSuccess: () => {
                            onFormChange({
                              ...form,
                              profilePictureId: "",
                              profilePic: "",
                            });
                          },
                        }
                      );
                    }
                    onConfirm();
                  }}
                  cancel={onCancel}
                  heading="Remove"
                  prompt="Are you sure to remove profile picture?"
                />
              )}
            />
          </FormField>
        </Grommet>
        <Box style={{ margin: "auto", paddingTop: "8px" }}>
          <Button
            onClick={() => {
              submit.current = true;
            }}
            disabled={isSubmitting}
            type="submit"
            primary
            label="Submit"
          />
        </Box>
      </Content>
    </Form>
  );
};

export default ScannerForm;
