import { Avatar, Box, Image, Layer, List, Text } from "grommet";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { setSelection } from "../../features/selectionSlice";
import { setUser } from "../../features/userSlice";
import useAPI from "../../lib/api/useApi";
import { getCommonFilePath } from "../../lib/common";
import { Constant, isMobile, Users } from "../../lib/Constant";
import Menu from "../menu";
import DropDown from "../shared/dropDown";
import { FlexBar } from "../shared/style";

const TopBar = styled(FlexBar)`
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  background-color: #000000;
  height: 32px;
  justify-content: space-between;
  padding-left: 108px;
  @media only screen and (max-width: 767px) {
    padding-left: 16px;
    height: 50px;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 9;
  }
`;

const AvtarGroup = styled(FlexBar)`
  position: absolute;
  right: 0px;
  color: white;
`;

const UserPic = styled(Avatar)`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  ${({ isDefault }) =>
    !isDefault &&
    `
  border: 1px solid #D3D3D3;
`}
`;

const PageHeading = styled(FlexBar)`
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: solid 0.5px #e9e9e9;
  height: 84px;
  padding: 0px 108px;
  @media only screen and (max-width: 430px) {
    padding-left: 0px;
  }
  @media only screen and (max-width: 767px) {
    margin-top: 52px;
  }
`;

const Title = styled(Box)`
  color: #000000;
  font-size: 40px;
  font-weight: 600;
  @media only screen and (max-width: 767px) {
    font-size: 24px !important;
    margin-bottom: 16px;
  }
  @media only screen and (min-width: 767px) and (max-width: 1024px) {
    font-size: 24px !important;
  }
`;

const TextField = styled(Text)`
  font-size: 20px;
  text-align: right;
  font-weight: 500;
  color: #000000;
  margin-right: 4px;
`;

const RightInputs = styled(FlexBar)`
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RowGroup = styled(FlexBar)`
  margin-left: 20px;
  @media only screen and (max-width: 767px) {
    margin-left: 0px;
    margin-bottom: 8px;
  }
`;

const Header = styled(Box)`
  position: fixed;
  top: 0;
  z-index: 4;
  width: 100%;
  @media only screen and (max-width: 767px) {
    position: inherit;
  }
`;
const RightList = styled(List)`
  top: 32px;
  position: absolute;
  color: black;
  background: white;
  right: 0px;
  width: 100%;
  min-width: 120px;
`;

const UserName = styled(FlexBar)`
  cursor: pointer;
  font-size: 16px;
  margin-right: 16px;
`;

const MemberSelection = styled(FlexBar)`
  margin-left: 20px;
  width: 400px;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    margin-bottom: 8px;
  }
`;

const HeaderBar = () => {
  const location = useLocation();
  const pathName = location.pathname?.toLocaleLowerCase();
  const dispatch = useDispatch();
  const selection = useSelector((state) => {
    return state.selection.value;
  });
  const [showMenu, setShowMenu] = useState(false);
  const currentMenu = useSelector((state) => {
    return state.menu.value;
  });

  const currentUser = useSelector((state) => {
    return state.user.value;
  });
  const headerRef = useRef();

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      if (headerRef.current) {
        if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
        ) {
          headerRef.current.classList.add("box-shadow");
        } else {
          headerRef.current.classList.remove("box-shadow");
        }
      }
    });
  }, []);

  return (
    <Header>
      <TopBar>
        {!isMobile && (
          <Image width="140px" src={Constant.Icons.myPropertyCardLogo} />
        )}
        {!isMobile && (
          <AvtarGroup style={{ pointerEvents: !showMenu }}>
            <UserName
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            >
              {currentUser.role === Users.Developer &&
              currentUser?.developerLogoId ? (
                <UserPic
                  isDefault={false}
                  src={getCommonFilePath(
                    currentUser?.developerLogoId,
                    currentUser.developerLogo,
                    `developer/file/thumbnails?developerId=${currentUser._id}&`
                  )}
                />
              ) : (
                <UserPic isDefault={true} src={Constant.Icons.defaultPic} />
              )}
              {currentUser?.userName ||
                currentUser?.userId?.userName ||
                currentUser?.user?.userName}
            </UserName>
            {showMenu && (
              <Layer
                animation={false}
                position="top-right"
                onClickOutside={() => {
                  setTimeout(() => {
                    setShowMenu(false);
                  }, 100);
                }}
                onEsc={() => {
                  setShowMenu(false);
                }}
                modal={false}
              >
                <RightList
                  primaryKey="name"
                  secondaryKey="percent"
                  onClickItem={(d) => {
                    localStorage.clear();
                    window.location = "/Login";
                  }}
                  data={[{ name: "Logout" }]}
                />
              </Layer>
            )}
          </AvtarGroup>
        )}
        {isMobile && <Menu className="menuBar" currentMenu={currentMenu} />}
      </TopBar>
      {!isMobile && <Menu className="menuBar" currentMenu={currentMenu} />}
      <PageHeading className="pageHeading" ref={headerRef}>
        {currentUser.role === Users.Member ? (
          <RightInputs
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <RowGroup style={{ marginLeft: "0px" }}>
              <Title>Welcome, {currentUser?.user?.userName}</Title>
            </RowGroup>
            <MemberSelection>
              <TextField>Units</TextField>
              <DropDown
                placeholder="Select unit"
                optionsData={currentUser.units.map((item) => {
                  return {
                    unitId: item.unitId,
                    name: `${item.developer.companyName} - ${item.site.projectName} - ${item.unit.blockNumber}-${item.unit.unitNumber}`,
                  };
                })}
                labelKeyData="name"
                valueKeyData={{ key: "unitId", reduce: true }}
                valuesData={selection.unitId}
                handleChange={({ value: nextValue }) => {
                  const objUser = currentUser.units.find(
                    (x) => x.unitId === nextValue
                  );
                  dispatch(
                    setUser({
                      ...currentUser,
                      user: objUser.user,
                      site: objUser?.site,
                    })
                  );
                  dispatch(setSelection({ unitId: nextValue }));
                }}
              />
            </MemberSelection>
          </RightInputs>
        ) : (
          <Title>{currentMenu?.name}</Title>
        )}

        {(currentUser?.role === Users.Developer ||
          currentUser?.role === Users.Admin) && (
          <WebHeader
            pathName={pathName}
            selection={selection}
            dispatch={dispatch}
            currentUser={currentUser}
          />
        )}
      </PageHeading>
    </Header>
  );
};

const WebHeader = ({ pathName, selection, dispatch, currentUser }) => {
  const { GET } = useAPI();

  const { data: developerData } = useQuery(
    ["developersHeader", pathName],
    async () => {
      if (
        Users.Admin === currentUser.role &&
        ["/sites", "/units", "/members"].includes(pathName)
      ) {
        return await GET({
          path: "developer/all",
        });
      }
    }
  );

  const { data: siteData } = useQuery(
    ["getDeveloperSites", selection.developerId, pathName],
    async () => {
      if (selection.developerId && ["/units", "/members"].includes(pathName)) {
        return await GET({
          path: `site/developer/${selection.developerId}`,
        });
      }
    }
  );

  return (
    <RightInputs>
      {["/sites", "/units", "/members"].includes(pathName) && (
        <RowGroup>
          {currentUser?.role === Users.Developer ? (
            <div>
              <TextField>Developer:</TextField>
              <TextField style={{ fontWeight: "400", width: "400px" }}>
                {currentUser.userId.userName}
              </TextField>
            </div>
          ) : (
            <>
              <TextField>Developer</TextField>
              <div className="projectSiteDropdown">
                <DropDown
                  placeholder="Select developer"
                  optionsData={
                    currentUser.role === Users.Admin
                      ? developerData?.data?.developers?.map((developer) => {
                          return {
                            developerId: developer._id,
                            developerName: developer.user.userName,
                          };
                        }) || []
                      : [
                          {
                            developerId: currentUser._id,
                            developerName: currentUser.userId.userName,
                          },
                        ]
                  }
                  labelKeyData="developerName"
                  valueKeyData={{ key: "developerId", reduce: true }}
                  valuesData={selection.developerId}
                  handleChange={({ value: nextValue }) => {
                    if (nextValue !== selection?.developerId) {
                      dispatch(
                        setSelection({ developerId: nextValue, siteId: "" })
                      );
                    }
                  }}
                />
              </div>
            </>
          )}
        </RowGroup>
      )}
      {["/units", "/members"].includes(pathName) && (
        <RowGroup>
          <TextField>Project site</TextField>
          <div className="projectSiteDropdown">
            <DropDown
              placeholder="Select site"
              optionsData={
                siteData?.data?.sites?.map((site) => {
                  return {
                    siteId: site._id,
                    projectName: site.projectName,
                  };
                }) || []
              }
              labelKeyData="projectName"
              valueKeyData={{ key: "siteId", reduce: true }}
              valuesData={selection.siteId}
              handleChange={({ value: nextValue }) => {
                dispatch(setSelection({ siteId: nextValue }));
              }}
            />
          </div>
        </RowGroup>
      )}
    </RightInputs>
  );
};

export default HeaderBar;
