import { Form, Text } from "grommet";
import { createRef, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import styled from "styled-components";
import { setUser } from "../features/userSlice";
import useAPI from "../lib/api/useApi";
import { Constant, isMobile, Users } from "../lib/Constant";
import { Button } from "./";
import { LogoImage } from "./auth";
import { FlexBar } from "./shared/style";

const Wrapper = styled(FlexBar)`
  justify-content: center;
  flex-direction: column;
  margin: auto;
`;

const FlexItem = styled(Text)`
  margin-bottom: 0.75em;
  font-size: 1em;
  font-weight: 400;

  ${({ type, hasError }) =>
    type === "resend" &&
    `
  cursor: pointer;
  text-decoration: underline;
  color:${hasError ? "red" : "#7D4CDB"} ;
`}
`;

const OTPLabel = styled(Text)`
  font-weight: 500;
  font-size: 1.25em;
  margin-bottom: 2.5em;
  text-align: center;
`;

const ErrorMsg = styled(Text)`
  color: red;
  font-size: 0.75em;
  margin-bottom: 1em;
`;

const OtpVerification = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [passCode, setPassCode] = useState("");
  const { POST } = useAPI();
  const dispatch = useDispatch();

  const mutation = useMutation("sendOtp", POST);
  const verify = useMutation("verify-otp", POST);

  function countDown() {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 180);
    restart(time);
  }

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: null,
    onExpire: () => console.warn("onExpire called"),
  });

  const refInput = createRef(OtpInput);

  useEffect(() => {
    if (!location?.state?.phoneNumber) {
      navigate("/login");
    }
    if (refInput.current) {
      refInput.current.handleOnPaste = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData("Text");
        if (new RegExp(/^[0-9]{6}$/).test(pastedData)) {
          setPassCode(pastedData);
        } else {
          setPassCode("");
        }
      };
    }
    countDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (passOtp) => {
    setPassCode(passOtp);
  };

  const generateOTP = async () => {
    if (!location?.state?.phoneNumber) {
      navigate("/login");
    }
    mutation.mutate(
      {
        path: "auth/send-otp",
        isApiToast: true,
        body: {
          mobileNumber: location.state.phoneNumber,
        },
      },
      {
        onSuccess: (res) => {
          if (res.data.is_success) {
            countDown();
          }
        },
      }
    );
  };

  const verifyOtp = () => {
    verify.mutate(
      {
        path: "auth/verify-otp",
        isApiToast: true,
        body: {
          mobileNumber: location.state.phoneNumber,
          otp: parseInt(passCode),
        },
      },
      {
        onError: () => {
          setHasError(true);
        },
        onSuccess: (data, error) => {
          if (data?.data?.is_success) {
            localStorage.setItem("access_token", data.data.data.access_token);
            localStorage.setItem("refresh_token", data.data.data.refreshToken);
            const role = data.data.data.role;
            const path =
              role === Users.Member
                ? "member/multiple-unit"
                : role === Users.Developer
                ? "developer"
                : "admin";

            dispatch(
              setUser({
                role: data.data.data.role,
                _id: data.data.data._id,
                userPath: `${path}/${data.data.data._id}`,
                isUser: false,
              })
            );
            navigate("/");
          }
        },
      }
    );
  };

  return (
    <Wrapper as={Form}>
      {isMobile && (
        <FlexBar style={{ marginBottom: 24 }}>
          <LogoImage src={Constant.Icons.mypropertyLogo} />
        </FlexBar>
      )}
      <h1>OTP Verification</h1>
      <OTPLabel>Enter the OTP sent to your phone number</OTPLabel>
      <OtpInput
        ref={refInput}
        value={passCode}
        onChange={handleChange}
        isInputNum
        className="otp"
        numInputs={6}
        hasErrored={hasError}
        separator={<span>&nbsp; &nbsp; &nbsp; </span>}
        errorStyle="error"
      />

      {hasError && <ErrorMsg> Invalid OTP entered</ErrorMsg>}
      <Button
        onClick={async () => {
          if (passCode > 5) {
            setHasError(false);
            verifyOtp();
          } else {
            setHasError(true);
          }
        }}
        disabled={
          verify.isLoading || mutation.isLoading
            ? true
            : passCode.length > 5
            ? false
            : true
        }
        primary
        type="submit"
        label="Submit"
      />

      {(minutes !== 0 || seconds !== 0) && (
        <FlexItem>
          OTP is valid for next{" "}
          <FlexItem color="#00C781">
            {`0${minutes}:${
              seconds.toString().length === 1 ? "0" : ""
            }${seconds} minutes`}
          </FlexItem>
        </FlexItem>
      )}

      {minutes * 60 + seconds < 150 && (
        <FlexItem>
          If you didn't receive a code{" "}
          <FlexItem
            type="resend"
            hasError={hasError}
            onClick={() => {
              setHasError(false);
              generateOTP();
              setPassCode("");
            }}
          >
            Resend
          </FlexItem>
        </FlexItem>
      )}
    </Wrapper>
  );
};

export default OtpVerification;
