import { Avatar, Box, Image, Layer, Text } from "grommet";
import { Close, Menu as Hamburger } from "grommet-icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { setMenu } from "../features/menuSlice";
import { getCommonFilePath } from "../lib/common";
import { Constant, isMobile, menuIcons, Users } from "../lib/Constant";

const HeaderMenu = styled.ul`
   {
    display: flex;
    box-sizing: border-box;
    background-color: ${(props) =>
      props.isMobile ? "transparent" : "rgb(255, 255, 255)"};
    color: rgb(68, 68, 68);
    height: ${(props) => (props.isMobile ? "62px" : "52px")};
    padding-left: 108px;
    border-bottom: ${(props) =>
      props.isMobile ? "0px" : "2px solid rgb(241, 241, 241)"};
    margin: auto;
    list-style-type: none;
    padding-bottom: 12px;
    width: 100%;
    @media only screen and (max-width: 1024px) {
      padding-left: 16px;
    }
    @media only screen and (max-width: 767px) {
      padding-left: 0px;
    }
  }
`;

const MenuItem = styled.li`{
  padding: 12px 24px 0px 0px;
  @media only screen and (max-width: 767px) {
   padding: 12px 0px 12px 0px;
    display: inline;
  }
}
}`;

const MenuText = styled(Text)`{
  padding-bottom: 12px;
  cursor: pointer;
 font-size:16px;
  ${({ isActive, theme }) =>
    isActive &&
    `
    border-bottom: 4px solid ${theme.global.colors.brand};
    font-weight: bold;
`}
}
@media only screen and (max-width: 767px) {
  border-bottom: 0px;
  padding-bottom: 0px;
}
}`;

const NavLink = styled(Link)`
  color: black;
  text-decoration: auto;
`;

const DrawerHeader = styled(Box)`
  height: ${window.innerHeight * 0.25}px;
  border: 1px solid transparent;
  margin-left: 16px;
  margin-right: 16px;
  justify-content: center;
  padding-top: 12px;
  border-bottom: 0.5px solid #ccc;
  padding-left: 12px;
`;
const DrawerBody = styled(Box)`
  height: ${window.innerHeight * 0.65}px;
  border: 1px solid transparent;
  padding: 14px 0px 12px 0px;
  margin-left: 16px;
  margin-right: 16px;
  border-bottom: 0.5px solid #ccc;
  padding-left: 12px;
`;
const DrawerFooter = styled(Box)`
  height: ${window.innerHeight * 0.1}px;
  border: 1px solid transparent;
  justify-content: center;
  margin-left: 16px;
  margin-right: 16px;
  padding-left: 12px;
`;

const UserDrawerPic = styled(Avatar)`
  width: 65px;
  height: 65px;

  ${({ isDefault }) =>
    isDefault &&
    `
background-color:black;
`}

  ${({ isDefault }) =>
    !isDefault &&
    `
    border: 1px solid #D3D3D3;
`}
`;

const DrawerMenuIcon = styled(Image)`
  width: 22px;
  height: 22px;
  margin-right: 16px;
`;

const UserName = styled(Text)`
  font-size: 20px;
  margin-top: 12px;
  font-weight: bold;
`;

const HeaderTitle = styled(Text)`
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
`;

const Menu = ({ currentMenu, handleClick }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = React.useState();
  const currentUser = useSelector((state) => {
    return state.user.value;
  });

  useEffect(() => {
    const menu = location.pathname.split("/")[1];
    const sel = Constant.Menu.find(
      (x) => x.link.toLocaleLowerCase() === menu.toLocaleLowerCase()
    );
    if (sel) {
      dispatch(setMenu(sel));
    } else {
      dispatch(setMenu(null));
    }
  }, [location, dispatch, currentUser.role]);

  return (
    <HeaderMenu isMobile={isMobile}>
      {isMobile ? (
        <Box
          onClick={() => setShow(true)}
          direction="row"
          alignSelf="center"
          justify="center"
          gap="medium"
        >
          <Hamburger color="#fff" />
          <HeaderTitle>My Property Card</HeaderTitle>
        </Box>
      ) : (
        Constant.Menu.filter(
          (x) => Constant.rights[currentUser.role]?.[x.property]?.view
        ).map((item, index) => {
          return (
            <MenuItem key={index}>
              <NavLink to={item.link}>
                <MenuText
                  isActive={
                    item.name.toLowerCase() === currentMenu?.name.toLowerCase()
                  }
                >
                  {item.name}
                </MenuText>
              </NavLink>
            </MenuItem>
          );
        })
      )}
      {isMobile && show && (
        <Layer
          position={"left"}
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
        >
          <DrawerHeader>
            <Box
              onClick={() => setShow(false)}
              direction="row"
              alignSelf="end"
              justify="end"
              gap="medium"
              pad={"medium"}
              style={{ position: "absolute", top: 0, right: 0, zIndex: 9999 }}
            >
              <Close size="medium" />
            </Box>
            {currentUser.role === Users.Developer &&
            currentUser?.developerLogoId ? (
              <UserDrawerPic
                isDefault={false}
                src={getCommonFilePath(
                  currentUser?.developerLogoId,
                  currentUser.developerLogo,
                  `developer/file/thumbnails?developerId=${currentUser._id}&`
                )}
              />
            ) : (
              <UserDrawerPic isDefault={true} src={Constant.Icons.defaultPic} />
            )}
            <UserName>
              {currentUser?.userName ||
                currentUser?.userId?.userName ||
                currentUser?.user?.userName}
            </UserName>
          </DrawerHeader>
          <DrawerBody>
            {Constant.Menu.filter(
              (x) => Constant.rights[currentUser.role]?.[x.property]?.view
            ).map((item, index) => {
              return (
                <MenuItem key={index}>
                  <Box
                    onClick={() => {
                      setShow(false);
                      navigate(`/${item.link}`);
                    }}
                    style={{ flexDirection: "row", borderWidth: 1 }}
                  >
                    <DrawerMenuIcon src={item.icon} />
                    <MenuText isActive={true}>{item.name}</MenuText>
                  </Box>
                </MenuItem>
              );
            })}
          </DrawerBody>
          <DrawerFooter>
            <MenuItem>
              <Box
                onClick={() => {
                  localStorage.clear();
                  window.location = "/Login";
                }}
                style={{ flexDirection: "row" }}
              >
                <DrawerMenuIcon src={menuIcons.logout} />
                <MenuText isActive={true}>Logout</MenuText>
              </Box>
            </MenuItem>
          </DrawerFooter>
        </Layer>
      )}
    </HeaderMenu>
  );
};

export default Menu;
