import { Box, Text } from "grommet";
import {
  AddCircle,
  Close,
  Edit,
  FormPreviousLink,
  Next,
  Previous,
  Trash,
} from "grommet-icons";
import styled from "styled-components";

export const CustomBox = styled(Box)`
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) =>
    !props.active
      ? props.theme.global.colors.hoverFg
      : props.theme.global.colors.brand};
  max-height: 80px;
  border-radius: 4px;

  ${({ active }) =>
    active &&
    `
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  
`}
`;

export const CollapsibleBox = styled(Box)`
  background: #f8f8f8;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const ActionHeader = styled(Text)`
  font-size: 14px;
  line-height: normal;
  color: #000000;
  font-weight: 600;
`;

const FieldHeader = styled(ActionHeader)`
  min-width: 100px;
`;

const BackButton = styled(FormPreviousLink)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  stroke: black;
`;

const ToolBar = styled.ul`
  display: flex;
  flex-direction: row;
  margin-top: 34px;
  margin-top: ${(props) => (props.isTop ? "34px" : "0px")};
  margin-bottom: 28px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px;
  li {
    list-style-type: none;
  }
`;

const NextPage = styled(Next)`
  stroke: black;
  &:hover {
    stroke: white;
    background: black;
  }
`;

const PrevPage = styled(Previous)`
  stroke: black;
  &:hover {
    stroke: white;
    background: black;
  }
`;

const EditIcon = styled(Edit)`
  stroke: black;
  height: 24px;
  cursor: pointer;
`;
const AddIcon = styled(AddCircle)`
  stroke: black;
  height: 24px;
  cursor: pointer;
`;
const DeleteIcon = styled(Trash)`
  stroke: black;
  height: 24px;
  cursor: pointer;
`;

const CloseIcon = styled(Close)`
  stroke: black;
  cursor: pointer;
`;
const Content = styled(Box)`
  margin: auto;
  margin-top: 40px;
  width: 40%;

  @media only screen and (max-width: 767px) {
    width: 80%;
  }
`;

const FlexBar = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RemoveTextIcon = styled(Text)`
  color: red;
  cursor: pointer;
  font-weight: 400;
  &:before {
    content: "remove";
  }
`;

export {
  ActionHeader,
  FieldHeader,
  BackButton,
  ToolBar,
  NextPage,
  PrevPage,
  EditIcon,
  DeleteIcon,
  AddIcon,
  CloseIcon,
  Content,
  FlexBar,
  RemoveTextIcon,
};
