import {
  Box,
  CheckBox,
  Form,
  Heading,
  NameValueList,
  NameValuePair,
  Text
} from "grommet";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button, DropDown, TextInput } from "../../components";
import { setSelection } from "../../features/selectionSlice";
import useAPI from "../../lib/api/useApi";
import { scrollDown } from "../../lib/common";
import { Constant, Users } from "../../lib/Constant";
import { Content } from "../shared/style";

const TextField = styled(Text)`
  font-size: 14px !important;
  text-align: right;
  margin-right: 8px;
  font-weight: 500;
`;

const DetailHeader = styled(Text)`
  box-shadow: 0 4px 4px -4px #d9cdcd;
  padding: 8px 12px 16px;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 8px;
  margin-bottom: 24px;
  height: 100%;
  align-items: center;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
`;

const DetailGroup = styled(Box)`
  width: 100%;
  box-shadow: 0 0 4px #e0d8d8;
  margin-top: 4px;
  padding: 8px;
`;

const SiteForm = ({
  id,
  formValues,
  handleSubmit,
  onFormChange,
  isLoading,
  filterFiles,
  isSubmitting,
  isAddSite = false,
}) => {
  const [form, setForm] = useState(formValues);
  const submit = useRef(false);
  const currentUser = useSelector((state) => {
    return state.user.value;
  });

  const selection = useSelector((state) => {
    return state.selection.value;
  });
  const dispatch = useDispatch();
  const { GET } = useAPI();
  const { data: developerData, isLoading: isSelectLoading } = useQuery(
    "developers",
    async () => {
      if (Users.Admin === currentUser.role) {
        return await GET({
          path: "developer/all",
        });
      }
    }
  );

  useEffect(() => {
    setForm(formValues);
  }, [formValues]);

  if (isLoading || isSelectLoading) {
    return <div>Loading...</div>;
  }

  const checkOtherFieldsValue = (data, field) => {
    let fields = [
      "finalPlotNumber",
      "surveyNumber",
      "TP_SchemeNumber",
      "village",
      "taluka",
      "district",
      "subDistrict",
      "city",
      "state",
      "pinNumber",
    ];
    let isValue = false;
    fields.forEach((element) => {
      if (field !== element && data[element]) {
        if (data[element] !== "") {
          isValue = true;
        }
      }
    });
    return isValue;
  };
  const getRequired = (data, field) => {
    if (data["projectLegalAddress2"] === "") {
      return true;
    } else {
      let isOtherAddress = checkOtherFieldsValue(data, field);
      if (isOtherAddress === true) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <Form
      onSubmit={() => {
        if (selection.developerId !== form.developerId) {
          dispatch(setSelection({ developerId: form.developerId }));
        }
        const data = {
          ...form,
          ...{
            isOtherProjectType:
              form.projectTypeSelect === Constant.ProjectTypes.Other,
            projectType:
              form.projectTypeSelect === Constant.Constitution.Other
                ? form.projectType
                : form.projectTypeSelect,
          },
        };
        if (
          data?.finalPlotNumber !== "" &&
          data?.surveyNumber !== "" &&
          data?.TP_SchemeNumber !== "" &&
          data?.village !== "" &&
          data?.taluka !== "" &&
          data?.district !== "" &&
          data?.subDistrict !== "" &&
          data?.city !== "" &&
          data?.state !== "" &&
          data?.pinNumber !== "" &&
          data?.projectLegalAddress2 !== ""
        ) {
          delete data?.projectLegalAddress2;
        }
        handleSubmit(data);
      }}
      value={form}
      onChange={(changeForm) => {
        setForm(changeForm);
        onFormChange(changeForm);
      }}
      onValidate={(event) => {
        if (submit.current && Object.entries(event.errors).length > 0) {
          scrollDown(Object.entries(event.errors)[0][0]);
        }
        submit.current = false;
      }}
      method="post"
      validate="blur"
    >
      <Content>
        <TextInput
          required
          id="projectName"
          type="text"
          label="Project name *"
          length={200}
        />

        {developerData && (
          <DropDown
            disabled={isAddSite}
            id="developerId"
            labelsData="Developer *"
            optionsData={developerData?.data?.developers?.map((developer) => {
              return {
                developerId: developer._id,
                developerName: developer.user.userName,
              };
            })}
            valuesData={form.developerId}
            labelKeyData="developerName"
            valueKeyData={{ key: "developerId", reduce: true }}
            required
          />
        )}

        <DetailGroup width="medium">
          <DetailHeader>Project legal address</DetailHeader>
          <NameValueList
            className="project-legal"
            gap="xxsmall"
            valueProps={{ width: "50%" }}
            nameProps={{ width: "40%" }}
            style={{ alignItems: "baseline" }}
          >
            <NameValuePair name={<TextField>Final plot number</TextField>}>
              <TextInput
                id="finalPlotNumber"
                placeholder="Final plot number"
                required={getRequired(form, "finalPlotNumber")}
                length={200}
              />
            </NameValuePair>
            <NameValuePair name={<TextField>Survey number</TextField>}>
              <TextInput
                id="surveyNumber"
                placeholder="Survey number"
                required={getRequired(form, "surveyNumber")}
                length={200}
              />
            </NameValuePair>
            <NameValuePair name={<TextField>TP scheme number</TextField>}>
              <TextInput
                id="TP_SchemeNumber"
                placeholder="TP scheme number"
                required={getRequired(form, "TP_SchemeNumber")}
                length={200}
              />
            </NameValuePair>
            <NameValuePair name={<TextField>Mouje / Village</TextField>}>
              <TextInput
                id="village"
                placeholder="Mouje / Village"
                required={getRequired(form, "village")}
                length={200}
              />
            </NameValuePair>
            <NameValuePair name={<TextField>Taluka</TextField>}>
              <TextInput
                id="taluka"
                placeholder="Taluka"
                required={getRequired(form, "taluka")}
                length={200}
              />
            </NameValuePair>
            <NameValuePair name={<TextField>District</TextField>}>
              <TextInput
                id="district"
                placeholder="District"
                required={getRequired(form, "district")}
                length={200}
              />
            </NameValuePair>
            <NameValuePair name={<TextField>Sub-District</TextField>}>
              <TextInput
                id="subDistrict"
                placeholder="Sub-District"
                required={getRequired(form, "subDistrict")}
                length={200}
              />
            </NameValuePair>
            <NameValuePair name={<TextField>City</TextField>}>
              <TextInput
                id="city"
                placeholder="City"
                required={getRequired(form, "city")}
                length={200}
              />
            </NameValuePair>
            <NameValuePair name={<TextField>State</TextField>}>
              <TextInput
                id="state"
                placeholder="State"
                required={getRequired(form, "state")}
                length={200}
              />
            </NameValuePair>
            <NameValuePair name={<TextField>Pin number</TextField>}>
              <TextInput
                id="pinNumber"
                placeholder="Pin number"
                type="number"
                onWheel={(e) => e.target.blur()}
                regEx={{
                  pattern: "^[0-9]{0,20}$",
                  message: "invalid",
                }}
                required={getRequired(form, "pinNumber")}
              />
            </NameValuePair>
            <NameValuePair>
              <Heading level={4} style={{ textAlign: "center" }}>
                Or
              </Heading>
            </NameValuePair>

            <NameValuePair name={<TextField>Project legal address</TextField>}>
              <TextInput
                id="projectLegalAddress2"
                placeholder="Project legal address"
                type="textarea"
                length={1000}
              />
            </NameValuePair>
          </NameValueList>
        </DetailGroup>

        <TextInput
          id="projectPostalAddress"
          type="textarea"
          label="Project postal address"
          length={1000}
        />

        <DropDown
          id="projectTypeSelect"
          optionsData={Object.values(Constant.ProjectTypes).map((item) => item)}
          required
          labelsData="Project type *"
          handleChange={({ value: nextValue }) => {
            onFormChange({
              ...form,
              projectType: "",
              projectTypeSelect: nextValue,
              projectTypeUnits:
                Constant.ProjectTypes.Mix_Development === form.projectTypeSelect
                  ? ["", ""]
                  : [""],
            });
          }}
        />
        <TextInput
          display={Constant.ProjectTypes.Other === form.projectTypeSelect}
          required
          length={200}
          id="projectType"
          placeholder="Enter Other Project type"
        />

        <TextInput
          id="numberOfTowers"
          label="Nos of Towers / Buildings"
          type="number"
          max={20}
          min={1}
          onWheel={(e) => e.target.blur()}
        />

        <DetailGroup width="medium">
          <DetailHeader>Nos. of units</DetailHeader>
          <NameValueList
            className="project-legal"
            gap="xxsmall"
            valueProps={{ width: "50%" }}
            nameProps={{ width: "40%" }}
            style={{ alignItems: "baseline" }}
          >
            {form.projectTypeSelect ===
            Constant.ProjectTypes.Mix_Development ? (
              <>
                <NameValuePair
                  name={<TextField> Residential units *</TextField>}
                >
                  <TextInput
                    type="number"
                    regEx={{
                      pattern: /^[0-9\b]+$/,
                      message: "invalid (decimal not allowed)",
                    }}
                    max={9999}
                    min={1}
                    onWheel={(e) => e.target.blur()}
                    required
                    id="projectTypeUnits[0]."
                  />
                </NameValuePair>
                <NameValuePair
                  name={<TextField> Commercial units *</TextField>}
                >
                  <TextInput
                    regEx={{
                      pattern: /^[0-9\b]+$/,
                      message: "invalid (decimal not allowed)",
                    }}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    required
                    max={9999}
                    min={1}
                    id="projectTypeUnits[1]."
                  />
                </NameValuePair>
              </>
            ) : (
              <NameValuePair
                name={
                  <TextField>
                    {`${form.projectType || form.projectTypeSelect} units *`}
                  </TextField>
                }
              >
                <TextInput
                  regEx={{
                    pattern: /^[0-9\b]+$/,
                    message: "invalid (decimal not allowed)",
                  }}
                  max={9999}
                  min={1}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  required
                  id="projectTypeUnits[0]."
                />
              </NameValuePair>
            )}
          </NameValueList>
        </DetailGroup>

        <DropDown
          id="projectStatus"
          optionsData={Object.values(Constant.ProjectStatus).map(
            (item) => item
          )}
          required
          labelsData="Project status *"
          handleChange={({ value: nextValue }) => {
            onFormChange({
              ...form,
              projectStatus: nextValue,
            });
            filterFiles(nextValue);
          }}
        />

        <TextInput
          required
          id="RERA_RegistrationNumber"
          onkeypress="return event.charCode >= 48"
          label="RERA registration number *"
          length={100}
        />

        <TextInput
          required
          id="RERA_RegistrationDate"
          label="RERA registration date *"
          type="date"
          formValue={form.RERA_RegistrationDate}
          handleChange={(e) => {
            onFormChange({
              ...form,
              RERA_RegistrationDate: e,
            });
            if (form.projectEndDate) {
              let diff = moment(e).diff(form.projectEndDate);
              if (diff > 0) {
                onFormChange({
                  ...form,
                  projectEndDate: null,
                });
              }
            }
          }}
        />

        <TextInput
          id="rajachitthi"
          label="Rajachitthi (Commencement certificate date)"
          type="date"
          formValue={form.rajachitthi}
          handleChange={(e) => {
            onFormChange({
              ...form,
              rajachitthi: e,
            });
          }}
        />

        <TextInput
          id="projectEndDate"
          label="Project end date (As per RERA)"
          type="date"
          formValue={form.projectEndDate}
          handleChange={(e) => {
            onFormChange({
              ...form,
              projectEndDate: e,
            });
          }}
          minDate={new Date(form?.RERA_RegistrationDate)}
        />
        {id && (
          <CheckBox
            checked={form.allowMemberToDownload}
            label="Allow members to download files?"
            onChange={(event) => {
              onFormChange({
                ...form,
                allowMemberToDownload: event.target.checked,
              });
            }}
          />
        )}

        <Box style={{ margin: "auto", paddingTop: "8px" }}>
          <Button
            onClick={() => {
              submit.current = true;
            }}
            disabled={isSubmitting}
            type="submit"
            primary
            label="Submit"
          />
        </Box>
      </Content>
    </Form>
  );
};

export default SiteForm;
