import { Heading } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { setMenu } from "../features/menuSlice";
import { Constant } from "../lib/Constant";
import Button from "./shared/button";
import { FlexBar } from "./shared/style";

const ContentWrapper = styled(FlexBar)`
  height: 100%;
  background-color: #ffffff;
  flex-direction: column;
  justify-content: center;
`;

const DefaultPage = (props) => {
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => {
    return state.user.value;
  });

  const onClick = () => {
    if (token) {
      const sel = Constant.Menu.find(
        (x) =>
          x.default && Constant.rights[currentUser.role]?.[x.property]?.view
      );
      dispatch(setMenu(sel));
      navigate(`/${sel.link}`);
    } else {
      navigate(`/login`);
    }
  };

  return (
    <ContentWrapper>
      <Heading level={3}>
        {token ? "You are already logged in" : "You are not logged in"}
      </Heading>
      <Button
        handleClick={onClick}
        primary
        label={token ? "Go to dashboard" : "Go to log in"}
      />
    </ContentWrapper>
  );
};

export default DefaultPage;
