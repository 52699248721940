import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { FieldHeader } from "../../components";
import ListView from "../../components/shared/listView";
import useAPI from "../../lib/api/useApi";
import { DeleteColumn, editList } from "../../lib/common";
import { Constant } from "../../lib/Constant";

const ScannerList = () => {
  const queryClient = useQueryClient();
  const { GET, DELETE } = useAPI();

  //delete Logic
  const { mutate: deletePost } = useMutation("scannerDelete", DELETE);
  const navigate = useNavigate();
  const { data: builderData, isLoading } = useQuery("scanners", async () => {
    return await GET({
      path: "scanner/all",
    });
  });

  const defaultColumns = [
    {
      property: "userName",
      header: <FieldHeader> Name</FieldHeader>,
      fieldType: "header",
    },

    {
      property: "email",
      header: <FieldHeader> Email</FieldHeader>,
      fieldType: "field",
    },
    {
      property: "mobileNumber",
      header: <FieldHeader> Phone</FieldHeader>,
      fieldType: "field",
    },
    editList({
      onClick: (data) => {
        navigate(data._id);
      },
    }),
  ];
  defaultColumns.push(
    DeleteColumn({
      onDelete: (data) => {
        deletePost(
          {
            path: `scanner/${data._id}`,
            message: Constant.ToasterMessages.Scanner.delete.success,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(["scanners"]);
            },
          }
        );
      },
    })
  );
  const searchFields = ["mobileNumber", "email", "userName"];
  return (
    <ListView
      isAutoMobile={true}
      isLoading={isLoading}
      title="Scanner list"
      searchPlaceHolder="Search scanner name"
      newButtonText="Add New Scanner"
      searchFields={searchFields}
      tableData={builderData?.data?.scanners}
      defaultColumns={defaultColumns}
      tableConfig={Constant.Pagination.Scanner}
      handeNewClick={() => {
        navigate("/scanners/add");
      }}
    />
  );
};

export default ScannerList;
