import { Form, Grommet, Image, Text } from "grommet";
import { useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  ActionHeader,
  Button,
  DropDown,
  FieldHeader,
  TextInput,
} from "../../components";
import ListView from "../../components/shared/listView";
import { CloseIcon, EditIcon, FlexBar } from "../../components/shared/style";
import useAPI from "../../lib/api/useApi";
import { DeleteColumn } from "../../lib/common";
import { Constant } from "../../lib/Constant";

const MemberList = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { GET, DELETE, POST } = useAPI();
  const selection = useSelector((state) => {
    return state.selection.value;
  });

  const currentUser = useSelector((state) => {
    return state.user.value;
  });
  const currentRight = Constant.rights[currentUser.role].member;

  const theme = {
    ...useTheme(),
    formField: {
      error: {
        style: {
          display: "none",
        },
      },
      border: {
        color: "none",
        error: {
          color: "none",
        },
      },
    },
  };

  const formRef = useRef();
  const [formValues, setFormValues] = useState({
    memberName: "",
    unitId: "",
    mobileNumber: "",
  });

  const { mutate: deletePost } = useMutation("memberDelete", DELETE);
  const { mutate: addMemberMutate, isLoading: isSubmitting } = useMutation(
    "memberAdd",
    POST
  );

  const { data, isLoading } = useQuery(
    ["getMembersBySite", selection.siteId],
    async () => {
      if (selection?.siteId) {
        let newMembers = [];
        let length = 0;
        const getPromise = new Promise((resolve) => {
          GET({
            path: `member/site/${selection.siteId}`,
          })
            .then((data) => {
              newMembers =
                data?.data?.members.map((item, index) => {
                  return {
                    ...item,
                    unit:
                      item?.unit?.blockNumber + "-" + item?.unit?.unitNumber,
                    index: index + 1,
                    ...{
                      memberName: item?.memberName || item?.user?.userName,
                    },
                  };
                }) || [];
              length = newMembers.length;
            })
            .finally(() => {
              if (selection?.siteId && currentRight.add) {
                newMembers?.unshift({
                  isAdd: true,
                  index: 0,
                  userId: {
                    mobileNumber: "",
                    memberName: "",
                    blockNumber: "",
                    unitNumber: "",
                  },
                });
                if (formRef) {
                  formRef.current.dispatchEvent(
                    new Event("reset", { bubbles: true, cancelable: true })
                  );
                }
              }
              resolve({ members: newMembers, memberLength: length });
            });
        });

        return await getPromise;
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: unitData } = useQuery(
    ["getUnitsByDeveloperAndSite", selection.siteId],
    async () =>
      await GET({
        path: `unit?developerId=${selection.developerId}&siteId=${selection.siteId}`,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const defaultColumns = [
    {
      property: "unit",
      header: (
        <FieldHeader>
          {" "}
          Unit <span>*</span>
        </FieldHeader>
      ),
      align: "center",
      render: (colData) =>
        colData.index === 0 ? (
          <DropDown
            parentForm={formRef}
            id="unitId"
            optionsData={
              unitData?.data?.units
                ?.filter((x) => {
                  let unitAssigned = data?.members?.find(
                    (d) => d?.unit?._id === x._id
                  );
                  if (!unitAssigned && x.blockNumber) {
                    return true;
                  }
                  return false;
                })
                .map((unit) => {
                  return {
                    unitId: unit._id,
                    unitName: unit.blockNumber + "-" + unit.unitNumber,
                  };
                }) || []
            }
            valuesData={formValues.unitId}
            labelKeyData="unitName"
            valueKeyData={{ key: "unitId", reduce: true }}
            required
            placeholder="Select Unit"
          />
        ) : (
          <Text>{colData?.unit}</Text>
        ),
      fieldType: "field",
    },
    {
      property: "memberName",
      header: (
        <FieldHeader>
          {" "}
          Member Name <span>*</span>
        </FieldHeader>
      ),
      align: "center",
      render: (data) => (
        <>
          {data?.index === 0 ? (
            <TextInput
              placeholder="Enter Member Name"
              isFreeField={true}
              required
              id="memberName"
              length={150}
              parentForm={formRef}
            />
          ) : (
            <Text>
              {data?.memberName || data?.user?.userName}
              {data?.card?.cardNumber && (
                <>
                  <br />
                  <Text
                    style={{ fontSize: "10px", color: "grey" }}
                  >{`(${data?.card?.cardNumber})`}</Text>
                </>
              )}
            </Text>
          )}
        </>
      ),
      fieldType: "field",
    },
    {
      property: "mobileNumber",
      header: (
        <FieldHeader>
          {" "}
          Member Phone <span>*</span>
        </FieldHeader>
      ),
      align: "center",
      render: (data) => (
        <>
          {data?.index === 0 ? (
            <TextInput
              isFreeField={true}
              regEx={{ pattern: "^[0-9]{10}$", message: "is invalid" }}
              icon={<Text className="textIcon">+91</Text>}
              required
              id="mobileNumber"
              type="number"
              placeholder="Enter Member Phone"
              onWheel={(e) => e.target.blur()}
              parentForm={formRef}
            />
          ) : (
            <Text>{data?.user?.mobileNumber}</Text>
          )}
        </>
      ),
      fieldType: "field",
    },
    {
      property: "edit",
      header: (
        <ActionHeader>
          {currentRight.add && " Add / "}
          Edit
        </ActionHeader>
      ),
      render: (data) =>
        data?.index !== 0 ? (
          <EditIcon
            onClick={() => {
              navigate(data._id);
            }}
          />
        ) : (
          <FlexBar>
            <Button
              style={{ padding: "0px", margin: "4px" }}
              type="submit"
              primary
              bgColor="inherit"
              disabled={isSubmitting}
              icon={
                <Image style={{ width: "24px" }} src={Constant.Icons.save} />
              }
            />
            <Button
              icon={<CloseIcon />}
              style={{ padding: "0px", margin: "4px" }}
              type="reset"
              primary
              disabled={isSubmitting}
              bgColor="inherit"
            />
          </FlexBar>
        ),
      align: "center",
      fieldType: "button",
    },
  ];

  if (currentRight.delete) {
    defaultColumns.push(
      DeleteColumn({
        show: false,
        onDelete: (data) => {
          deletePost(
            {
              path: `member/${data._id}`,
              isApiToast: true,
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(["getMembersBySite"]);
              },
            }
          );
        },
      })
    );
  }

  const searchFields = [
    "memberName",
    "user.mobileNumber",
    "unit.blockNumber",
    "unit.unitNumber",
    "unit",
    "card.cardNumber",
  ];

  const onFormChange = (value) => {
    setFormValues(value);
  };

  const onSubmit = () => {
    const data = {
      siteId: selection.siteId,
      ...formValues,
    };
    addMemberMutate(
      {
        path: "member",
        body: data,
        isApiToast: true,
      },
      {
        onSettled: (res) => {
          if (res?.data.is_success) {
            queryClient.invalidateQueries(["getMembersBySite"]);
            formRef.current.dispatchEvent(
              new Event("reset", { bubbles: true, cancelable: true })
            );
          }
        },
      }
    );
  };

  const selectDeveloperSiteMsg =
    "Please select any developer and any site to see their units";
  const config = {
    ...Constant.Pagination.Member,
    emptyTableMsg:
      !selection.developerId && !selection.siteId
        ? selectDeveloperSiteMsg
        : selection.developerId && !selection.siteId
        ? "No site selected"
        : Constant.Pagination.Member.emptyTableMsg,
  };

  return (
    <Grommet theme={theme}>
      <Form
        ref={formRef}
        onReset={(e) => {
          setFormValues({
            memberName: "",
            siteId: selection.siteId,
            unitId: "",
            mobileNumber: "",
          });
        }}
        value={formValues}
        onChange={onFormChange}
        onSubmit={onSubmit}
        method="post"
        validate="blur"
      >
        <ListView
          isAutoMobile={true}
          isLoading={isLoading}
          title="Member list"
          searchPlaceHolder="Search member name"
          searchFields={searchFields}
          tableData={data?.members}
          defaultColumns={defaultColumns}
          tableConfig={config}
          newButtonDisabled={!selection.developerId}
          handeNewClick={() => {
            navigate("/members/add");
          }}
          length={data?.memberLength}
          component={"member"}
        />
      </Form>
    </Grommet>
  );
};

export default MemberList;
