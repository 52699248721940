import { Box, FormField, MaskedInput as GMaskedInput } from 'grommet';

import { useState } from 'react';
import styled from 'styled-components';

const MaskedInputWrapper = styled(GMaskedInput)`
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
`;

const GroupBox = styled(Box)`
  border-radius: 4px;
  border: 2px solid black;

  ${({ isValid }) =>
    !isValid &&
    `
      border: 2px solid red;
      outline: none;
  `}
`;

const MaskedTextInput = ({
  id,
  label,
  type,
  onChange,
  required,
  min,
  max,
  regEx,
  compare,
  length,
  mask,
}) => {
  const [isValid, setIsValid] = useState(true);

  const onValidate = (val) => {
    const getMessage = (msg) => {
      setIsValid(false);
      return `${label || 'This field'} ${msg}`;
    };

    if (!val && required) {
      return getMessage(`is required`);
    } else if (
      val &&
      (parseInt(val) < (min || 0) || parseInt(val) > (max || Number.MAX_VALUE))
    ) {
      return getMessage(`must be between ${min || 0} to ${max || 'any'}`);
    } else if (regEx && !val.match(regEx.pattern)) {
      return getMessage(`${regEx?.message}`);
    } else if (compare && compare.value !== val) {
      return getMessage(` is not matched with ${compare.field}`);
    } else if (val && val.length > length) {
      return getMessage(`can not be greater than ${length}`);
    } else {
      setIsValid(true);
    }
  };
  return (
    <>
      <FormField htmlFor={id} name={id} label={label} validate={onValidate}>
        <GroupBox direction='row' isValid={isValid}>
          <MaskedInputWrapper
            isValid={isValid}
            type={type}
            id={id}
            name={id}
            placeholder={`Enter ${label || 'value'}`}
            mask={mask}
          />
        </GroupBox>
      </FormField>
    </>
  );
};

export default MaskedTextInput;
