import { Box } from "grommet";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BackButton, FlexBar } from "./style";

const Title = styled.h3`
  font-size: 28px;
  margin: 0px;
`;

const BackBox = ({ title, link }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box style={{ marginBottom: "8px" }} direction="row" align="center">
        <FlexBar
          className="cursor_pointer"
          onClick={() => {
            navigate(link);
          }}
          size="small"
          color="black"
        >
          <BackButton />
          Back
        </FlexBar>
      </Box>
      {title && <Title>{title}</Title>}
    </>
  );
};

export default BackBox;
