import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

import dialougeSlice from "./features/dialougeSlice";
import menuSlice from "./features/menuSlice";
import spinnerSlice from "./features/spinnerSlice";
import selectionSlice from "./features/selectionSlice";
import userSlice from "./features/userSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  confirm: dialougeSlice,
  menu: menuSlice,
  spinner: spinnerSlice,
  selection:selectionSlice,
  user:userSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
