import { Box, Layer } from "grommet";
import { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { setSelection } from "../../features/selectionSlice";
import { setUser } from "../../features/userSlice";
import useAPI from "../../lib/api/useApi";
import { Users } from "../../lib/Constant";
import HeaderBar from "../layout/headerBar";

const ContentWrapper = styled(Box)`
  position: relative;
  margin: 200px 98px 32px;
  padding: 48px 98px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  @media only screen and (max-width: 1024px) {
    margin-left: 16px;
    margin-right: 16px;
    padding: 20px 20px;
  }
  @media only screen and (max-width: 767px) {
    margin: 32px 16px;
    padding: 20px 20px;
  }
`;

const Layout = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => {
    return state.user.value;
  });
  const { GET } = useAPI();
  const headerRef = useRef();
  const navigate = useNavigate();
  let location = useLocation();
  useQuery(
    "userProfile",
    async () => {
      if (currentUser?.userPath) {
        return await GET({
          path: currentUser.userPath,
        });
      }
    },
    {
      onSettled: (res) => {
        if (res?.is_success) {
          const userInfo = Object.values(res.data)[0];
          if (currentUser.role === Users.Member) {
            let userState = {
              units: userInfo,
            };
            if (!currentUser.isUser) {
              dispatch(
                setSelection({
                  unitId: userInfo[0].unitId,
                })
              );
              userState = {
                ...userState,
                user: userInfo[0].user,
                site: userInfo[0]?.site,
              };
            }
            redirectTo("/MyPropertyInformation", {
              ...userState,
            });
          } else if (currentUser.role === Users.Developer) {
            dispatch(
              setSelection({
                developerId: currentUser._id,
              })
            );
            redirectTo("/Profile", userInfo);
          } else {
            redirectTo("/Developers", userInfo);
          }
        }
      },
    }
  );

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      if (headerRef.current) {
        if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
        ) {
          headerRef.current.classList.add("box-shadow");
        } else {
          headerRef.current.classList.remove("box-shadow");
        }
      }
    });
  }, []);

  const redirectTo = (path, user) => {
    dispatch(
      setUser({
        ...currentUser,
        ...user,
      })
    );
    if (!currentUser.isUser) {
      dispatch(
        setUser({
          ...currentUser,
          ...user,
          isUser: true,
        })
      );
      navigate(path);
    }
  };

  const auth = localStorage.getItem("access_token");
  if (location.pathname.split("/")[1]) {
    if (auth) {
      return currentUser.isUser ? (
        <Box>
          <HeaderBar />
          <ContentWrapper>{props.children}</ContentWrapper>
        </Box>
      ) : (
        <Layer responsive={false} className="spinner" />
      );
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return props.children;
  }
};

export default Layout;
