// eslint-disable-next-line no-extend-native
Array.prototype.filterWithSearch = function (
  searchValue,
  searchFields,
  setCount
) {
  const result = this?.filter((x) => {
    return x?.isAdd
      ? true
      : searchValue && searchValue.length > 0
      ? searchFields
          .map((s) =>
            s
              .replace(/\[([^[\]]*)\]/g, ".$1.")
              .split(".")
              .filter((t) => t !== "")
              .reduce((prev, cur) => prev && prev[cur], x)
          )
          .filter((b) =>
            b
              ?.toString()
              .toLocaleLowerCase()
              .includes(searchValue.toLocaleLowerCase())
          ).length > 0
      : true;
  });
  setCount && setCount(result.filter(x=> !x?.isAdd)?.length || 0);
  return result;
};
export const Users = {
  Admin: "ADMIN",
  Member: "MEMBER",
  Scanner: "SCANNER",
  Developer: "DEVELOPER",
};

export const isMobile = window.innerWidth <= 767;

export const menuIcons = {
  units: "/assets/icons/Units.svg",
  scanner: "/assets/icons/scanner.svg",
  projectSite: "/assets/icons/project_site.svg",
  member: "/assets/icons/Members.svg",
  logout: "/assets/icons/logout.svg",
  information: "/assets/icons/information.svg",
  help: "/assets/icons/help.svg",
  documents: "/assets/icons/documents.svg",
  developer: "/assets/icons/developer.svg",
  cards: "/assets/icons/cards.svg",
  defaultPic1: "/assets/icons/default_profile_pic.svg",
  masterDoc: "/assets/icons/master_doc.svg",
};

export const MAX_IMAGE_LIMIT = 5000000;
export const Constant = {
  Icons: {
    shield: "/assets/icons/shield.svg",
    architecture: "/assets/icons/architecture.svg",
    viewImg: "/assets/icons/viewImg.svg",
    logo: "assets/icons/logo.svg",
    mypropertyLogo: "assets/icons/mypropertyLogo.svg",
    cloud: "assets/icons/cloud.svg",
    illustration: "assets/icons/illustration.svg",
    checkMark: "assets/icons/checkMark.svg",
    OTP: "assets/icons/OTP.svg",
    defaultImage: "assets/icons/defaultImage.png",
    myPropertyCardLogo: "/assets/icons/mypropertycard-logo.svg",
    certificate: "/assets/icons/certificate.svg",
    approvedBuildingPlan: "/assets/icons/approved-building-plan.svg",
    Form_7_12: "/assets/icons/7-12.svg",
    sale: "/assets/icons/sale.svg",
    saleDeed: "/assets/icons/sale-deed.svg",
    projectBrochure: "/assets/icons/project-brochure.svg",
    findFile: "/assets/icons/find-file.svg",
    defaultPic: "/assets/icons/defaultPic.svg",
    email: "/assets/icons/email.svg",
    whatsApp: "/assets/icons/whatsApp.svg",
    save: "/assets/icons/save.png",
    helpPage: "/assets/icons/help-page.jpg",
    moneyReceipt: "/assets/icons/moneyReceipt.svg",
    moneyReceiptWhite: "/assets/icons/moneyReceiptWhite.svg",
    aadhar: "/assets/icons/aadharWhite.svg",
    pancard: "/assets/icons/pancard.svg",
    detail: "/assets/icons/detail.svg",
    export_file_icon: "/assets/icons/export-file-icon.svg",
  },

  Regex: {
    phoneNumber: /^\+91\d{10}$/,
  },
  Documents: {
    developer: {
      document: {
        limit: 10,
      },
    },
  },
  ToasterMessages: {
    Builder: {
      add: {
        error: "",
        success: "Developer added successfully",
      },
      update: {
        error: "",
        success: "Developer updated successfully",
      },
      delete: {
        error: "",
        success: "Developer deleted successfully",
      },
      Documents: {
        update: {
          error: "",
          success: "Document uploaded successfully",
        },
        delete: {
          error: "",
          success: "Document deleted successfully",
        },
      },
      removeImage: {
        error: "",
        success: "Developer logo removed successfully",
      },
    },
    Site: {
      add: {
        error: "",
        success: "Site added successfully",
      },
      update: {
        error: "",
        success: "Site updated successfully",
      },
      delete: {
        error: "",
        success: "Site deleted successfully",
      },
      Documents: {
        update: {
          error: "",
          success: "Document uploaded successfully",
        },
        delete: {
          error: "",
          success: "Document deleted successfully",
        },
      },
      Photos: {
        update: {
          error: "",
          success: "Site photo uploaded successfully",
        },
        delete: {
          error: "",
          success: "Site photo deleted successfully",
        },
      },
    },
    Member: {
      add: {
        error: "",
        success: "Member added successfully",
      },
      update: {
        error: "",
        success: "Member updated successfully",
      },

      delete: {
        error: "",
        success: "Member deleted successfully",
      },
      Documents: {
        update: {
          error: "",
          success: "Document uploaded successfully",
        },
        delete: {
          error: "",
          success: "Document deleted successfully",
        },
      },
      MoneyReceipt: {
        update: {
          error: "",
          success: "Document uploaded successfully",
        },
        delete: {
          error: "",
          success: "Document deleted successfully",
        },
      },
    },

    Scanner: {
      add: {
        error: "",
        success: "Scanner added successfully",
      },
      update: {
        error: "",
        success: "Scanner updated successfully",
      },

      delete: {
        error: "",
        success: "Scanner deleted successfully",
      },
      removeImage: {
        error: "",
        success: "Profile picture removed successfully",
      },
    },
    Unit: {
      save: {
        error: "",
        success: "Unit saved successfully",
      },
      delete: {
        error: "",
        success: "Unit deleted successfully",
      },
    },
    Card: {
      add: {
        error: "",
        success: "Cards added successfully",
      },
      save: {
        error: "",
        success: "Cards updated successfully",
      },
      delete: {
        error: "",
        success: "Cards deleted successfully",
      },
    },
    Login: {
      sendOtp: {
        error: "",
        success: "OTP is sent on your email/mobile",
      },
    },
    VerifyOtp: {
      error: "",
      success: "",
    },
  },

  Pagination: {
    Builder: {
      itemsPerPage: 100,
      emptyTableMsg: "No developers found",
    },

    Scanner: {
      itemsPerPage: 100,
      emptyTableMsg: "No scanners found",
    },

    Sites: {
      itemsPerPage: 100,
      emptyTableMsg: "No project sites found",
    },
    Unit: {
      itemsPerPage: 100,
      emptyTableMsg: "No units found",
      flex: "flex-end",
      isSrNo: true,
    },
    Member: {
      itemsPerPage: 100,
      emptyTableMsg: "No members found",
    },
    Card: {
      itemsPerPage: 100,
      emptyTableMsg: "No cards found",
    },
    defaultEmptyTableMsg: "No records found",
  },

  Constitution: {
    Proprietorship_Firm: "Proprietorship Firm",
    Partnership_Firm: "Partnership Firm",
    LLP_Firm: "LLP Firm",
    Pvt_Ltd_Company: "Pvt Ltd Company",
    Limited_Company: "Limited Company",
    Trust: "Trust",
    Other: "Other",
  },

  ProjectTypes: {
    Residential_Apartments: "Residential Apartments",
    Residential_Bungalow: "Residential Bungalow",
    Residential_Plotting: "Residential Plotting",
    Commercial_Complex: "Commercial Complex",
    Mix_Development: "Mix Development",
    Industrial_Sheds: "Industrial Sheds",
    Industrial_Plotting: "Industrial Plotting",
    Other: "Other",
  },

  ProjectStatus: {
    New_Project: "New Project",
    Ongoing_Project: "Ongoing Project",
    Redevelopment: "Redevelopment",
  },
  Menu: [
    {
      name: "Developers",
      icon: menuIcons.developer,
      heading: "Developers",
      link: "Developers",
      default: true,
      property: "developer",
    },
    {
      name: "Profile",
      icon: menuIcons.defaultPic1,
      heading: "Profile",
      link: "Profile",
      default: true,
      property: "profile",
    },
    {
      name: "Project Site",
      icon: menuIcons.projectSite,
      heading: "Project Site",
      link: "Sites",
      property: "site",
    },
    {
      name: "Units",
      icon: menuIcons.units,
      heading: "Units",
      link: "Units",
      property: "unit",
    },
    {
      name: "Members",
      icon: menuIcons.member,
      heading: "Members",
      link: "Members",
      property: "member",
    },
    {
      name: "Scanners",
      icon: menuIcons.scanner,
      heading: "Members",
      link: "Scanners",
      property: "scanner",
    },
    {
      name: "Cards",
      icon: menuIcons.cards,
      heading: "Cards",
      link: "Cards",
      property: "card",
    },
    {
      name: "My Property Information",
      icon: menuIcons.information,
      default: true,
      heading: "My Property Information",
      link: "MyPropertyInformation",
      property: "myPropertyInformation",
    },
    {
      name: "My Property Documents",
      icon: menuIcons.documents,
      heading: "My Property Documents",
      link: "MyPropertyDocuments",
      property: "myPropertyDocuments",
    },
    {
      name: "Project Master Documents",
      icon: menuIcons.masterDoc,
      heading: "Project Master Documents",
      link: "ProjectMasterDocuments",
      property: "projectMasterDocuments",
    },
    {
      name: "Help",
      icon: menuIcons.help,
      heading: "Help",
      link: "Help",
      property: "help",
      users: [Users.Member, Users.Developer],
    },
  ],

  DateFormat: "dd/mm/yyyy",
  rights: {
    DEVELOPER: {
      developer: {
        view: false,
      },
      profile: {
        view: true,
      },
      site: {
        view: true,
        add: false,
        delete: false,
        edit: true,
      },
      member: {
        view: true,
        add: false,
        delete: false,
        edit: true,
      },
      unit: {
        view: true,
        add: true,
        delete: true,
        edit: true,
      },
      scanner: {
        view: false,
        add: false,
        delete: false,
        edit: false,
      },
      card: {
        view: false,
        add: false,
        delete: false,
        edit: false,
      },
      help: {
        view: true,
      },
      myPropertyInformation: {
        view: false,
      },
      myPropertyDocuments: {
        view: false,
      },
      projectMasterDocuments: {
        view: false,
      },
    },
    ADMIN: {
      developer: {
        view: true,
        add: true,
        delete: true,
        edit: true,
      },
      profile: {
        view: false,
      },
      site: {
        view: true,
        add: true,
        delete: true,
        edit: true,
      },
      member: {
        view: true,
        add: true,
        delete: true,
        edit: true,
      },
      unit: {
        view: true,
        add: true,
        delete: true,
        edit: true,
      },
      scanner: {
        view: true,
        add: true,
        delete: true,
        edit: true,
      },
      card: {
        view: true,
        add: true,
        delete: true,
        edit: true,
      },
      help: {
        view: true,
      },
      myPropertyInformation: {
        view: false,
      },
      myPropertyDocuments: {
        view: false,
      },
      projectMasterDocuments: {
        view: false,
      },
    },
    MEMBER: {
      developer: {
        view: false,
      },
      profile: {
        view: false,
      },
      site: {
        view: false,
        add: false,
        delete: false,
        edit: true,
      },
      member: {
        view: false,
        add: false,
        delete: false,
        edit: true,
      },
      unit: {
        view: false,
        add: false,
        delete: false,
        edit: true,
      },
      scanner: {
        view: false,
        add: false,
        delete: false,
        edit: false,
      },
      card: {
        view: false,
        add: false,
        delete: false,
        edit: false,
      },
      help: {
        view: true,
      },
      myPropertyInformation: {
        view: true,
      },
      myPropertyDocuments: {
        view: true,
      },
      projectMasterDocuments: {
        view: true,
      },
    },
  },

  CardStatus: {
    ACTIVE: 1,
    INACTIVE: 0,
    DELETED: -1,
    Assigned: 2,
    Unassigned: 3,
  },

  Errors: {
    FileLimitError: "Please select image less than 5mb.",
  },
  DocumentTypes: {
    siteDocuments: "siteDocuments",
    sitePhotos: "sitePhotos",
    developerDocuments: "documents",
    memberDocuments: "memberDocuments",
    moneyReceipts: "moneyReceipts",
  },
};
